import React from 'react';
import ImageUploading from 'react-images-uploading';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
// Components
import MultipleImagesList from './MultipleImagesList/MultipleImagesList';
import { Button } from 'antd';

import styles from './UploadMultipleImages.module.scss';

export type UploadImage = {
  data_url?: string;
  data_key?: string;
  file?: File;
};

type UploadMultipleImagesProps = {
  images: UploadImage[];
  setImages: (images: UploadImage[]) => void;
  setIsMainImage?: (index: number) => void;
  isMainImage: number | null;
  disabledButtons?: boolean;
  buttonClassName?: string;
};

const UploadMultipleImages = ({
  images,
  setImages,
  setIsMainImage,
  isMainImage,
  disabledButtons = false,
  buttonClassName,
}: UploadMultipleImagesProps): JSX.Element => {
  const onChange = (imageList: any) => {
    setImages(imageList);
  };

  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={10}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <div className={styles.uploadImageWrapper}>
          <Button
            className={buttonClassName}
            style={isDragging ? { color: 'red' } : undefined}
            onClick={onImageUpload}
            {...dragProps}
            disabled={disabledButtons}
          >
            <UploadOutlined /> Upload
          </Button>

          <Button
            className={buttonClassName}
            onClick={onImageRemoveAll}
            disabled={disabledButtons}
          >
            <DeleteOutlined /> Delete all
          </Button>

          <MultipleImagesList
            imageList={imageList}
            onImageUpdate={onImageUpdate}
            onImageRemove={(index: number) => {
              onImageRemove(index);
            }}
            setIsMainImage={setIsMainImage}
            isMainImage={isMainImage}
          />
        </div>
      )}
    </ImageUploading>
  );
};

export default UploadMultipleImages;
