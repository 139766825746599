import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import { Typography, AutoComplete } from 'antd';
// Api
import { GET_STATE_CITIES_V2 } from 'api/locations/queries';
// Types
import {
  GetStateCitiesV2,
  GetStateCitiesV2Variables,
} from 'api/locations/types/GetStateCitiesV2';
// Styles
import styles from './SearchCities.module.scss';

const { Text } = Typography;

export type AutoCompleteOption = {
  value: string | number;
  label: string;
  longitude: number;
  latitude: number;
};

type SearchCityProps = {
  stateId: number;
  selectedCityOption: AutoCompleteOption | null;
  setSelectedCityOption: React.Dispatch<
    React.SetStateAction<AutoCompleteOption | null>
  >;
};

export const SearchCitiesComponent = ({
  selectedCityOption,
  setSelectedCityOption,
  stateId,
}: SearchCityProps): JSX.Element => {
  const [getCities, { data }] = useLazyQuery<
    GetStateCitiesV2,
    GetStateCitiesV2Variables
  >(GET_STATE_CITIES_V2);

  const [cityOptions, setCityOptions] = useState<AutoCompleteOption[]>([]);

  const handleSelect = (value: string, option: AutoCompleteOption): void => {
    setSelectedCityOption(option);
  };

  const handleOnClear = () => {
    setSelectedCityOption(null);
  };

  const handleOnSearch = useMemo(() => {
    const loadOptions = (searchTerm: string) => {
      getCities({
        variables: {
          input: {
            stateId,
            name: searchTerm,
          },
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [getCities, stateId]);

  useEffect(() => {
    if (data?.getStateCitiesV2?.entities) {
      setCityOptions(
        data?.getStateCitiesV2?.entities.map((item) => ({
          value: item.id,
          label: item.name || '',
          latitude: item.latitude,
          longitude: item.longitude,
        }))
      );
    }
  }, [getCities, data]);

  return (
    <div className={styles.streamSection}>
      <Text>City:</Text>
      <AutoComplete
        id="city-search"
        allowClear
        className={styles.autoComplete}
        placeholder="Search city name"
        options={cityOptions}
        value={selectedCityOption?.label}
        onSearch={handleOnSearch}
        onSelect={handleSelect}
        onClear={handleOnClear}
      />

      {selectedCityOption && (
        <Text className={styles.text}>
          Selected city: {selectedCityOption?.label}
        </Text>
      )}
    </div>
  );
};

export default SearchCitiesComponent;
