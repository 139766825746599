import moment, { Moment } from 'moment';
// Types
import { RangePickerProps } from 'antd/lib/date-picker';
import { WeekDay } from 'api/graphql-global-types';
import {
  GetSchedule_getSchedule_rules,
  GetSchedule_getSchedule_rules_ScheduleDateRule,
  GetSchedule_getSchedule_rules_ScheduleWeekDayRule,
} from 'api/schedule/types/GetSchedule';
import { GetScheduleTimezones } from 'api/schedule/types/GetScheduleTimezones';

export const weekdays: string[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export type CheckboxState = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

export type TimeRanges = {
  monday: TimeRange[];
  tuesday: TimeRange[];
  wednesday: TimeRange[];
  thursday: TimeRange[];
  friday: TimeRange[];
  saturday: TimeRange[];
  sunday: TimeRange[];
};

export type TimeRange = {
  from: Moment | null;
  to: Moment | null;
};

export type DateSpecificHours = {
  date: Moment | null;
  timeRanges: TimeRange[];
};

export const disabledDate: RangePickerProps['disabledDate'] = (
  current: moment.Moment
) => {
  // Can not select days before today and today
  return current && current < moment().startOf('day');
};

export const getTimezoneOptions = (
  timezones: GetScheduleTimezones | undefined
): { tzCode: string; tzName: string; label: string }[] => {
  if (!timezones?.getScheduleTimezones) return [];

  return timezones?.getScheduleTimezones?.map((item) => ({
    tzCode: item.alias,
    tzName: item.iana,
    label: item.iana,
  }));
};

export function isScheduleDateRule(
  rule: GetSchedule_getSchedule_rules
): rule is GetSchedule_getSchedule_rules_ScheduleDateRule {
  return (
    (rule as GetSchedule_getSchedule_rules_ScheduleDateRule).date !== undefined
  );
}

export function isScheduleWeekDayRule(
  rule: GetSchedule_getSchedule_rules
): rule is GetSchedule_getSchedule_rules_ScheduleWeekDayRule {
  return (
    (rule as GetSchedule_getSchedule_rules_ScheduleWeekDayRule).weekday !==
    undefined
  );
}

export function getRuleForDay(
  day: string,
  rules: GetSchedule_getSchedule_rules[]
): GetSchedule_getSchedule_rules_ScheduleWeekDayRule | null {
  return (
    ((rules.find(
      (rule) => isScheduleWeekDayRule(rule) && rule.weekday === day
    ) || null) as GetSchedule_getSchedule_rules_ScheduleWeekDayRule) || null
  );
}

export const getWeekDayRule = (
  day: string,
  rules: GetSchedule_getSchedule_rules_ScheduleWeekDayRule[]
): GetSchedule_getSchedule_rules_ScheduleWeekDayRule | null => {
  return rules?.find((rule) => rule.weekday === day) || null;
};

type WeekDayAvailabilityType = {
  weekday: WeekDay;
  intervals: {
    from: string;
    to: string;
  }[];
};

export const getWeekDayAvailability = (
  timeRanges: TimeRanges,
  checkboxState: CheckboxState
): WeekDayAvailabilityType[] => {
  return (Object.keys(timeRanges) as Array<keyof TimeRanges>)
    .filter((weekday) => checkboxState[weekday as keyof CheckboxState])
    .map((weekday) => ({
      weekday: weekday as WeekDay,
      intervals: timeRanges[weekday].map((interval) => ({
        from: interval.from ? interval.from.format('HH:mm') : '',
        to: interval.to ? interval.to.format('HH:mm') : '',
      })),
    }))
    .filter((day) =>
      day.intervals.some(
        (interval) => interval.from !== '' || interval.to !== ''
      )
    );
};

type DatesAvailability = {
  date: string;
  intervals: {
    from: string;
    to: string;
  }[];
};

export const getDatesAvailability = (
  specificHoursList: DateSpecificHours[]
): DatesAvailability[] => {
  return specificHoursList.map((item) => ({
    date: item.date ? item.date.format('YYYY-MM-DD') : '',
    intervals: item.timeRanges.map((interval) => ({
      from: interval.from ? interval.from.format('HH:mm') : '',
      to: interval.to ? interval.to.format('HH:mm') : '',
    })),
  }));
};

export const DURATION_MINUTES = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '60 minutes', value: 60 },
];
