import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form, Button, Modal } from 'antd';
// Api
import {
  CREATE_CAMPAIGN_SUBMISSION_FILES,
  SUBMIT_CAMPAIGN_OFFER_RESULTS,
} from 'api/campaignV2/mutations';
// Types
import {
  GetCampaignOffers_getCampaignOffers_entities,
  GetCampaignOffers_getCampaignOffers_entities_campaign_activities_SocialActivity,
} from 'api/campaignV2/types/GetCampaignOffers';
import {
  CampaignOfferStatus,
  CampaignOfferSubmissionInput,
  CampaignTaskType,
  SubmitCampaignOfferResultsInput,
} from 'api/graphql-global-types';
import {
  CreateCampaignSubmissionFiles,
  CreateCampaignSubmissionFilesVariables,
} from 'api/campaignV2/types/CreateCampaignSubmissionFiles';
import {
  SubmitCampaignOfferResults,
  SubmitCampaignOfferResultsVariables,
} from 'api/campaignV2/types/SubmitCampaignOfferResults';
// Helpers
import {
  createPresignedUrlsAndUploadToS3,
  PresignedUrlResultItem,
} from 'helpers/single-uploader';
import {
  arrangeSubmissions,
  EMPTY_SOCIAL_STATE,
  getInitialSocialState,
  SocialStateEnum,
  SocialStateType,
} from 'helpers/deliverables';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import SocialInputField from 'components/common/SocialInputField/SocialInputField';
// Styles
import styles from './SocialDeliverables.module.scss';

type SocialDeliverablesProps = {
  record: GetCampaignOffers_getCampaignOffers_entities | null;
  onClose: () => void;
  onFinish: () => void;
};

const SocialDeliverables = ({
  record,
  onClose,
  onFinish,
}: SocialDeliverablesProps): JSX.Element => {
  const [form] = Form.useForm();
  const campaignActivity = record?.campaign
    .activities?.[0] as GetCampaignOffers_getCampaignOffers_entities_campaign_activities_SocialActivity;

  const postTypes = campaignActivity?.tasks?.map((task) => task.type);

  const isIGPost = postTypes?.includes(CampaignTaskType.IG_POST) || false;
  const isIGReel = postTypes?.includes(CampaignTaskType.IG_REEL) || false;
  const isIGStory = postTypes?.includes(CampaignTaskType.IG_STORY) || false;
  const isIGLive = postTypes?.includes(CampaignTaskType.IG_LIVE) || false;
  const isTikTok = postTypes?.includes(CampaignTaskType.TT_VIDEO) || false;

  const [socialState, setSocialState] =
    useState<SocialStateType>(EMPTY_SOCIAL_STATE);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [createCampaignSubmissionFiles] = useMutation<
    CreateCampaignSubmissionFiles,
    CreateCampaignSubmissionFilesVariables
  >(CREATE_CAMPAIGN_SUBMISSION_FILES);

  const [submitCampaignOffer] = useMutation<
    SubmitCampaignOfferResults,
    SubmitCampaignOfferResultsVariables
  >(SUBMIT_CAMPAIGN_OFFER_RESULTS);

  useEffect(() => {
    if (record) {
      const initialSocialState = getInitialSocialState(record);
      setSocialState(initialSocialState);
    } else {
      setSocialState(EMPTY_SOCIAL_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const handleFinish = () => {
    onFinish();
    handleReset();
  };

  const handleReset = () => {
    onClose();
    form.resetFields();
    setIsLoading(false);
    setSocialState(EMPTY_SOCIAL_STATE);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const input: SubmitCampaignOfferResultsInput = {
        offerId: record?.id || '',
        ownerId: record?.userId,
        submissions: [],
      };

      const imagesForUpload: File[] = [];
      socialState.liveImages.forEach((image) => {
        if (image.file) {
          imagesForUpload.push(image.file);
        }
      });
      socialState.storyImages.forEach((image) => {
        if (image.file) {
          imagesForUpload.push(image.file);
        }
      });

      const imageKeys: PresignedUrlResultItem[] =
        await createPresignedUrlsAndUploadToS3({
          isStrict: true,
          files: imagesForUpload,
          withoutContentType: true,
          getPresignedUrls: async () => {
            const { data: customImagesPresignedUrls } =
              await createCampaignSubmissionFiles({
                variables: {
                  input: {
                    offerId: record?.id || '',
                    files: imagesForUpload.map((item) => ({
                      name: item.name,
                    })),
                  },
                },
              });
            if (!customImagesPresignedUrls) {
              console.error('Error creating presigned images');
              throw new Error('Something went wrong');
            }

            return customImagesPresignedUrls.createCampaignSubmissionFiles;
          },
        });

      const allSubmissions: CampaignOfferSubmissionInput[] = arrangeSubmissions(
        socialState,
        imageKeys
      );

      input.submissions = [...allSubmissions];

      await submitCampaignOffer({
        variables: {
          input,
        },
      });
      successNotification(`Campaign deliverables added successfully`);

      handleFinish();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
      setIsLoading(false);
    }
  };

  // disable submit if any of the inputs is missing a value or has an empty string(in case of links)
  const disableSubmit =
    (isIGPost && !!socialState.iGPosts.filter((item) => item === '').length) ||
    (isIGReel && !!socialState.iGReels.filter((item) => item === '').length) ||
    (isTikTok && !!socialState.tikToks.filter((item) => item === '').length) ||
    (isIGLive && !socialState.liveImages.length) ||
    (isIGStory && !socialState.storyImages.length);

  const isCompletedOrPublished =
    record?.statusV2 === CampaignOfferStatus.COMPLETED ||
    record?.statusV2 === CampaignOfferStatus.PUBLISHED;

  return (
    <Modal
      title="Deliverables"
      visible={!!record}
      footer={null}
      onCancel={handleReset}
      width="90vw"
    >
      <Form
        className={styles.root}
        name="createEditSocialCampaignDeliverables"
        aria-label="Create/Edit Social Campaign Deliverables"
        form={form}
        onFinish={handleSubmit}
      >
        {isIGPost && (
          <SocialInputField
            name={SocialStateEnum.iGPosts}
            label="Link to the Instagram post:"
            placeholder="https://instagram.com/hey..."
            isCompletedOrPublished={isCompletedOrPublished}
            socialState={socialState}
            setState={setSocialState}
          />
        )}
        {isIGReel && (
          <SocialInputField
            name={SocialStateEnum.iGReels}
            label="Link to the Instagram reel:"
            placeholder="https://instagram.com/hey..."
            isCompletedOrPublished={isCompletedOrPublished}
            socialState={socialState}
            setState={setSocialState}
          />
        )}
        {isTikTok && (
          <SocialInputField
            name={SocialStateEnum.tikToks}
            label="Link to the TikTok post:"
            placeholder="Paste here..."
            isCompletedOrPublished={isCompletedOrPublished}
            socialState={socialState}
            setState={setSocialState}
          />
        )}
        {isIGStory && (
          <SocialInputField
            name={SocialStateEnum.storyImages}
            label="Screenshots of Instagram story and insights:"
            isCompletedOrPublished={isCompletedOrPublished}
            socialState={socialState}
            setState={setSocialState}
          />
        )}
        {isIGLive && (
          <SocialInputField
            name={SocialStateEnum.liveImages}
            label="Screenshots of Instagram live and insights:"
            isCompletedOrPublished={isCompletedOrPublished}
            socialState={socialState}
            setState={setSocialState}
          />
        )}
        {!isCompletedOrPublished && (
          <div className={styles.submitButton}>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoading}
              disabled={isLoading || disableSubmit}
            >
              Save
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default SocialDeliverables;
