import React from 'react';
import { Button } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// Api
import { DOWNLOAD_CAMPAIGN_OFFERS } from 'api/campaignV2/mutations';
import { GET_CAMPAIGNS_V2 } from 'api/campaignV2/queries';
// Types
import {
  GetCampaignsV2,
  GetCampaignsV2Variables,
  GetCampaignsV2_getCampaigns_entities,
} from 'api/campaignV2/types/GetCampaignsV2';
import {
  DownloadCampaignOffersFull,
  DownloadCampaignOffersFullVariables,
} from 'api/campaignV2/types/DownloadCampaignOffersFull';
import { CampaignStatus } from 'api/graphql-global-types';
// Ui
import { successNotification, errorNotification } from 'ui/Notification';
// Components
import TalentTable from './components/TalentTable/TalentTable';
import CampaignRecordData from './components/CampaignRecordData/CampaignRecordData';
import ChangeCampaignBudget from './components/ChangeCampaignBudget/ChangeCampaignBudget';
import CampaignActivityData from './components/CampaignActivityData/CampaignActivityData';
// Style
import styles from './CampaignDetails.module.scss';

const CampaignDetailsComponent = (): JSX.Element | null => {
  const { campaignId } = useParams<{ campaignId: string | undefined }>();

  const { data } = useQuery<GetCampaignsV2, GetCampaignsV2Variables>(
    GET_CAMPAIGNS_V2,
    {
      variables: {
        input: {
          campaignId,
        },
      },
      skip: !campaignId,
      fetchPolicy: 'cache-and-network',
    }
  );

  const record: GetCampaignsV2_getCampaigns_entities | null =
    data?.getCampaigns.entities[0] || null;

  const [downloadCSV, { loading: downloadingCSV }] = useMutation<
    DownloadCampaignOffersFull,
    DownloadCampaignOffersFullVariables
  >(DOWNLOAD_CAMPAIGN_OFFERS);

  const onCSVButtonClick = async () => {
    try {
      const { data } = await downloadCSV({
        variables: {
          input: {
            campaignId: record?.id || '',
          },
        },
      });

      if (data?.downloadCampaignOffersFull.csvUrl) {
        window.open(data.downloadCampaignOffersFull.csvUrl, '_blank');
        successNotification('CSV has been downloaded');
      }
    } catch (error) {
      console.error('Error downloading CSV', error);
      errorNotification('Error downloading CSV');
    }
  };

  const showBudgetField =
    record?.status !== CampaignStatus.COMPLETED &&
    record?.status !== CampaignStatus.DECLINED;

  if (!record) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.dataArea}>
        <CampaignRecordData record={record} />

        <CampaignActivityData record={record} />
      </div>

      <div className={styles.budgetArea}>
        {showBudgetField && <ChangeCampaignBudget record={record} />}
      </div>

      <div className={styles.talentsArea}>
        <h1>Talent: </h1>

        <Button
          className={styles.exportTalentsButton}
          type="primary"
          loading={downloadingCSV}
          onClick={onCSVButtonClick}
        >
          Export to CSV
        </Button>

        <TalentTable record={record} />
      </div>
    </div>
  );
};

export default CampaignDetailsComponent;
