import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Spin, Typography, AutoComplete, Tag } from 'antd';
import debounce from 'lodash/debounce';
import Checkbox from 'antd/lib/checkbox';
import { DeleteOutlined } from '@ant-design/icons';
// Api
import { SET_INTERVIEW_SETTINGS } from 'api/interview/mutations';
import { GET_INTERVIEW_SETTINGS } from 'api/interview/queries';
import { GET_STORE_INTERVIEWERS_MINIMAL } from 'api/interviewer/queries';
// Types
import {
  GetInterviewSettings,
  GetInterviewSettings_getInterviewSettings_InterviewHighValueMemorabiliaTrigger,
  GetInterviewSettings_getInterviewSettings_InterviewHighValueMerchTrigger,
  GetInterviewSettings_getInterviewSettings_InterviewPPVParticipantTrigger,
} from 'api/interview/types/GetInterviewSettings';
import {
  SetInterviewSettings,
  SetInterviewSettingsVariables,
} from 'api/interview/types/SetInterviewSettings';
import {
  InterviewSettingType,
  StoreStatus,
  StoreInterviewerOrderBy,
} from 'api/graphql-global-types';
import {
  GetStoreInterviewersMinimal,
  GetStoreInterviewersMinimalVariables,
} from 'api/interviewer/types/GetStoreInterviewersMinimal';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
// Styles
import styles from './InterviewSettings.module.scss';

const { Title, Text } = Typography;

type AutoCompleteOption = {
  value: string | number;
  label: string;
};

const InterviewSettings = (): JSX.Element => {
  const [storeOptions, setStoreOptions] = useState<AutoCompleteOption[]>([]);

  const [selectedStoresMerch, setSelectedStoresMerch] = useState<
    AutoCompleteOption[]
  >([]);
  const [selectedStoresMemorabilia, setSelectedStoresMemorabilia] = useState<
    AutoCompleteOption[]
  >([]);
  const [selectedStoresPPV, setSelectedStoresPPV] = useState<
    AutoCompleteOption[]
  >([]);

  const [storeMerchInputValue, setStoreMerchInputValue] = useState<string>('');
  const [storeMemorabiliaInputValue, setStoreMemorabiliaInputValue] =
    useState<string>('');
  const [storePPVInputValue, setStorePPVInputValue] = useState<string>('');

  const { data } = useQuery<GetInterviewSettings>(GET_INTERVIEW_SETTINGS, {
    fetchPolicy: 'cache-and-network',
  });

  const [setInterviewSettings, { loading }] = useMutation<
    SetInterviewSettings,
    SetInterviewSettingsVariables
  >(SET_INTERVIEW_SETTINGS);

  const { data: interviewersData, refetch } = useQuery<
    GetStoreInterviewersMinimal,
    GetStoreInterviewersMinimalVariables
  >(GET_STORE_INTERVIEWERS_MINIMAL, {
    variables: {
      input: {
        orderBy: StoreInterviewerOrderBy.firstName,
        status: [StoreStatus.Active],
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleOnSearchStoresMerch = useMemo(() => {
    const loadOptions = (name: string) => {
      refetch({
        input: {
          name,
          orderBy: StoreInterviewerOrderBy.firstName,
          status: [StoreStatus.Active],
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  const handleOnSearchStoresMemorabilia = useMemo(() => {
    const loadOptions = (name: string) => {
      refetch({
        input: {
          name,
          orderBy: StoreInterviewerOrderBy.firstName,
          status: [StoreStatus.Active],
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  const handleOnSearchStoresPPV = useMemo(() => {
    const loadOptions = (name: string) => {
      refetch({
        input: {
          name,
          orderBy: StoreInterviewerOrderBy.firstName,
          status: [StoreStatus.Active],
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  const merchTriger = data?.getInterviewSettings.find(
    (item) => item.id === InterviewSettingType.HighValueMerchTrigger
  ) as GetInterviewSettings_getInterviewSettings_InterviewHighValueMerchTrigger;

  const memorabiliaTriger = data?.getInterviewSettings.find(
    (item) => item.id === InterviewSettingType.HighValueMemorabiliaTrigger
  ) as GetInterviewSettings_getInterviewSettings_InterviewHighValueMemorabiliaTrigger;

  const ppvParticipantTriger = data?.getInterviewSettings.find(
    (item) => item.id === InterviewSettingType.PPVParticipantTrigger
  ) as GetInterviewSettings_getInterviewSettings_InterviewPPVParticipantTrigger;

  const interviewersMerch = merchTriger?.interviewers;
  const interviewersMemorabilia = memorabiliaTriger?.interviewers;
  const interviewersPPV = ppvParticipantTriger?.interviewers;

  const [form] = Form.useForm();

  const handleUpdateSettings = async () => {
    const values = form.getFieldsValue();

    const merchIds = selectedStoresMerch.map((store) => String(store.value));
    const memorabiliaIds = selectedStoresMemorabilia.map((store) =>
      String(store.value)
    );
    const PPVIds = selectedStoresPPV.map((store) => String(store.value));

    try {
      await setInterviewSettings({
        variables: {
          input: {
            HighValueMemorabiliaTrigger: {
              isEnabled: values.interviewHighValueMemorabiliaTrigger,
              config: {
                minPrice: Number(values.interviewHighValueMemorabiliaMinPrice),
              },
              interviewerIds: memorabiliaIds,
            },
            HighValueMerchTrigger: {
              isEnabled: values.interviewHighValueMerchTrigger,
              config: {
                minPrice: Number(values.interviewHighValueMerchMinPrice),
              },
              interviewerIds: merchIds,
            },
            PPVParticipantTrigger: {
              isEnabled: values.interviewPPVParticipantTrigger,
              interviewerIds: PPVIds,
            },
          },
        },
      });
      successNotification('Settings updated');
    } catch (error) {
      errorNotification((error as Error)?.message);
    }
  };

  const handleFinish = async () => {
    handleUpdateSettings();
  };

  const handleSelectStoreMerch = (
    value: string,
    option: AutoCompleteOption
  ): void => {
    setSelectedStoresMerch([...selectedStoresMerch, option]);

    setStoreMerchInputValue('');
  };

  const handleSelectStoreMemorabilia = (
    value: string,
    option: AutoCompleteOption
  ): void => {
    setSelectedStoresMemorabilia([...selectedStoresMemorabilia, option]);

    setStoreMemorabiliaInputValue('');
  };

  const handleSelectStorePPV = (
    value: string,
    option: AutoCompleteOption
  ): void => {
    setSelectedStoresPPV([...selectedStoresPPV, option]);

    setStorePPVInputValue('');
  };

  const handleStoreChangeMerch = (e: string) => {
    setStoreMerchInputValue(e);

    const newSelectedStores = [
      ...selectedStoresMerch,
      storeMerchInputValue.trim(),
    ];
    form.setFieldsValue({
      interviewerMerchIds: newSelectedStores,
    });
  };

  const handleStoreChangeMemorabilia = (e: string) => {
    setStoreMemorabiliaInputValue(e);

    const newSelectedStores = [
      ...selectedStoresMemorabilia,
      storeMemorabiliaInputValue.trim(),
    ];
    form.setFieldsValue({
      interviewerMemorabiliaIds: newSelectedStores,
    });
  };

  const handleStoreChangePPV = (e: string) => {
    setStorePPVInputValue(e);

    const newSelectedStores = [...selectedStoresPPV, storePPVInputValue.trim()];
    form.setFieldsValue({
      interviewerPPVIds: newSelectedStores,
    });
  };

  const handleRemoveSelectedStoreMerch = (storeId: string | number) => {
    const newSelectedStores = selectedStoresMerch.filter(
      (storeOption) => storeOption.value !== storeId
    );
    setSelectedStoresMerch(newSelectedStores);
    form.setFieldsValue({
      interviewerMerchIds: newSelectedStores,
    });
  };

  const handleRemoveSelectedStoreMemorabilia = (storeId: string | number) => {
    const newSelectedStores = selectedStoresMemorabilia.filter(
      (storeOption) => storeOption.value !== storeId
    );
    setSelectedStoresMemorabilia(newSelectedStores);
    form.setFieldsValue({
      interviewerMemorabiliaIds: newSelectedStores,
    });
  };

  const handleRemoveSelectedStorePPV = (storeId: string | number) => {
    const newSelectedStores = selectedStoresPPV.filter(
      (storeOption) => storeOption.value !== storeId
    );
    setSelectedStoresPPV(newSelectedStores);
    form.setFieldsValue({
      interviewerPPVIds: newSelectedStores,
    });
  };

  useEffect(() => {
    if (interviewersData?.getStoreInterviewers.entities) {
      setStoreOptions(
        interviewersData.getStoreInterviewers.entities.map((store) => ({
          value: store.id,
          label: store.storeDetails?.storeName
            ? store.storeDetails.storeName
            : `${store.firstName} ${store.lastName}`,
        }))
      );
    }
  }, [interviewersData]);

  useEffect(() => {
    if (data) {
      const merchOptions: AutoCompleteOption[] =
        interviewersMerch.map((host) => {
          return {
            value: host.id,
            label: host.storeDetails?.storeName
              ? host.storeDetails.storeName
              : `${host.firstName} ${host.lastName}`,
          };
        }) || [];

      const memorabiliaOptions: AutoCompleteOption[] =
        interviewersMemorabilia.map((host) => {
          return {
            value: host.id,
            label: host.storeDetails?.storeName
              ? host.storeDetails.storeName
              : `${host.firstName} ${host.lastName}`,
          };
        }) || [];

      const pPVOptions: AutoCompleteOption[] =
        interviewersPPV.map((host) => {
          return {
            value: host.id,
            label: host.storeDetails?.storeName
              ? host.storeDetails.storeName
              : `${host.firstName} ${host.lastName}`,
          };
        }) || [];

      form.setFieldsValue({
        interviewHighValueMerchTrigger: merchTriger?.isEnabled || false,
        interviewHighValueMerchMinPrice: merchTriger?.config?.minPrice || 1,
        interviewHighValueMemorabiliaTrigger:
          memorabiliaTriger?.isEnabled || false,
        interviewHighValueMemorabiliaMinPrice:
          memorabiliaTriger?.config?.minPrice || 1,
        interviewPPVParticipantTrigger:
          ppvParticipantTriger?.isEnabled || false,
        interviewersMerchIds: merchOptions || [],
        interviewerMemorabiliaIds: memorabiliaOptions || [],
        interviewerPPVIds: pPVOptions || [],
      });
      if (merchOptions.length) {
        setSelectedStoresMerch(merchOptions);
      }
      if (memorabiliaOptions.length) {
        setSelectedStoresMemorabilia(memorabiliaOptions);
      }
      if (pPVOptions.length) {
        setSelectedStoresPPV(pPVOptions);
      }
    }
  }, [
    data,
    form,
    merchTriger,
    memorabiliaTriger,
    ppvParticipantTriger,
    interviewersPPV,
    interviewersMerch,
    interviewersMemorabilia,
  ]);

  const clearSearch = () => {
    setStoreMerchInputValue('');
    setStoreMemorabiliaInputValue('');
    setStorePPVInputValue('');
  };

  return (
    <div className={styles.root}>
      <Form
        form={form}
        layout="horizontal"
        name="interviewSettings"
        autoComplete="off"
        onFinish={handleFinish}
      >
        <Title>Interview settings</Title>

        <Form.Item
          name="interviewHighValueMerchTrigger"
          label="Interview High Value Merch Trigger"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item name="interviewHighValueMerchMinPrice" label="Min price">
          <Input
            type="number"
            placeholder="Type here"
            className={styles.input}
          />
        </Form.Item>

        <div className={styles.storeSection}>
          <Text className={styles.accountText}>
            Merch Trigger Interviewers:
          </Text>
          <AutoComplete
            id="interviewerMerchIds"
            allowClear
            className={styles.autoComplete}
            placeholder="Search Account Name"
            options={storeOptions}
            value={storeMerchInputValue}
            onSearch={handleOnSearchStoresMerch}
            onSelect={handleSelectStoreMerch}
            onChange={(e) => handleStoreChangeMerch(e)}
            onBlur={clearSearch}
          />
          <div className={styles.optionWrapper}>
            {selectedStoresMerch.map((option) => (
              <Tag key={option.value} className={styles.tag}>
                <div className={styles.textLabel}>{option.label}</div>
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemoveSelectedStoreMerch(option.value)}
                />
              </Tag>
            ))}
          </div>
        </div>

        <Form.Item
          name="interviewHighValueMemorabiliaTrigger"
          label="Interview High Value Memorabilia Trigger"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          name="interviewHighValueMemorabiliaMinPrice"
          label="Min price"
        >
          <Input
            type="number"
            placeholder="Type here"
            className={styles.input}
          />
        </Form.Item>

        <div className={styles.storeSection}>
          <Text className={styles.accountText}>
            Memorabilia Trigger Interviewers:
          </Text>
          <AutoComplete
            id="interviewerMemorabiliaIds"
            allowClear
            className={styles.autoComplete}
            placeholder="Search Account Name"
            options={storeOptions}
            value={storeMemorabiliaInputValue}
            onSearch={handleOnSearchStoresMemorabilia}
            onSelect={handleSelectStoreMemorabilia}
            onChange={(e) => handleStoreChangeMemorabilia(e)}
            onBlur={clearSearch}
          />
          <div className={styles.optionWrapper}>
            {selectedStoresMemorabilia.map((option) => (
              <Tag key={option.value} className={styles.tag}>
                <div className={styles.textLabel}>{option.label}</div>
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    handleRemoveSelectedStoreMemorabilia(option.value)
                  }
                />
              </Tag>
            ))}
          </div>
        </div>

        <Form.Item
          name="interviewPPVParticipantTrigger"
          label="Interview PPV Participant Trigger"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <div className={styles.storeSection}>
          <Text className={styles.accountText}>PPV Trigger Interviewers:</Text>
          <AutoComplete
            id="interviewerPPVIds"
            allowClear
            className={styles.autoComplete}
            placeholder="Search Account Name"
            options={storeOptions}
            value={storePPVInputValue}
            onSearch={handleOnSearchStoresPPV}
            onSelect={handleSelectStorePPV}
            onChange={(e) => handleStoreChangePPV(e)}
            onBlur={clearSearch}
          />
          <div className={styles.optionWrapper}>
            {selectedStoresPPV.map((option) => (
              <Tag key={option.value} className={styles.tag}>
                <div className={styles.textLabel}>{option.label}</div>
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemoveSelectedStorePPV(option.value)}
                />
              </Tag>
            ))}
          </div>
        </div>

        {loading ? (
          <Spin size="large" />
        ) : (
          <Button type="primary" htmlType="submit">
            Save Settings
          </Button>
        )}
      </Form>
    </div>
  );
};

export default InterviewSettings;
