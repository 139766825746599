import React from 'react';
import moment from 'moment';
// Helpers
import { parseForInnerHTMLWithBreaks } from 'helpers/textEditor';
// Types
import {
  GetCampaignsV2_getCampaigns_entities,
  GetCampaignsV2_getCampaigns_entities_activities_EventMarketingActivity,
} from 'api/campaignV2/types/GetCampaignsV2';
// Styles
import styles from './EventMarketingDetailsModal.module.scss';

type EventMarketingDetailsModalProps = {
  record: GetCampaignsV2_getCampaigns_entities;
};

const EventMarketingDetailsModal = ({
  record,
}: EventMarketingDetailsModalProps): JSX.Element => {
  const activity = record
    .activities?.[0] as GetCampaignsV2_getCampaigns_entities_activities_EventMarketingActivity;

  return (
    <div className={styles.root}>
      <div className={styles.recordData}>
        <div>
          <p className={styles.label}>ID:</p>
          <p className={styles.value}>{record.id}</p>
        </div>

        <div>
          <p className={styles.label}>Event Name:</p>
          <p className={styles.value}>{record.name}</p>
        </div>

        <div>
          <p className={styles.label}>Event date and time:</p>
          <p className={styles.value}>
            {moment(activity.startDate).format('M/D/YYYY, h:mm A')}
          </p>
        </div>

        <div>
          <p className={styles.label}>Sport:</p>
          <p className={styles.value}>{activity.sport}</p>
        </div>

        <div>
          <p className={styles.label}>Number of participants:</p>
          <p className={styles.value}>
            {activity.participantCount} participants
          </p>
        </div>

        <div>
          <p className={styles.label}>Description/Instructions:</p>
          <p
            className={styles.value}
            dangerouslySetInnerHTML={{
              __html: parseForInnerHTMLWithBreaks(activity.description),
            }}
          />
        </div>

        <div>
          <p className={styles.label}>Social media links:</p>
          {activity.socialMediaLinks?.length ? (
            activity.socialMediaLinks.map((link) => (
              <p className={styles.value} key={link}>
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </p>
            ))
          ) : (
            <p className={styles.value}>No links provided</p>
          )}
        </div>
        <div>
          <p className={styles.label}>Number of Pro Interviews:</p>
          <p className={styles.value}>
            {activity.participantCount} Pro Interviews
          </p>
        </div>

        <div>
          <p className={styles.label}>Short form content deliverables:</p>
          <p className={styles.value}>~{activity.shortFormVideoCount} clips</p>
        </div>

        <div>
          <p className={styles.label}>Geo Targeted Views Guaranteed:</p>
          <p className={styles.value}>~{activity.estimatedViews}+</p>
        </div>
      </div>
    </div>
  );
};

export default EventMarketingDetailsModal;
