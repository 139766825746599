import React, { useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  DeleteOutlined,
  QuestionCircleTwoTone,
  ShareAltOutlined,
  UploadOutlined,
} from '@ant-design/icons';
// Constants
import { commonTimeFormat } from 'constants/global';
// Api
import {
  DELETE_EDITED_VIDEO_BY_ID,
  EDIT_VIDEO_REQUEST_INFO,
} from 'api/videoLab/mutation';
import { GET_VIDEO_EDIT_REQUESTS } from 'api/videoLab/queries';
import { CREATE_UNREGISTERED_CONTACT } from 'api/mentions/mutations';
// Types
import {
  GetVideoEditRequests_getVideoEditRequests_entities,
  GetVideoEditRequests_getVideoEditRequests_entities_editedVideos,
} from 'api/videoLab/types/GetVideoEditRequests';
import {
  CreateMentionInput,
  CreateUnregisteredContactInput,
} from 'api/graphql-global-types';
import {
  EditVideoRequestInfo,
  EditVideoRequestInfoVariables,
} from 'api/videoLab/types/EditVideoRequestInfo';
import { VideoEditRequestStatus } from 'api/graphql-global-types';
import {
  DeleteEditedVideoById,
  DeleteEditedVideoByIdVariables,
} from 'api/videoLab/types/DeleteEditedVideoById';
import {
  CreateUnregisteredContact,
  CreateUnregisteredContactVariables,
} from 'api/mentions/types/CreateUnregisteredContact';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import { Mentions, MentionValues } from 'uiShared/Mentions/Mentions';
import TagsInput from 'uiShared/TagsInput/TagsInput';
import { tagStyles } from 'uiShared/TagWithButtons/helpers';
// Helpers
import { formatHashtagInput } from 'helpers/hashtags';
import { formatMentionsInput } from 'helpers/mentions';
// Components
import UploadFinalizedVideo from 'pages/VideoEditingRequest/VideoEditorView/UploadFinalizedVideo/UploadFinalizedVideo';
import VideoRequestSourceFields from '../../../common/VideoRequestSourceFields/VideoRequestSourceFields';
// Styles
import styles from './VideoEditRequestDetailsModal.module.scss';

const { Text } = Typography;
const { TextArea } = Input;

type AdminEditVideoRequestInput = {
  videoEditorId: string | null;
  createdAt: Moment;
  dueDate: Moment | null;
  rawUrls: string | null;
  adminNotes: string | null;
  notes: string | null;
};

type VideoEditRequestDetailsModalProps = {
  videoEditRequest: GetVideoEditRequests_getVideoEditRequests_entities | null;
  hideDetailsModal: () => void;
  isViewOnly?: boolean;
};

const VideoEditRequestDetailsModal = ({
  videoEditRequest,
  hideDetailsModal,
  isViewOnly = false,
}: VideoEditRequestDetailsModalProps): JSX.Element | null => {
  const [editedVideos, setEditedVideos] = useState<
    GetVideoEditRequests_getVideoEditRequests_entities_editedVideos[]
  >(videoEditRequest?.editedVideos || []);

  const disabledDate = (current: Moment | null) => {
    if (!current) return false;

    return moment(current).isBefore(moment().add(-1, 'minute'));
  };

  const [rawUrls, setRawUrls] = useState<string[]>(
    videoEditRequest?.rawUrls || []
  );
  const [hashtagValues, setHashtagValues] = useState(
    videoEditRequest?.hashtags?.map((tag) => tag.name) || []
  );

  const [mentionsValues, setMentionsValues] = useState<MentionValues[]>(
    videoEditRequest?.mentions || []
  );
  const [inputValue, setInputValue] = useState('');
  const [selectedEditedVideo, setSelectedEditedVideo] =
    useState<GetVideoEditRequests_getVideoEditRequests_entities_editedVideos | null>(
      null
    );
  const [isUploadModalVisible, setIsUploadModalVisible] =
    useState<boolean>(false);

  const showUploadModal = () => setIsUploadModalVisible(true);

  const hideUploadModal = () => {
    setIsUploadModalVisible(false);
    setSelectedEditedVideo(null);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && inputValue.trim()) {
      e.preventDefault();
      setRawUrls([...rawUrls, inputValue.trim()]);
      setInputValue('');
    }
  };

  const initialFormValues: AdminEditVideoRequestInput = {
    videoEditorId: videoEditRequest?.assignedEditorId || null,
    createdAt: moment(videoEditRequest?.createdAt) || '-',
    dueDate: moment(videoEditRequest?.dueDate) || '-',
    rawUrls: videoEditRequest?.rawUrls?.join(', ') || null,
    adminNotes: videoEditRequest?.adminNotes || null,
    notes: videoEditRequest?.notes || null,
  };

  const [createUnregisteredContact] = useMutation<
    CreateUnregisteredContact,
    CreateUnregisteredContactVariables
  >(CREATE_UNREGISTERED_CONTACT);

  const [editVideoRequestInfo, { loading: editVideoRequestInfoLoading }] =
    useMutation<EditVideoRequestInfo, EditVideoRequestInfoVariables>(
      EDIT_VIDEO_REQUEST_INFO
    );

  const [deleteEditedVideoById] = useMutation<
    DeleteEditedVideoById,
    DeleteEditedVideoByIdVariables
  >(DELETE_EDITED_VIDEO_BY_ID);

  const { refetch: refetchVideoEditData } = useQuery(GET_VIDEO_EDIT_REQUESTS, {
    variables: {
      input: {
        videoEditorIds: [videoEditRequest?.assignedEditorId],
      },
    },
  });

  const handleRefetchVideos = async () => {
    const { data: refetchedData } = await refetchVideoEditData({
      input: {
        videoEditorIds: [videoEditRequest?.assignedEditorId],
      },
    });
    const videoRequestItemEditedVideos =
      refetchedData?.getVideoEditRequests?.entities?.filter(
        (item: GetVideoEditRequests_getVideoEditRequests_entities) =>
          item?.id === videoEditRequest?.id
      )[0]?.editedVideos;
    setEditedVideos(videoRequestItemEditedVideos);
  };

  const [form] = Form.useForm();

  const handleFormFinish = async () => {
    const adminNotes = form.getFieldValue('adminNotes');
    const dueDate = form.getFieldValue('dueDate');

    const formattedHashtagValues = hashtagValues?.length
      ? formatHashtagInput(hashtagValues)
      : null;

    const createUnregisteredContactInput =
      (mentionsValues as MentionValues[])?.filter(
        (mention) => !mention?.targetType
      ) || [];

    const unregisterContactsData = await createUnregisteredContact({
      variables: {
        input:
          createUnregisteredContactInput as CreateUnregisteredContactInput[],
      },
    });

    const newUnregisteredContacts =
      (unregisterContactsData?.data?.createUnregisteredContact || [])?.map(
        (contact) => ({
          unregisteredContactId: contact?.id,
        })
      ) || [];

    const oldMentions: MentionValues[] =
      mentionsValues?.filter((mention) => mention?.targetType) || [];
    const formatedOldMentions = formatMentionsInput(oldMentions);

    try {
      await editVideoRequestInfo({
        variables: {
          input: {
            id: videoEditRequest?.id || '',
            adminNotes,
            dueDate: dueDate,
            hashtagInputs: formattedHashtagValues,
            status: videoEditRequest?.status,
            mentionsInputs: [
              ...formatedOldMentions,
              ...newUnregisteredContacts,
            ] as CreateMentionInput[],
          },
        },
      });

      successNotification('Information updated successfully');
      hideDetailsModal();
      refetchVideoEditData();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  const handleMentionChange = useCallback(
    (mentions) => {
      if (JSON.stringify(mentions) !== JSON.stringify(mentionsValues)) {
        return setMentionsValues(mentions);
      }
    },
    [mentionsValues]
  );

  useEffect(() => {
    if (videoEditRequest) {
      form.setFieldsValue({
        adminNotes: videoEditRequest?.adminNotes || '',
      });
    }
  }, [videoEditRequest, form]);

  if (editVideoRequestInfoLoading) {
    return <Spin />;
  }

  if (!videoEditRequest) {
    return null;
  }

  const handleEditedVideoClick = (
    editedVideo: GetVideoEditRequests_getVideoEditRequests_entities_editedVideos
  ) => {
    setSelectedEditedVideo(editedVideo);
    showUploadModal();
  };

  const handleCopyLinkButtonClick = async (url: string) => {
    try {
      await navigator.clipboard.writeText(url);

      successNotification('Link copied');
    } catch (error) {
      errorNotification('Could not copy text');
    }
  };

  const handleRemoveEditedVideo = async (
    editedVideoId: string,
    key: string
  ) => {
    try {
      await deleteEditedVideoById({
        variables: { input: { editedVideoId, key } },
      });
      successNotification('The stream has been deleted successfully');
      handleRefetchVideos();
    } catch (err: any) {
      errorNotification(err?.message);
    }
  };

  // flag used to determine user options-if the request was rejected,
  // we have different UI and options
  const isReupload: boolean =
    videoEditRequest?.status === VideoEditRequestStatus.Rejected;

  return (
    <>
      <Form
        form={form}
        layout="horizontal"
        name="videoRequestDetailsForm"
        autoComplete="off"
        initialValues={initialFormValues}
        onFinish={handleFormFinish}
      >
        <div>
          <p>Task ID: {videoEditRequest?.id || ''}</p>
        </div>

        <div>
          <p>Status: {videoEditRequest?.status || ''}</p>
        </div>

        <div>
          <p>Store name: {videoEditRequest?.assignedEditor?.firstName || ''}</p>
        </div>

        {!!videoEditRequest?.additionalStores.length && (
          <Form.Item label={<Text>Additional store names</Text>}>
            <div>
              {videoEditRequest.additionalStores.map((store, index) => (
                <Tag key={index} style={tagStyles}>
                  <div className={styles.text}>
                    {store?.storeDetails?.storeName || ''}
                  </div>
                </Tag>
              ))}
            </div>
          </Form.Item>
        )}

        <VideoRequestSourceFields
          source={videoEditRequest.videoSource}
          withInterviewId
          withStreamId
          withMediaPostId
        />

        <Form.Item name="createdAt" label={<Text>Created at</Text>}>
          <DatePicker
            data-testid="createdAt"
            showTime
            format={commonTimeFormat}
            style={{ width: 250 }}
            showNow
            disabled
          />
        </Form.Item>

        <Form.Item name="dueDate" label={<Text>Due date</Text>}>
          <DatePicker
            data-testid="dueDate"
            disabledDate={disabledDate}
            showTime
            format={commonTimeFormat}
            style={{ width: 250 }}
            minuteStep={15}
            showNow={true}
            disabled={isViewOnly}
          />
        </Form.Item>

        <Form.Item
          label={<Text>Raw video link</Text>}
          rules={[
            {
              required: true,
              message: 'Please add raw video urls',
            },
          ]}
        >
          <TextArea
            value={inputValue}
            onChange={(e) => handleInputChange(e)}
            onKeyPress={handleKeyPress}
            placeholder="Enter each URL and press Enter"
            autoSize={{ minRows: 1, maxRows: 10 }}
            disabled={isViewOnly}
          />
          <div style={{ marginTop: '10px' }}>
            {rawUrls.map((url, index) => (
              <Tag key={index} style={tagStyles}>
                <div className={styles.text}>{url}</div>

                <Button
                  type="link"
                  icon={<ShareAltOutlined />}
                  onClick={() => handleCopyLinkButtonClick(url)}
                  className={styles.tagButton}
                />
              </Tag>
            ))}
          </div>
        </Form.Item>

        <Form.Item label={<Text>Finalized Video</Text>}>
          <div>
            {editedVideos?.map((item, index) => (
              <Tag
                key={index}
                onClick={() => handleEditedVideoClick(item)}
                className={styles.videoTag}
              >
                {item?.title || `finalized_video_${index}`}
                {!isViewOnly && (
                  <Button
                    className={styles.videoTagButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveEditedVideo(item.id, item.key);
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                )}
              </Tag>
            ))}
          </div>
        </Form.Item>

        {!isViewOnly && !isReupload && (
          <Space className="video-modal-container" direction="vertical">
            <Button
              type="primary"
              onClick={showUploadModal}
              className={styles.addButton}
            >
              {<UploadOutlined />}
              Upload finalized video
            </Button>
          </Space>
        )}

        <Form.Item name="notes" label={<Text>Editor Notes</Text>}>
          <Input disabled placeholder="Add notes here" />
        </Form.Item>

        <Form.Item name="adminNotes" label={<Text>Admin Notes</Text>}>
          <Input disabled={isViewOnly} placeholder="Add notes here" />
        </Form.Item>

        <Form.Item name="hashtags" label="Tagging">
          <TagsInput
            hashtags={hashtagValues}
            onChange={(value: any) => setHashtagValues(value)}
            name="hashtags"
          />
        </Form.Item>

        <Form.Item name="mentions" className={styles.mentionsRoot}>
          <Text className={styles.mentionSectionTitleWrapper}>
            <p className={styles.mentionsSectionTitle}>Mentioning options</p>
            <Tooltip
              title="Mention athletes, brands, and organizations by using their names and
          links to directly engage them and increase interaction on your
          content. Write down their names and url links."
            >
              <QuestionCircleTwoTone />
            </Tooltip>
          </Text>
          <div className={styles.mentionsContainer}>
            <Mentions
              mentions={mentionsValues}
              onChange={handleMentionChange}
            />
          </div>
        </Form.Item>

        {!isViewOnly && (
          <Form.Item className={styles.saveButtonContainer}>
            <Button
              type="primary"
              htmlType="submit"
              loading={editVideoRequestInfoLoading}
            >
              Save
            </Button>
          </Form.Item>
        )}
      </Form>

      <Modal
        title="Upload finalized video"
        visible={!!isUploadModalVisible}
        footer={null}
        onCancel={hideUploadModal}
        width={800}
        destroyOnClose
      >
        <UploadFinalizedVideo
          onClose={hideUploadModal}
          isViewOnly={false}
          isAdmin
          videoEditRequest={videoEditRequest}
          selectedEditedVideo={selectedEditedVideo}
          handleRefetchVideos={handleRefetchVideos}
        />
      </Modal>
    </>
  );
};

export default VideoEditRequestDetailsModal;
