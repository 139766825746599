import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { Button } from 'antd';
// Api
import { GET_CAMPAIGN_OFFERS } from 'api/campaignV2/queries';
import { COMPLETED_CAMPAIGN_OFFER } from 'api/campaignV2/mutations';
// Types
import { GetCampaignsV2_getCampaigns_entities } from 'api/campaignV2/types/GetCampaignsV2';
import {
  CompleteCampaignOffer,
  CompleteCampaignOfferVariables,
} from 'api/campaignV2/types/CompleteCampaignOffer';
import {
  GetCampaignOffers,
  GetCampaignOffers_getCampaignOffers_entities,
  GetCampaignOffersVariables,
} from 'api/campaignV2/types/GetCampaignOffers';
import {
  CampaignOffersOrderBy,
  CampaignOfferStatus,
  CampaignStatus,
  CampaignType,
  GetCampaignOffersInput,
  SortDirection,
} from 'api/graphql-global-types';
// Helpers
import {
  CAMPAIGN_OFFER_STATUS_OPTIONS,
  getFollowerRange,
  mapOfferStatusToLabel,
  SOCIAL_FOLLOWERS_OPTIONS,
} from 'helpers/campaign';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import SearchByNameAndEmail from './components/SearchByNameAndEmail/SearchByNameAndEmail';
import CampaignDeliverables from './components/CampaignDeliverables/CampaignDeliverables';
// Styles
import styles from './TalentTable.module.scss';

type TalentTableProps = {
  record: GetCampaignsV2_getCampaigns_entities | null;
};

const TalentTable = ({ record }: TalentTableProps): JSX.Element | null => {
  const [deliverablesModalData, setDeliverablesModalData] =
    useState<GetCampaignOffers_getCampaignOffers_entities | null>(null);

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [sort, setSortInfo] = useState<SortedInfo<CampaignOffersOrderBy>>({
    order: SortDirection.DESC,
    key: CampaignOffersOrderBy.name,
  });

  const getCampaignOffersInput: any = () => {
    const input: GetCampaignOffersInput = {
      campaignId: record?.id,
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      if (key === CampaignOffersOrderBy.status) {
        return (input['statusesV2'] = [value] as CampaignOfferStatus[]);
      }
      if (key === CampaignOffersOrderBy.socialFollowing) {
        const result = getFollowerRange(value as string);

        return (input['socialFollowingRange'] = result);
      }
    });

    return { input };
  };

  const {
    data: offerData,
    loading,
    refetch,
  } = useQuery<GetCampaignOffers, GetCampaignOffersVariables>(
    GET_CAMPAIGN_OFFERS,
    {
      variables: {
        ...getCampaignOffersInput(),
      },
      fetchPolicy: 'cache-and-network',
      skip: !record?.id,
    }
  );

  const [completeCampaignOffer] = useMutation<
    CompleteCampaignOffer,
    CompleteCampaignOfferVariables
  >(COMPLETED_CAMPAIGN_OFFER);

  const handleCompleteCampaignOffer = async (
    offer: GetCampaignOffers_getCampaignOffers_entities
  ) => {
    try {
      await completeCampaignOffer({
        variables: {
          input: {
            ownerId: offer.userId,
            offerId: offer.id,
          },
        },
      });

      successNotification('Campaign offer completed!');
    } catch (err) {
      errorNotification((err as Error)?.message);
    }
  };

  const handleOpenDeliverablesModal = (
    record: GetCampaignOffers_getCampaignOffers_entities
  ) => {
    setDeliverablesModalData(record);
  };

  const handleCloseDeliverablesModal = () => {
    setDeliverablesModalData(null);
  };

  const handleFinish = () => {
    handleCloseDeliverablesModal();
    refetch();
  };

  const talents = offerData?.getCampaignOffers?.entities;

  const columns = [
    {
      title: 'Talent id',
      dataIndex: 'userId',
      key: CampaignOffersOrderBy.id,
      align: 'left' as const,
      width: 150,
      sorterType: 'text',
    },
    {
      title: 'Talent Name',
      dataIndex: 'profileName',
      key: CampaignOffersOrderBy.name,
      align: 'left' as const,
      width: 150,
      sorterType: 'text',
    },
    {
      title: 'Following',
      dataIndex: 'socialFollowing',
      key: CampaignOffersOrderBy.socialFollowing,
      align: 'left' as const,
      width: 150,
      sorterType: 'number',
      withRadioFilters: true,
      filters: SOCIAL_FOLLOWERS_OPTIONS,
    },
    {
      title: 'Submission Date',
      dataIndex: 'submittedAt',
      key: CampaignOffersOrderBy.submittedAt,
      align: 'left' as const,
      width: 150,
      sorterType: 'date',
      render: function DisplayDate(
        dueData: string,
        item: GetCampaignOffers_getCampaignOffers_entities
      ) {
        return (
          <span>
            {item.submittedAt
              ? moment(item.submittedAt).local().format('MM/DD/YYYY, h:mm a')
              : 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: CampaignOffersOrderBy.status,
      align: 'left' as const,
      width: 150,
      withRadioFilters: true,
      filters: CAMPAIGN_OFFER_STATUS_OPTIONS,
      filterMultiple: true,
      render: function DisplayDate(
        status: string,
        item: GetCampaignOffers_getCampaignOffers_entities
      ) {
        return <span>{mapOfferStatusToLabel(item.statusV2)}</span>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      align: 'center' as const,
      width: 50,
      render: function Actions(
        id: string,
        record: GetCampaignOffers_getCampaignOffers_entities
      ) {
        const isCompletedOrPublished =
          record?.statusV2 === CampaignOfferStatus.PUBLISHED ||
          record?.statusV2 === CampaignOfferStatus.COMPLETED;
        const isAccepted = record.statusV2 === CampaignOfferStatus.ACCEPTED;
        const isCompleted = record.statusV2 === CampaignOfferStatus.COMPLETED;

        const isLiveAppearance =
          record.submissions[0]?.__typename ===
          'CampaignLiveAppearanceSubmission';

        return (
          <div className={styles.actions}>
            {!isCompleted && (
              <Button
                key={record.userId}
                onClick={() => handleCompleteCampaignOffer(record)}
                type="primary"
              >
                Complete campaign offer
              </Button>
            )}

            {isAccepted ||
              (isCompletedOrPublished ? (
                <>
                  {!isLiveAppearance && (
                    <Button
                      key={record.userId}
                      onClick={() => handleOpenDeliverablesModal(record)}
                      type="primary"
                    >
                      {isCompletedOrPublished ? 'View' : 'Submit'}
                    </Button>
                  )}
                </>
              ) : null)}
          </div>
        );
      },
    },
  ];

  // don't show the table for bookMe campaigns
  const showTalentTable =
    record?.type === CampaignType.Social ||
    record?.type === CampaignType.SponsoredAppearance;

  // don't show the talent selection and adding part for bookMe campaigns or
  // campaigns without fitting status
  const showTalentAdd =
    showTalentTable &&
    (record?.status === CampaignStatus.ON_REVIEW ||
      record?.status === CampaignStatus.DETAIL_FILLED ||
      record?.status === CampaignStatus.PAID_AND_ACTIVE);

  if (!record) {
    return null;
  }

  return (
    <div className={styles.root}>
      {showTalentAdd && (
        <SearchByNameAndEmail record={record} onAdd={refetch} />
      )}

      {showTalentTable && (
        <Table<
          GetCampaignOffers_getCampaignOffers_entities,
          CampaignOffersOrderBy
        >
          columns={columns}
          data={talents}
          scroll={{ x: 300 }}
          loading={loading}
          total={offerData?.getCampaignOffers.total}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          sortInfo={sort}
          setSortInfo={setSortInfo}
        />
      )}

      <CampaignDeliverables
        record={deliverablesModalData}
        onClose={handleCloseDeliverablesModal}
        onFinish={handleFinish}
      />
    </div>
  );
};

export default TalentTable;
