import React, { ChangeEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Input } from 'antd';
// Api
import { ADD_OFFLINE_PAYMENT } from 'api/campaignV2/mutations';
// Types
import { GetCampaignsV2_getCampaigns_entities } from 'api/campaignV2/types/GetCampaignsV2';
import {
  AddOfflineCampaignPayment,
  AddOfflineCampaignPaymentVariables,
} from 'api/campaignV2/types/AddOfflineCampaignPayment';
// Ui
import { successNotification, errorNotification } from 'ui/Notification';
// Style
import styles from './ChangeCampaignBudget.module.scss';

type ChangeCampaignBudgetProps = {
  record: GetCampaignsV2_getCampaigns_entities;
};

const ChangeCampaignBudget = ({
  record,
}: ChangeCampaignBudgetProps): JSX.Element | null => {
  const [newBudget, setNewBudget] = useState<string>('');
  const [comment, setComment] = useState<string | null>(null);

  const [addOfflineCampaign] = useMutation<
    AddOfflineCampaignPayment,
    AddOfflineCampaignPaymentVariables
  >(ADD_OFFLINE_PAYMENT);

  const handleBudgetChange = (newValue: ChangeEvent<HTMLInputElement>) => {
    const newBudgetValue = newValue.target.value;

    const positiveOrNegativeNumber = /^[-+]?\d+$/;

    const isValid = positiveOrNegativeNumber.test(newBudgetValue);
    if (isValid) {
      setNewBudget(newBudgetValue);
    }
  };

  const handleCommentChange = (newValue: ChangeEvent<HTMLInputElement>) => {
    const newCommentValue = newValue.target.value;
    if (newCommentValue !== '') {
      setComment(newCommentValue);
    } else {
      setComment(null);
    }
  };

  const handleUpdateBudget = async () => {
    if (record?.id && Number(newBudget)) {
      try {
        const { data } = await addOfflineCampaign({
          variables: {
            input: {
              amount: Number(newBudget),
              campaignId: record?.id,
              ...(comment && { comment }),
            },
          },
        });

        if (data?.addOfflineCampaignPayment.id) {
          successNotification('Campaign budget has been updated');
        }
      } catch (error) {
        console.error('Error updating budget', error);
        errorNotification('Error updating budget');
      }
    } else {
      errorNotification('Impossible budget value');
    }
  };

  return (
    <div className={styles.root}>
      <p className={styles.label}>Budget Change:</p>
      <div className={styles.budgetInputAndButton}>
        <Input
          onChange={handleBudgetChange}
          style={{ width: 250 }}
          placeholder="$"
        />
        <Input
          onChange={handleCommentChange}
          style={{ width: 250 }}
          placeholder="Reason"
        />
        <Button type="primary" onClick={handleUpdateBudget}>
          Add budget
        </Button>
      </div>
      <p className={styles.budgetExplanation}>Positive number to add budget</p>
      <p className={styles.budgetExplanation}>
        Negative number to subtract budget
      </p>
    </div>
  );
};

export default ChangeCampaignBudget;
