import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Link,
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Spin, Layout, Menu, Typography, Button, BackTop, Space } from 'antd';
import {
  PicCenterOutlined,
  DashboardOutlined,
  ControlOutlined,
  TeamOutlined,
  RollbackOutlined,
  ReconciliationOutlined,
  SlidersOutlined,
  EyeOutlined,
  CarryOutOutlined,
  HomeOutlined,
  DollarOutlined,
  UserOutlined,
  ShoppingOutlined,
  SkinOutlined,
  CalendarOutlined,
  BarcodeOutlined,
  ProfileOutlined,
  DribbbleOutlined,
  BorderlessTableOutlined,
  FormatPainterOutlined,
  PlaySquareOutlined,
  AuditOutlined,
  ArrowUpOutlined,
  RedoOutlined,
  VideoCameraOutlined,
  TableOutlined,
  LoadingOutlined,
  MessageOutlined,
  UploadOutlined,
  AudioOutlined,
  UnorderedListOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
// Api
import { SIGN_OUT } from 'api/auth/mutations';
// Types
import { UserRole } from 'api/graphql-global-types';
import { LocationStateType } from 'pages/StreamsCalendar/components/StreamsListItem/StreamsListItem';
import { SignOut, SignOutVariables } from 'api/auth/types/SignOut';
// Hooks
import { useAppContext } from 'hooks';
// Constants
import {
  LOGIN,
  HOME_PAGE_MANAGEMENT,
  DASHBOARD,
  TAGS,
  // MANAGE_MERCH_GEN,
  // MANAGE_MERCH,
  MANAGE_MERCH_TSHIRT_GEN,
  MANAGE_MERCH_HOODIE_GEN,
  MANAGE_MERCH_HAT_GEN,
  MANAGE_MERCH_JOGGERS_GEN,
  MANAGE_MERCH_RASH_GUARD_GEN,
  MANAGE_MERCH_TSHIRT,
  MANAGE_MERCH_HOODIE,
  MANAGE_MERCH_HAT,
  MANAGE_MERCH_JOGGERS,
  MANAGE_MERCH_RASH_GUARD,
  MANAGE_ATHLETES_STORES,
  MANAGE_ATHLETES_STORES_WITH_PAGINATION,
  ATHLETE_DASHBOARD,
  ATHLETE_DASHBOARD_GEN,
  MANAGE_ATHLETE_AMA_REQUESTS_GEN,
  MANAGE_ATHLETE_AMA_REQUESTS,
  MANAGE_ATHLETE_SCHEDULED_STREAMS,
  MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN,
  SETUP_ATHLETE_STREAM_GEN,
  SETUP_ATHLETE_STREAM,
  SETUP_ATHLETE_AMA_GEN,
  SETUP_ATHLETE_AMA,
  ATHLETE_DEALS_SETUP_GEN,
  ATHLETE_DEALS_SETUP,
  MANAGE_ATHLETE_MERCH_GEN,
  MANAGE_ATHLETE_MERCH,
  MANAGE_ATHLETE_PRODUCTS_GEN,
  MANAGE_ATHLETE_PRODUCTS,
  MANAGE_ATHLETE_EXPERIENCE_GEN,
  MANAGE_ATHLETE_EXPERIENCE,
  MANAGE_ATHLETE_DESIGNS_GEN,
  MANAGE_ATHLETE_DESIGNS,
  MANAGE_ATHLETE_BIO_GEN,
  MANAGE_ATHLETE_BIO,
  MEDIA_FEED_ATHLETE_GEN,
  MEDIA_FEED_ATHLETE,
  ATHLETE_STORE_SALES_GEN,
  ATHLETE_SCHEDULED_EVENTS_GEN,
  ATHLETE_SCHEDULED_EVENTS,
  ORGANIZATION_SCHEDULED_EVENTS_GEN,
  ORGANIZATION_SCHEDULED_EVENTS,
  CONTENT_CREATOR_SCHEDULED_EVENTS_GEN,
  CONTENT_CREATOR_SCHEDULED_EVENTS,
  ATHLETE_STORE_SALES,
  CUSTOMERS,
  MANAGE_ACCOUNT,
  CUSTOMERS_WITH_PAGINATION,
  CUSTOMER_PURCHASES_GEN,
  CUSTOMER_PURCHASES,
  CUSTOMER_SETTINGS_GEN,
  CUSTOMER_SETTINGS,
  CUSTOMER_ADDRESSES_GEN,
  CUSTOMER_ADDRESSES,
  CUSTOMER_PAYMENT_GEN,
  CUSTOMER_PAYMENT,
  ALL_PRODUCT_MEMORABILIA,
  ALL_AMA_REQUESTS,
  STREAMS_CALENDAR,
  ALL_STREAMS_TABLE,
  WATCH_STREAM,
  PROMO_CODES,
  DESIGN_REQUESTS,
  DESIGNERS,
  EDITING_REQUESTS,
  MY_EDITING_REQUESTS,
  VIDEO_EDITORS,
  VIDEO_EDITOR_PROFILE,
  MY_DESIGN_REQUESTS,
  REDIRECTS,
  GENERAL_SALES,
  EXPERIENCE_SALES,
  WATCH_STREAM_GEN,
  MERCH_SALES_REPORT,
  AMA_SALES_REPORT,
  STREAM_SALES_REPORT,
  MANAGE_WATCH_STREAM_MODERATORS_GEN,
  LIST_OF_ASSIGNED_STREAMS,
  ADMINS,
  ACCOUNT,
  DESIGN_REQUEST_RESULT_DETAILS,
  DESIGN_REQUEST_DETAILS,
  DESIGN_REQUEST_DETAILS_GEN,
  DESIGN_REQUEST_RESULT_DETAILS_GEN,
  MANAGE_MESSAGES,
  MANAGE_MESSAGES_WITH_PAGINATION,
  CAMPAIGNS,
  CAMPAIGN_DETAILS,
  CAMPAIGN_DETAILS_GEN,
  MANAGE_CONTENT_CREATOR_PRODUCTS_GEN,
  MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN,
  MANAGE_ORGANIZATION_EXPERIENCE_GEN,
  MANAGE_ORGANIZATION_PRODUCTS_GEN,
  MANAGE_ORGANIZATIONS_STORES,
  ORGANIZATION_DASHBOARD_GEN,
  MANAGE_ORGANIZATION_AMA_REQUESTS_GEN,
  MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN,
  SETUP_ORGANIZATION_AMA_GEN,
  SETUP_ORGANIZATION_STREAM_GEN,
  MANAGE_ORGANIZATION_MERCH_GEN,
  MANAGE_ORGANIZATION_DESIGNS_GEN,
  MANAGE_ORGANIZATION_BIO_GEN,
  MEDIA_FEED_ORGANIZATION_GEN,
  ORGANIZATION_STORE_SALES_GEN,
  MANAGE_ORGANIZATION_PRODUCTS,
  MANAGE_ORGANIZATION_EXPERIENCE,
  MANAGE_CONTENT_CREATOR_PRODUCTS,
  MANAGE_CONTENT_CREATOR_EXPERIENCE,
  MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION,
  ORGANIZATION_DASHBOARD,
  MANAGE_ORGANIZATION_AMA_REQUESTS,
  MANAGE_ORGANIZATION_SCHEDULED_STREAMS,
  SETUP_ORGANIZATION_STREAM,
  SETUP_ORGANIZATION_AMA,
  MANAGE_ORGANIZATION_MERCH,
  MANAGE_ORGANIZATION_DESIGNS,
  MANAGE_ORGANIZATION_BIO,
  MEDIA_FEED_ORGANIZATION,
  ORGANIZATION_STORE_SALES,
  MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION,
  CONTENT_CREATOR_DASHBOARD_GEN,
  CONTENT_CREATOR_DASHBOARD,
  MANAGE_CONTENT_CREATOR_AMA_REQUESTS,
  MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS,
  MANAGE_CONTENT_CREATOR_MERCH,
  MANAGE_CONTENT_CREATOR_MERCH_GEN,
  SETUP_CONTENT_CREATOR_AMA,
  CONTENT_CREATOR_DEALS_SETUP,
  SETUP_CONTENT_CREATOR_STREAM,
  MANAGE_CONTENT_CREATOR_DESIGNS_GEN,
  MANAGE_CONTENT_CREATOR_DESIGNS,
  MANAGE_CONTENT_CREATOR_BIO_GEN,
  MANAGE_CONTENT_CREATOR_BIO,
  MEDIA_FEED_CONTENT_CREATOR_GEN,
  MEDIA_FEED_CONTENT_CREATOR,
  CONTENT_CREATOR_STORE_SALES_GEN,
  CONTENT_CREATOR_STORE_SALES,
  MANAGE_CONTENT_CREATORS_STORES,
  MANAGE_CONTENT_CREATOR_AMA_REQUESTS_GEN,
  MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN,
  SETUP_CONTENT_CREATOR_AMA_GEN,
  CONTENT_CREATOR_DEALS_SETUP_GEN,
  SETUP_CONTENT_CREATOR_STREAM_GEN,
  BULK_UPLOAD,
  MANUAL_MERCH_MERGE,
  INTERVIEWS,
  INTERVIEWERS,
  INTERVIEW_SETTINGS,
  MY_INTERVIEWS,
  MY_SCHEDULED_EVENTS,
  MY_AVAILABILITY,
  VIDEO_EDIT_REQUEST_SETTINGS,
  ARTICLES,
  ARTICLES_WITH_PAGINATION,
} from 'constants/routes';
import {
  CUSTOMERS_DEFAULT_ROUTE,
  ARTICLES_DEFAULT_ROUTE,
  MANAGE_ATHLETES_STORES_DEFAULT_ROUTE,
  MANAGE_MESSAGES_DEFAULT_ROUTE,
  MANAGE_ORGANIZATIONS_STORES_DEFAULT_ROUTE,
  MANAGE_CONTENT_CREATORS_STORES_DEFAULT_ROUTE,
} from 'App';
import { STORE_TYPE } from 'constants/global';
// Components
import LoginForm from 'components/common/LoginForm/LoginForm';
// Styles
import styles from './LayoutWrapper.module.scss';

const { Sider, Header, Content } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

type State = {
  collapsed: boolean | false;
};

export const LAYOUT_ID = 'right-side-layout';

const MENU_ITEMS: Record<string, string> = {
  HOME_PAGE_MANAGEMENT: 'homePageManagement',
  DASHBOARD: 'dashboard',
  TAGS: 'tags',

  // MANAGE_MERCH_GEN: 'manageMerchGen',
  // MANAGE_MERCH: 'manageMerch',
  MANAGE_MERCH_TSHIRT_GEN: 'manageMerchTShirtGen',
  MANAGE_MERCH_TSHIRT: 'manageMerchTShirt',
  MANAGE_MERCH_HOODIE_GEN: 'manageMerchHoodieGen',
  MANAGE_MERCH_HOODIE: 'manageMerchHoodie',
  MANAGE_MERCH_HAT_GEN: 'manageMerchHatGen',
  MANAGE_MERCH_HAT: 'manageMerchHat',
  MANAGE_MERCH_JOGGERS_GEN: 'manageMerchJoggersGen',
  MANAGE_MERCH_JOGGERS: 'manageMerchJoggers',
  MANAGE_MERCH_RASH_GUARD_GEN: 'manageMerchRashGuardGen',
  MANAGE_MERCH_RASH_GUARD: 'manageMerchRashGuard',

  MANAGE_ATHLETES_STORES: 'manageAthletesStoreS',
  MANAGE_ATHLETES_STORES_WITH_PAGINATION: 'manageAthletesStoresWithPagination',
  ATHLETE_DASHBOARD_GEN: 'athleteDashboardGen',
  ATHLETE_DASHBOARD: 'athleteDashboard',
  MANAGE_ATHLETE_AMA_REQUESTS: 'manageAthleteAmaRequests',
  MANAGE_ATHLETE_SCHEDULED_STREAMS: 'manageAthleteScheduledStreams',
  SETUP_ATHLETE_STREAM: 'setupAthleteStream',
  SETUP_ATHLETE_AMA: 'setupAthleteAma',
  ATHLETE_DEALS_SETUP: 'athleteDealsSetup',
  MANAGE_ATHLETE_MERCH_GEN: 'manageAthleteMerchGen',
  MANAGE_ATHLETE_DESIGNS_GEN: 'manageAthleteDesignsGen',
  MANAGE_ATHLETE_MERCH: 'manageAthleteMerch',
  MANAGE_ATHLETE_PRODUCTS_GEN: 'manageAthleteProductsGen',
  MANAGE_ATHLETE_PRODUCTS: 'manageAthleteProducts',
  MANAGE_ATHLETE_EXPERIENCE_GEN: 'manageAthleteExperienceGen',
  MANAGE_ATHLETE_EXPERIENCE: 'manageAthleteExperience',

  MANAGE_ORGANIZATION_PRODUCTS_GEN: 'manageOrganizationProductsGen',
  MANAGE_ORGANIZATION_PRODUCTS: 'manageOrganizationProducts',
  MANAGE_ORGANIZATION_EXPERIENCE_GEN: 'manageOrganizationExperienceGen',
  MANAGE_ORGANIZATION_EXPERIENCE: 'manageOrganizationExperience',

  MANAGE_CONTENT_CREATOR_PRODUCTS_GEN: 'manageContentCreatorProductsGen',
  MANAGE_CONTENT_CREATOR_PRODUCTS: 'manageContentCreatorProducts',
  MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN: 'manageContentCreatorExperienceGen',
  MANAGE_CONTENT_CREATOR_EXPERIENCE: 'manageContentCreatorExperience',

  MANAGE_ATHLETE_DESIGNS: 'manageAthleteDesigns',
  MANAGE_ATHLETE_BIO_GEN: 'manageAthleteBioGen',
  MANAGE_ATHLETE_BIO: 'manageAthleteBio',
  MEDIA_FEED_ATHLETE_GEN: 'mediaFeedAthleteGen',
  MEDIA_FEED_ATHLETE: 'mediaFeedAthlete',
  ATHLETE_STORE_SALES_GEN: 'athleteStoreSalesGen',
  ATHLETE_STORE_SALES: 'athleteStoreSales',

  ATHLETE_SCHEDULED_EVENTS_GEN: 'athleteScheduledEventsGen',
  ATHLETE_SCHEDULED_EVENTS: 'athleteScheduledEvents',

  ORGANIZATION_SCHEDULED_EVENTS_GEN: 'organizationScheduledEventsGen',
  ORGANIZATION_SCHEDULED_EVENTS: 'organizationScheduledEvents',

  CONTENT_CREATOR_SCHEDULED_EVENTS_GEN: 'contentCreatorScheduledEventsGen',
  CONTENT_CREATOR_SCHEDULED_EVENTS: 'contentCreatorScheduledEvents',

  MANAGE_ORGANIZATION_STORES: 'manageOrganizationStores',
  MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION:
    'manageOrganizationStoresWithPagination',
  ORGANIZATION_DASHBOARD_GEN: 'organizationDashboardGen',
  ORGANIZATION_DASHBOARD: 'organizationDashboard',
  MANAGE_ORGANIZATION_AMA_REQUESTS: 'manageOrganizationAmaRequests',
  MANAGE_ORGANIZATION_SCHEDULED_STREAMS: 'manageOrganizationScheduledStreams',
  SETUP_ORGANIZATION_STREAM: 'setupOrganizationStream',
  SETUP_ORGANIZATION_AMA: 'setupOrganizationAma',
  MANAGE_ORGANIZATION_MERCH_GEN: 'manageOrganizationMerchGen',
  MANAGE_ORGANIZATION_DESIGNS_GEN: 'manageOrganizationDesignsGen',
  MANAGE_ORGANIZATION_MERCH: 'manageOrganizationMerch',
  MANAGE_ORGANIZATION_DESIGNS: 'manageOrganizationDesigns',
  MANAGE_ORGANIZATION_BIO_GEN: 'manageOrganizationBioGen',
  MANAGE_ORGANIZATION_BIO: 'manageOrganizationBio',
  MEDIA_FEED_ORGANIZATION_GEN: 'mediaFeedOrganizationGen',
  MEDIA_FEED_ORGANIZATION: 'mediaFeedOrganization',
  ORGANIZATION_STORE_SALES_GEN: 'organizationStoreSalesGen',
  ORGANIZATION_STORE_SALES: 'organizationStoreSales',

  MANAGE_CONTENT_CREATOR_STORES: 'manageContentCreatorStores',
  MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION:
    'manageContentCreatorStoresWithPagination',
  CONTENT_CREATOR_DASHBOARD_GEN: 'contentCreatorDashboardGen',
  CONTENT_CREATOR_DASHBOARD: 'contentCreatorDashboard',
  MANAGE_CONTENT_CREATOR_AMA_REQUESTS: 'manageContentCreatorAmaRequests',
  MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS:
    'manageContentCreatorScheduledStreams',
  SETUP_CONTENT_CREATOR_STREAM: 'setupContentCreatorStream',
  SETUP_CONTENT_CREATOR_AMA: 'setupContentCreatorAma',
  CONTENT_CREATOR_DEALS_SETUP: 'contentCreatorDealsSetup',
  MANAGE_CONTENT_CREATOR_MERCH_GEN: 'manageContentCreatorMerchGen',
  MANAGE_CONTENT_CREATOR_DESIGNS_GEN: 'manageContentCreatorDesignsGen',
  MANAGE_CONTENT_CREATOR_MERCH: 'manageContentCreatorMerch',
  MANAGE_CONTENT_CREATOR_DESIGNS: 'manageContentCreatorDesigns',
  MANAGE_CONTENT_CREATOR_BIO_GEN: 'manageContentCreatorBioGen',
  MANAGE_CONTENT_CREATOR_BIO: 'manageContentCreatorBio',
  MEDIA_FEED_CONTENT_CREATOR_GEN: 'mediaFeedContentCreatorGen',
  MEDIA_FEED_CONTENT_CREATOR: 'mediaFeedContentCreator',
  CONTENT_CREATOR_STORE_SALES_GEN: 'contentCreatorStoreSalesGen',
  CONTENT_CREATOR_STORE_SALES: 'contentCreatorStoreSales',

  CUSTOMERS: 'customers',
  CUSTOMERS_WITH_PAGINATION: 'customersWithPagination',
  CUSTOMER_PURCHASES_GEN: 'customerPurchasesGen',
  CUSTOMER_PURCHASES: 'customerPurchases',
  CUSTOMER_SETTINGS_GEN: 'customerSettingsGen',
  CUSTOMER_SETTINGS: 'customerSettings',
  CUSTOMER_ADDRESSES_GEN: 'customerAddressesGen',
  CUSTOMER_ADDRESSES: 'customerAddresses',
  CUSTOMER_PAYMENT_GEN: 'customerPaymentGen',
  CUSTOMER_PAYMENT: 'customerPayment',

  ALL_PRODUCT_MEMORABILIA: 'allProductMemorabilia',

  ALL_AMA_REQUESTS: 'allAmaRequests',

  STREAMS_CALENDAR: 'streamsCalendar',
  ALL_STREAMS_TABLE: 'allStreamsTable',
  MANAGE_WATCH_STREAM_MODERATORS_GEN: 'manageModerators',
  WATCH_STREAM_GEN: 'watchStreamGen',
  WATCH_STREAM: 'watchStream',

  PROMO_CODES: 'promoCodes',
  BULK_UPLOAD: 'bulkUpload',
  DESIGN_REQUESTS: 'designRequests',
  DESIGNERS: 'designersList',
  GENERAL_SALES: 'generalSales',
  EXPERIENCE_SALES: 'experienceSales',
  MANAGE_MESSAGES: 'manageMessages',
  MANAGE_MESSAGES_WITH_PAGINATION: 'manageMessagesWithPagination',
  CAMPAIGNS: 'Campaigns',
  CAMPAIGN_DETAILS: 'campaignDetails',
  CAMPAIGN_DETAILS_GEN: 'campaignDetailsGen',
  ADMINS: 'Admins',

  EDITING_REQUESTS: 'editingRequests',
  MY_EDITING_REQUESTS: 'myEditingRequest',
  VIDEO_EDITORS: 'videoEditors',
  VIDEO_EDITOR_PROFILE: 'videoEditorProfile',
  MY_DESIGN_REQUESTS: 'myDesignRequests',
  VIDEO_EDIT_REQUEST_SETTINGS: 'videoEditRequestSettings',

  REDIRECTS: 'redirects',

  MERCH_SALES_REPORT: 'athleteSalesReport',
  AMA_SALES_REPORT: 'amaSalesReport',
  STREAM_SALES_REPORT: 'streamSalesReport',

  LIST_OF_ASSIGNED_STREAMS: 'list-of-assigned-streams',
  MANUAL_MERCH_MERGE: 'manualMerchMerge',
  INTERVIEWS: 'interviews',
  INTERVIEWERS: 'interviewers',
  INTERVIEW_SETTINGS: 'interviewSettings',
  MY_SCHEDULE_EVENTS: 'myScheduleEvents',
  MY_AVAILABILITY: 'availability',
  MY_INTERVIEWS: 'myInterviews',
  MY_SCHEDULED_EVENTS: 'myScheduledEvents',

  ARTICLES: 'articles',
  ARTICLES_WITH_PAGINATION: 'articlesWithPagination',
};

const MERCH_MENU_ITEMS: string[] = [
  // MENU_ITEMS.MANAGE_MERCH,
  MENU_ITEMS.MANAGE_MERCH_TSHIRT_GEN,
  MENU_ITEMS.MANAGE_MERCH_TSHIRT,
  MENU_ITEMS.MANAGE_MERCH_HOODIE_GEN,
  MENU_ITEMS.MANAGE_MERCH_HOODIE,
  MENU_ITEMS.MANAGE_MERCH_HAT_GEN,
  MENU_ITEMS.MANAGE_MERCH_HAT,
  MENU_ITEMS.MANAGE_MERCH_JOGGERS_GEN,
  MENU_ITEMS.MANAGE_MERCH_JOGGERS,
  MENU_ITEMS.MANAGE_MERCH_RASH_GUARD_GEN,
  MENU_ITEMS.MANAGE_MERCH_RASH_GUARD,
];

const ATHLETE_MENU_ITEMS: string[] = [
  MENU_ITEMS.ATHLETE_DASHBOARD_GEN,
  MENU_ITEMS.ATHLETE_DASHBOARD,

  MENU_ITEMS.MANAGE_ATHLETE_MERCH,
  ...MERCH_MENU_ITEMS,
  MENU_ITEMS.MANAGE_ATHLETE_DESIGNS,
  MENU_ITEMS.MANAGE_ATHLETE_AMA_REQUESTS,
  MENU_ITEMS.SETUP_ATHLETE_AMA,
  MENU_ITEMS.ATHLETE_DEALS_SETUP,
  MENU_ITEMS.MANAGE_ATHLETE_SCHEDULED_STREAMS,
  MENU_ITEMS.SETUP_ATHLETE_STREAM,
  MENU_ITEMS.MANAGE_ATHLETE_PRODUCTS,
  MENU_ITEMS.MANAGE_ATHLETE_EXPERIENCE,

  MENU_ITEMS.MANAGE_ATHLETE_BIO,
  MENU_ITEMS.MEDIA_FEED_ATHLETE,
  MENU_ITEMS.ATHLETE_STORE_SALES,
  MENU_ITEMS.ATHLETE_SCHEDULED_EVENTS,
];

const ORGANIZATION_MENU_ITEMS: string[] = [
  MENU_ITEMS.ORGANIZATION_DASHBOARD_GEN,
  MENU_ITEMS.ORGANIZATION_DASHBOARD,

  MENU_ITEMS.MANAGE_ORGANIZATION_MERCH,
  ...MERCH_MENU_ITEMS,
  MENU_ITEMS.MANAGE_ORGANIZATION_DESIGNS,
  MENU_ITEMS.MANAGE_ORGANIZATION_AMA_REQUESTS,
  MENU_ITEMS.SETUP_ORGANIZATION_AMA,
  MENU_ITEMS.MANAGE_ORGANIZATION_SCHEDULED_STREAMS,
  MENU_ITEMS.SETUP_ORGANIZATION_STREAM,
  MENU_ITEMS.MANAGE_ORGANIZATION_PRODUCTS,
  MENU_ITEMS.MANAGE_ORGANIZATION_EXPERIENCE,

  MENU_ITEMS.MANAGE_ORGANIZATION_BIO,
  MENU_ITEMS.MEDIA_FEED_ORGANIZATION,
  MENU_ITEMS.ORGANIZATION_STORE_SALES,
  MENU_ITEMS.ORGANIZATION_SCHEDULED_EVENTS,
];

const CONTENT_CREATOR_MENU_ITEMS: string[] = [
  MENU_ITEMS.CONTENT_CREATOR_DASHBOARD_GEN,
  MENU_ITEMS.CONTENT_CREATOR_DASHBOARD,

  MENU_ITEMS.MANAGE_CONTENT_CREATOR_MERCH,
  ...MERCH_MENU_ITEMS,
  MENU_ITEMS.MANAGE_CONTENT_CREATOR_DESIGNS,
  MENU_ITEMS.MANAGE_CONTENT_CREATOR_AMA_REQUESTS,
  MENU_ITEMS.SETUP_CONTENT_CREATOR_AMA,
  MENU_ITEMS.CONTENT_CREATOR_DEALS_SETUP,
  MENU_ITEMS.MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS,
  MENU_ITEMS.SETUP_CONTENT_CREATOR_STREAM,
  MENU_ITEMS.MANAGE_CONTENT_CREATOR_PRODUCTS,
  MENU_ITEMS.MANAGE_CONTENT_CREATOR_EXPERIENCE,

  MENU_ITEMS.MANAGE_CONTENT_CREATOR_BIO,
  MENU_ITEMS.MEDIA_FEED_CONTENT_CREATOR,
  MENU_ITEMS.CONTENT_CREATOR_STORE_SALES,
  MENU_ITEMS.CONTENT_CREATOR_SCHEDULED_EVENTS,
];

// TODO: uncomment it when "Watch Stream" menu will have at least one menu item
// const WATCH_STREAM_MENU_ITEMS: string[] = [
//   MENU_ITEMS.WATCH_STREAM_GEN,
//   MENU_ITEMS.WATCH_STREAM,
// ];

const CUSTOMER_MENU_ITEMS: string[] = [
  MENU_ITEMS.CUSTOMER_PURCHASES,
  MENU_ITEMS.CUSTOMER_SETTINGS,
  MENU_ITEMS.CUSTOMER_ADDRESSES,
  MENU_ITEMS.CUSTOMER_PAYMENT,
];

const MENU_ITEMS_LINKS: Record<string, string> = {
  [MENU_ITEMS.HOME_PAGE_MANAGEMENT]: HOME_PAGE_MANAGEMENT,
  [MENU_ITEMS.DASHBOARD]: DASHBOARD,
  [MENU_ITEMS.TAGS]: TAGS,

  // [MENU_ITEMS.MANAGE_MERCH_GEN]: MANAGE_MERCH_GEN,
  // [MENU_ITEMS.MANAGE_MERCH]: MANAGE_MERCH_GEN,
  [MENU_ITEMS.MANAGE_MERCH_TSHIRT_GEN]: MANAGE_MERCH_TSHIRT_GEN,
  [MENU_ITEMS.MANAGE_MERCH_TSHIRT]: MANAGE_MERCH_TSHIRT,
  [MENU_ITEMS.MANAGE_MERCH_HOODIE_GEN]: MANAGE_MERCH_HOODIE_GEN,
  [MENU_ITEMS.MANAGE_MERCH_HOODIE]: MANAGE_MERCH_HOODIE,
  [MENU_ITEMS.MANAGE_MERCH_HAT_GEN]: MANAGE_MERCH_HAT_GEN,
  [MENU_ITEMS.MANAGE_MERCH_HAT]: MANAGE_MERCH_HAT,
  [MENU_ITEMS.MANAGE_MERCH_JOGGERS_GEN]: MANAGE_MERCH_JOGGERS_GEN,
  [MENU_ITEMS.MANAGE_MERCH_JOGGERS]: MANAGE_MERCH_JOGGERS,
  [MENU_ITEMS.MANAGE_MERCH_RASH_GUARD_GEN]: MANAGE_MERCH_RASH_GUARD_GEN,
  [MENU_ITEMS.MANAGE_MERCH_RASH_GUARD]: MANAGE_MERCH_RASH_GUARD,

  [MENU_ITEMS.MANAGE_ATHLETES_STORES]: MANAGE_ATHLETES_STORES,
  [MENU_ITEMS.ATHLETE_DASHBOARD_GEN]: ATHLETE_DASHBOARD_GEN,
  [MENU_ITEMS.ATHLETE_DASHBOARD]: ATHLETE_DASHBOARD_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_AMA_REQUESTS]: MANAGE_ATHLETE_AMA_REQUESTS_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_SCHEDULED_STREAMS]:
    MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN,
  [MENU_ITEMS.SETUP_ATHLETE_AMA]: SETUP_ATHLETE_AMA_GEN,
  [MENU_ITEMS.ATHLETE_DEALS_SETUP]: ATHLETE_DEALS_SETUP_GEN,
  [MENU_ITEMS.SETUP_ATHLETE_STREAM]: SETUP_ATHLETE_STREAM_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_MERCH_GEN]: MANAGE_ATHLETE_MERCH_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_MERCH]: MANAGE_ATHLETE_MERCH_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_PRODUCTS_GEN]: MANAGE_ATHLETE_PRODUCTS_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_PRODUCTS]: MANAGE_ATHLETE_PRODUCTS_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_EXPERIENCE_GEN]: MANAGE_ATHLETE_EXPERIENCE_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_EXPERIENCE]: MANAGE_ATHLETE_EXPERIENCE_GEN,

  [MENU_ITEMS.MANAGE_ORGANIZATION_PRODUCTS_GEN]:
    MANAGE_ORGANIZATION_PRODUCTS_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_PRODUCTS]: MANAGE_ORGANIZATION_PRODUCTS_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_EXPERIENCE_GEN]:
    MANAGE_ORGANIZATION_EXPERIENCE_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_EXPERIENCE]:
    MANAGE_ORGANIZATION_EXPERIENCE_GEN,

  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_PRODUCTS_GEN]:
    MANAGE_CONTENT_CREATOR_PRODUCTS_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_PRODUCTS]:
    MANAGE_CONTENT_CREATOR_PRODUCTS_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN]:
    MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_EXPERIENCE]:
    MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN,

  [MENU_ITEMS.MANAGE_ATHLETE_DESIGNS_GEN]: MANAGE_ATHLETE_DESIGNS_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_DESIGNS]: MANAGE_ATHLETE_DESIGNS_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_BIO_GEN]: MANAGE_ATHLETE_BIO_GEN,
  [MENU_ITEMS.MANAGE_ATHLETE_BIO]: MANAGE_ATHLETE_BIO_GEN,
  [MENU_ITEMS.MEDIA_FEED_ATHLETE_GEN]: MEDIA_FEED_ATHLETE_GEN,
  [MENU_ITEMS.MEDIA_FEED_ATHLETE]: MEDIA_FEED_ATHLETE_GEN,
  [MENU_ITEMS.ATHLETE_STORE_SALES_GEN]: ATHLETE_STORE_SALES_GEN,
  [MENU_ITEMS.ATHLETE_STORE_SALES]: ATHLETE_STORE_SALES_GEN,

  [MENU_ITEMS.ATHLETE_SCHEDULED_EVENTS_GEN]: ATHLETE_SCHEDULED_EVENTS_GEN,
  [MENU_ITEMS.ATHLETE_SCHEDULED_EVENTS]: ATHLETE_SCHEDULED_EVENTS_GEN,

  [MENU_ITEMS.ORGANIZATION_SCHEDULED_EVENTS_GEN]:
    ORGANIZATION_SCHEDULED_EVENTS_GEN,
  [MENU_ITEMS.ORGANIZATION_SCHEDULED_EVENTS]: ORGANIZATION_SCHEDULED_EVENTS_GEN,

  [MENU_ITEMS.CONTENT_CREATOR_SCHEDULED_EVENTS_GEN]:
    CONTENT_CREATOR_SCHEDULED_EVENTS_GEN,
  [MENU_ITEMS.CONTENT_CREATOR_SCHEDULED_EVENTS]:
    CONTENT_CREATOR_SCHEDULED_EVENTS_GEN,

  [MENU_ITEMS.MANAGE_ORGANIZATION_STORES]: MANAGE_ORGANIZATIONS_STORES,
  [MENU_ITEMS.ORGANIZATION_DASHBOARD_GEN]: ORGANIZATION_DASHBOARD_GEN,
  [MENU_ITEMS.ORGANIZATION_DASHBOARD]: ORGANIZATION_DASHBOARD_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_AMA_REQUESTS]:
    MANAGE_ORGANIZATION_AMA_REQUESTS_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_SCHEDULED_STREAMS]:
    MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN,
  [MENU_ITEMS.SETUP_ORGANIZATION_AMA]: SETUP_ORGANIZATION_AMA_GEN,
  [MENU_ITEMS.SETUP_ORGANIZATION_STREAM]: SETUP_ORGANIZATION_STREAM_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_MERCH_GEN]: MANAGE_ORGANIZATION_MERCH_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_MERCH]: MANAGE_ORGANIZATION_MERCH_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_DESIGNS_GEN]: MANAGE_ORGANIZATION_DESIGNS_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_DESIGNS]: MANAGE_ORGANIZATION_DESIGNS_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_BIO_GEN]: MANAGE_ORGANIZATION_BIO_GEN,
  [MENU_ITEMS.MANAGE_ORGANIZATION_BIO]: MANAGE_ORGANIZATION_BIO_GEN,
  [MENU_ITEMS.MEDIA_FEED_ORGANIZATION_GEN]: MEDIA_FEED_ORGANIZATION_GEN,
  [MENU_ITEMS.MEDIA_FEED_ORGANIZATION]: MEDIA_FEED_ORGANIZATION_GEN,
  [MENU_ITEMS.ORGANIZATION_STORE_SALES_GEN]: ORGANIZATION_STORE_SALES_GEN,
  [MENU_ITEMS.ORGANIZATION_STORE_SALES]: ORGANIZATION_STORE_SALES_GEN,

  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_STORES]: MANAGE_CONTENT_CREATORS_STORES,
  [MENU_ITEMS.CONTENT_CREATOR_DASHBOARD_GEN]: CONTENT_CREATOR_DASHBOARD_GEN,
  [MENU_ITEMS.CONTENT_CREATOR_DASHBOARD]: CONTENT_CREATOR_DASHBOARD_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_AMA_REQUESTS]:
    MANAGE_CONTENT_CREATOR_AMA_REQUESTS_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS]:
    MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN,
  [MENU_ITEMS.SETUP_CONTENT_CREATOR_AMA]: SETUP_CONTENT_CREATOR_AMA_GEN,
  [MENU_ITEMS.CONTENT_CREATOR_DEALS_SETUP]: CONTENT_CREATOR_DEALS_SETUP_GEN,
  [MENU_ITEMS.SETUP_CONTENT_CREATOR_STREAM]: SETUP_CONTENT_CREATOR_STREAM_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_MERCH_GEN]:
    MANAGE_CONTENT_CREATOR_MERCH_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_MERCH]: MANAGE_CONTENT_CREATOR_MERCH_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_DESIGNS_GEN]:
    MANAGE_CONTENT_CREATOR_DESIGNS_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_DESIGNS]:
    MANAGE_CONTENT_CREATOR_DESIGNS_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_BIO_GEN]: MANAGE_CONTENT_CREATOR_BIO_GEN,
  [MENU_ITEMS.MANAGE_CONTENT_CREATOR_BIO]: MANAGE_CONTENT_CREATOR_BIO_GEN,
  [MENU_ITEMS.MEDIA_FEED_CONTENT_CREATOR_GEN]: MEDIA_FEED_CONTENT_CREATOR_GEN,
  [MENU_ITEMS.MEDIA_FEED_CONTENT_CREATOR]: MEDIA_FEED_CONTENT_CREATOR_GEN,
  [MENU_ITEMS.CONTENT_CREATOR_STORE_SALES_GEN]: CONTENT_CREATOR_STORE_SALES_GEN,
  [MENU_ITEMS.CONTENT_CREATOR_STORE_SALES]: CONTENT_CREATOR_STORE_SALES_GEN,

  [MENU_ITEMS.CUSTOMERS]: CUSTOMERS,
  [MENU_ITEMS.MANAGE_ACCOUNT]: MANAGE_ACCOUNT,
  [MENU_ITEMS.CUSTOMER_PURCHASES_GEN]: CUSTOMER_PURCHASES_GEN,
  [MENU_ITEMS.CUSTOMER_PURCHASES]: CUSTOMER_PURCHASES_GEN,
  [MENU_ITEMS.CUSTOMER_SETTINGS_GEN]: CUSTOMER_SETTINGS_GEN,
  [MENU_ITEMS.CUSTOMER_SETTINGS]: CUSTOMER_SETTINGS_GEN,
  [MENU_ITEMS.CUSTOMER_ADDRESSES_GEN]: CUSTOMER_ADDRESSES_GEN,
  [MENU_ITEMS.CUSTOMER_ADDRESSES]: CUSTOMER_ADDRESSES_GEN,
  [MENU_ITEMS.CUSTOMER_PAYMENT_GEN]: CUSTOMER_PAYMENT_GEN,
  [MENU_ITEMS.CUSTOMER_PAYMENT]: CUSTOMER_PAYMENT_GEN,

  [MENU_ITEMS.ALL_PRODUCT_MEMORABILIA]: ALL_PRODUCT_MEMORABILIA,

  [MENU_ITEMS.ALL_AMA_REQUESTS]: ALL_AMA_REQUESTS,

  [MENU_ITEMS.STREAMS_CALENDAR]: STREAMS_CALENDAR,
  [MENU_ITEMS.ALL_STREAMS_TABLE]: ALL_STREAMS_TABLE,
  [MENU_ITEMS.MANAGE_WATCH_STREAM_MODERATORS_GEN]:
    MANAGE_WATCH_STREAM_MODERATORS_GEN,
  [MENU_ITEMS.WATCH_STREAM]: WATCH_STREAM_GEN,

  [MENU_ITEMS.PROMO_CODES]: PROMO_CODES,

  [MENU_ITEMS.REDIRECTS]: REDIRECTS,

  [MENU_ITEMS.GENERAL_SALES]: GENERAL_SALES,
  [MENU_ITEMS.EXPERIENCE_SALES]: EXPERIENCE_SALES,
  [MENU_ITEMS.MANAGE_MESSAGES]: MANAGE_MESSAGES,
  [MENU_ITEMS.CAMPAIGNS]: CAMPAIGNS,
  [MENU_ITEMS.CAMPAIGN_DETAILS]: CAMPAIGN_DETAILS,

  [MENU_ITEMS.ADMINS]: ADMINS,

  [MENU_ITEMS.MERCH_SALES_REPORT]: MERCH_SALES_REPORT,
  [MENU_ITEMS.AMA_SALES_REPORT]: AMA_SALES_REPORT,
  [MENU_ITEMS.STREAM_SALES_REPORT]: STREAM_SALES_REPORT,
  [MENU_ITEMS.MANUAL_MERCH_MERGE]: MANUAL_MERCH_MERGE,

  [MENU_ITEMS.INTERVIEWS]: INTERVIEWS,
  [MENU_ITEMS.INTERVIEWERS]: INTERVIEWERS,
  [MENU_ITEMS.INTERVIEW_SETTINGS]: INTERVIEW_SETTINGS,
  [MENU_ITEMS.MY_AVAILABILITY]: MY_AVAILABILITY,

  [MENU_ITEMS.ARTICLES]: ARTICLES,
};

const LINKS_MENU: Record<string, string> = {
  [HOME_PAGE_MANAGEMENT]: MENU_ITEMS.HOME_PAGE_MANAGEMENT,
  [DASHBOARD]: MENU_ITEMS.DASHBOARD,
  [TAGS]: MENU_ITEMS.TAGS,

  // [MANAGE_MERCH_GEN]: MENU_ITEMS.MANAGE_MERCH_GEN,
  // [MANAGE_MERCH]: MENU_ITEMS.MANAGE_MERCH,
  [MANAGE_MERCH_TSHIRT]: MENU_ITEMS.MANAGE_MERCH_TSHIRT,
  [MANAGE_MERCH_HOODIE]: MENU_ITEMS.MANAGE_MERCH_HOODIE,
  [MANAGE_MERCH_HAT]: MENU_ITEMS.MANAGE_MERCH_HAT,

  [MANAGE_ATHLETES_STORES_WITH_PAGINATION]:
    MENU_ITEMS.MANAGE_ATHLETES_STORES_WITH_PAGINATION,
  [ATHLETE_DASHBOARD_GEN]: MENU_ITEMS.ATHLETE_DASHBOARD_GEN,
  [ATHLETE_DASHBOARD]: MENU_ITEMS.ATHLETE_DASHBOARD,
  [MANAGE_ATHLETE_AMA_REQUESTS]: MENU_ITEMS.MANAGE_ATHLETE_AMA_REQUESTS,
  [MANAGE_ATHLETE_SCHEDULED_STREAMS]:
    MENU_ITEMS.MANAGE_ATHLETE_SCHEDULED_STREAMS,
  [SETUP_ATHLETE_STREAM]: MENU_ITEMS.SETUP_ATHLETE_STREAM,
  [SETUP_ATHLETE_AMA]: MENU_ITEMS.SETUP_ATHLETE_AMA,
  [ATHLETE_DEALS_SETUP]: MENU_ITEMS.ATHLETE_DEALS_SETUP,
  [MANAGE_ATHLETE_MERCH_GEN]: MENU_ITEMS.MANAGE_ATHLETE_MERCH_GEN,
  [MANAGE_ATHLETE_MERCH]: MENU_ITEMS.MANAGE_ATHLETE_MERCH,
  [MANAGE_ATHLETE_PRODUCTS_GEN]: MENU_ITEMS.MANAGE_ATHLETE_PRODUCTS_GEN,
  [MANAGE_ATHLETE_PRODUCTS]: MENU_ITEMS.MANAGE_ATHLETE_PRODUCTS,
  [MANAGE_ATHLETE_EXPERIENCE_GEN]: MENU_ITEMS.MANAGE_ATHLETE_EXPERIENCE_GEN,
  [MANAGE_ATHLETE_EXPERIENCE]: MENU_ITEMS.MANAGE_ATHLETE_EXPERIENCE,

  [MANAGE_ORGANIZATION_PRODUCTS_GEN]:
    MENU_ITEMS.MANAGE_ORGANIZATION_PRODUCTS_GEN,
  [MANAGE_ORGANIZATION_PRODUCTS]: MENU_ITEMS.MANAGE_ORGANIZATION_PRODUCTS,
  [MANAGE_ORGANIZATION_EXPERIENCE_GEN]:
    MENU_ITEMS.MANAGE_ORGANIZATION_EXPERIENCE_GEN,
  [MANAGE_ORGANIZATION_EXPERIENCE]: MENU_ITEMS.MANAGE_ORGANIZATION_EXPERIENCE,

  [MANAGE_CONTENT_CREATOR_PRODUCTS_GEN]:
    MENU_ITEMS.MANAGE_CONTENT_CREATOR_PRODUCTS_GEN,
  [MANAGE_CONTENT_CREATOR_PRODUCTS]: MENU_ITEMS.MANAGE_CONTENT_CREATOR_PRODUCTS,
  [MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN]:
    MENU_ITEMS.MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN,
  [MANAGE_CONTENT_CREATOR_EXPERIENCE]:
    MENU_ITEMS.MANAGE_CONTENT_CREATOR_EXPERIENCE,

  [MANAGE_ATHLETE_DESIGNS_GEN]: MENU_ITEMS.MANAGE_ATHLETE_DESIGNS_GEN,
  [MANAGE_ATHLETE_DESIGNS]: MENU_ITEMS.MANAGE_ATHLETE_DESIGNS,
  [MANAGE_ATHLETE_BIO_GEN]: MENU_ITEMS.MANAGE_ATHLETE_BIO_GEN,
  [MANAGE_ATHLETE_BIO]: MENU_ITEMS.MANAGE_ATHLETE_BIO,
  [MEDIA_FEED_ATHLETE_GEN]: MENU_ITEMS.MEDIA_FEED_ATHLETE_GEN,
  [MEDIA_FEED_ATHLETE]: MENU_ITEMS.MEDIA_FEED_ATHLETE,
  [ATHLETE_STORE_SALES_GEN]: MENU_ITEMS.ATHLETE_STORE_SALES_GEN,
  [ATHLETE_STORE_SALES]: MENU_ITEMS.ATHLETE_STORE_SALES,

  [ATHLETE_SCHEDULED_EVENTS_GEN]: MENU_ITEMS.ATHLETE_SCHEDULED_EVENTS_GEN,
  [ATHLETE_SCHEDULED_EVENTS]: MENU_ITEMS.ATHLETE_SCHEDULED_EVENTS,

  [ORGANIZATION_SCHEDULED_EVENTS_GEN]:
    MENU_ITEMS.ORGANIZATION_SCHEDULED_EVENTS_GEN,
  [ORGANIZATION_SCHEDULED_EVENTS]: MENU_ITEMS.ORGANIZATION_SCHEDULED_EVENTS,

  [CONTENT_CREATOR_SCHEDULED_EVENTS_GEN]:
    MENU_ITEMS.CONTENT_CREATOR_SCHEDULED_EVENTS_GEN,
  [CONTENT_CREATOR_SCHEDULED_EVENTS]:
    MENU_ITEMS.CONTENT_CREATOR_SCHEDULED_EVENTS,

  [MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION]:
    MENU_ITEMS.MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION,
  [ORGANIZATION_DASHBOARD_GEN]: MENU_ITEMS.ORGANIZATION_DASHBOARD_GEN,
  [ORGANIZATION_DASHBOARD]: MENU_ITEMS.ORGANIZATION_DASHBOARD,
  [MANAGE_ORGANIZATION_AMA_REQUESTS]:
    MENU_ITEMS.MANAGE_ORGANIZATION_AMA_REQUESTS,
  [MANAGE_ORGANIZATION_SCHEDULED_STREAMS]:
    MENU_ITEMS.MANAGE_ORGANIZATION_SCHEDULED_STREAMS,
  [SETUP_ORGANIZATION_STREAM]: MENU_ITEMS.SETUP_ORGANIZATION_STREAM,
  [SETUP_ORGANIZATION_AMA]: MENU_ITEMS.SETUP_ORGANIZATION_AMA,
  [MANAGE_ORGANIZATION_MERCH_GEN]: MENU_ITEMS.MANAGE_ORGANIZATION_MERCH_GEN,
  [MANAGE_ORGANIZATION_MERCH]: MENU_ITEMS.MANAGE_ORGANIZATION_MERCH,
  [MANAGE_ORGANIZATION_DESIGNS_GEN]: MENU_ITEMS.MANAGE_ORGANIZATION_DESIGNS_GEN,
  [MANAGE_ORGANIZATION_DESIGNS]: MENU_ITEMS.MANAGE_ORGANIZATION_DESIGNS,
  [MANAGE_ORGANIZATION_BIO_GEN]: MENU_ITEMS.MANAGE_ORGANIZATION_BIO_GEN,
  [MANAGE_ORGANIZATION_BIO]: MENU_ITEMS.MANAGE_ORGANIZATION_BIO,
  [MEDIA_FEED_ORGANIZATION_GEN]: MENU_ITEMS.MEDIA_FEED_ORGANIZATION_GEN,
  [MEDIA_FEED_ORGANIZATION]: MENU_ITEMS.MEDIA_FEED_ORGANIZATION,
  [ORGANIZATION_STORE_SALES_GEN]: MENU_ITEMS.ORGANIZATION_STORE_SALES_GEN,
  [ORGANIZATION_STORE_SALES]: MENU_ITEMS.ORGANIZATION_STORE_SALES,

  [MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION]:
    MENU_ITEMS.MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION,
  [CONTENT_CREATOR_DASHBOARD_GEN]: MENU_ITEMS.CONTENT_CREATOR_DASHBOARD_GEN,
  [CONTENT_CREATOR_DASHBOARD]: MENU_ITEMS.CONTENT_CREATOR_DASHBOARD,
  [MANAGE_CONTENT_CREATOR_AMA_REQUESTS]:
    MENU_ITEMS.MANAGE_ORGANIZATION_AMA_REQUESTS,
  [MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS]:
    MENU_ITEMS.MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS,
  [SETUP_CONTENT_CREATOR_STREAM]: MENU_ITEMS.SETUP_CONTENT_CREATOR_STREAM,
  [SETUP_CONTENT_CREATOR_AMA]: MENU_ITEMS.SETUP_CONTENT_CREATOR_AMA,
  [CONTENT_CREATOR_DEALS_SETUP]: MENU_ITEMS.CONTENT_CREATOR_DEALS_SETUP,
  [MANAGE_CONTENT_CREATOR_MERCH_GEN]:
    MENU_ITEMS.MANAGE_CONTENT_CREATOR_MERCH_GEN,
  [MANAGE_CONTENT_CREATOR_MERCH]: MENU_ITEMS.MANAGE_CONTENT_CREATOR_MERCH,
  [MANAGE_CONTENT_CREATOR_DESIGNS_GEN]:
    MENU_ITEMS.MANAGE_CONTENT_CREATOR_DESIGNS_GEN,
  [MANAGE_CONTENT_CREATOR_DESIGNS]: MENU_ITEMS.MANAGE_CONTENT_CREATOR_DESIGNS,
  [MANAGE_CONTENT_CREATOR_BIO_GEN]: MENU_ITEMS.MANAGE_CONTENT_CREATOR_BIO_GEN,
  [MANAGE_CONTENT_CREATOR_BIO]: MENU_ITEMS.MANAGE_CONTENT_CREATOR_BIO,
  [MEDIA_FEED_CONTENT_CREATOR_GEN]: MENU_ITEMS.MEDIA_FEED_CONTENT_CREATOR_GEN,
  [MEDIA_FEED_CONTENT_CREATOR]: MENU_ITEMS.MEDIA_FEED_CONTENT_CREATOR,
  [CONTENT_CREATOR_STORE_SALES_GEN]: MENU_ITEMS.CONTENT_CREATOR_STORE_SALES_GEN,
  [CONTENT_CREATOR_STORE_SALES]: MENU_ITEMS.CONTENT_CREATOR_STORE_SALES,

  [CUSTOMERS_WITH_PAGINATION]: MENU_ITEMS.CUSTOMERS_WITH_PAGINATION,
  [MANAGE_ACCOUNT]: MENU_ITEMS.MANAGE_ACCOUNT,
  [CUSTOMER_PURCHASES_GEN]: MENU_ITEMS.CUSTOMER_PURCHASES_GEN,
  [CUSTOMER_PURCHASES]: MENU_ITEMS.CUSTOMER_PURCHASES,
  [CUSTOMER_SETTINGS_GEN]: MENU_ITEMS.CUSTOMER_SETTINGS_GEN,
  [CUSTOMER_SETTINGS]: MENU_ITEMS.CUSTOMER_SETTINGS,
  [CUSTOMER_ADDRESSES_GEN]: MENU_ITEMS.CUSTOMER_ADDRESSES_GEN,
  [CUSTOMER_ADDRESSES]: MENU_ITEMS.CUSTOMER_ADDRESSES,
  [CUSTOMER_PAYMENT_GEN]: MENU_ITEMS.CUSTOMER_PAYMENT_GEN,
  [CUSTOMER_PAYMENT]: MENU_ITEMS.CUSTOMER_PAYMENT,

  [ALL_PRODUCT_MEMORABILIA]: MENU_ITEMS.ALL_PRODUCT_MEMORABILIA,

  [ALL_AMA_REQUESTS]: MENU_ITEMS.ALL_AMA_REQUESTS,

  [STREAMS_CALENDAR]: MENU_ITEMS.STREAMS_CALENDAR,
  [ALL_STREAMS_TABLE]: MENU_ITEMS.ALL_STREAMS_TABLE,
  [MANAGE_WATCH_STREAM_MODERATORS_GEN]:
    MENU_ITEMS.MANAGE_WATCH_STREAM_MODERATORS_GEN,
  [WATCH_STREAM]: MENU_ITEMS.WATCH_STREAM,

  [PROMO_CODES]: MENU_ITEMS.PROMO_CODES,
  [BULK_UPLOAD]: MENU_ITEMS.BULK_UPLOAD,
  [DESIGN_REQUESTS]: MENU_ITEMS.DESIGN_REQUESTS,
  [DESIGNERS]: MENU_ITEMS.DESIGNERS,
  [MY_DESIGN_REQUESTS]: MENU_ITEMS.MY_DESIGN_REQUESTS,

  [EDITING_REQUESTS]: MENU_ITEMS.EDITING_REQUESTS,
  [VIDEO_EDITORS]: MENU_ITEMS.VIDEO_EDITORS,
  [VIDEO_EDIT_REQUEST_SETTINGS]: MENU_ITEMS.VIDEO_EDIT_REQUEST_SETTINGS,

  [LIST_OF_ASSIGNED_STREAMS]: MENU_ITEMS.LIST_OF_ASSIGNED_STREAMS,
  [GENERAL_SALES]: MENU_ITEMS.GENERAL_SALES,
  [EXPERIENCE_SALES]: MENU_ITEMS.EXPERIENCE_SALES,
  [MANUAL_MERCH_MERGE]: MENU_ITEMS.MANUAL_MERCH_MERGE,
  [MANAGE_MESSAGES]: MENU_ITEMS.MANAGE_MESSAGES,
  [MANAGE_MESSAGES_WITH_PAGINATION]: MENU_ITEMS.MANAGE_MESSAGES_WITH_PAGINATION,
  [CAMPAIGNS]: MENU_ITEMS.CAMPAIGNS,
  [ADMINS]: MENU_ITEMS.ADMINS,

  [REDIRECTS]: MENU_ITEMS.REDIRECTS,

  [MERCH_SALES_REPORT]: MENU_ITEMS.MERCH_SALES_REPORT,
  [AMA_SALES_REPORT]: MENU_ITEMS.AMA_SALES_REPORT,
  [STREAM_SALES_REPORT]: MENU_ITEMS.STREAM_SALES_REPORT,

  [INTERVIEWS]: MENU_ITEMS.INTERVIEWS,
  [INTERVIEWERS]: MENU_ITEMS.INTERVIEWERS,
  [MY_AVAILABILITY]: MENU_ITEMS.MY_AVAILABILITY,
  [MY_INTERVIEWS]: MENU_ITEMS.MY_INTERVIEWS,
  [MY_SCHEDULED_EVENTS]: MENU_ITEMS.MY_SCHEDULED_EVENTS,
  [INTERVIEW_SETTINGS]: MENU_ITEMS.INTERVIEW_SETTINGS,

  [ARTICLES]: MENU_ITEMS.ARTICLES,
  [ARTICLES_WITH_PAGINATION]: MENU_ITEMS.ARTICLES_WITH_PAGINATION,
  [CAMPAIGN_DETAILS]: MENU_ITEMS.CAMPAIGN_DETAILS,
};

// const CAMPAIGN_DETAILS_MENU: Record<string, string> = {};

const PAGE_TITLES: Record<string, string> = {
  [HOME_PAGE_MANAGEMENT]: 'Home Page Management',
  [DASHBOARD]: 'Dashboard',
  [TAGS]: 'Manage Tags',

  // [MANAGE_MERCH_GEN]: 'Manage Merch',
  [MANAGE_MERCH_TSHIRT_GEN]: 'Manage T-Shirt',
  [MANAGE_MERCH_HOODIE_GEN]: 'Manage Hoodie',
  [MANAGE_MERCH_HAT_GEN]: 'Manage Hat',
  [MANAGE_MERCH_JOGGERS_GEN]: 'Manage Joggers',
  [MANAGE_MERCH_RASH_GUARD_GEN]: 'Manage Rash-Guard',

  [MANAGE_ATHLETES_STORES]: 'Manage Athletes',
  [MANAGE_ATHLETES_STORES_WITH_PAGINATION]: 'Manage Athletes',
  [ATHLETE_DASHBOARD_GEN]: 'Dashboard',
  [MANAGE_ATHLETE_AMA_REQUESTS_GEN]: 'Manage AMA requests',
  [MANAGE_ATHLETE_SCHEDULED_STREAMS_GEN]: 'Manage Scheduled Streams',
  [SETUP_ATHLETE_AMA_GEN]: 'Setup AMA',
  [ATHLETE_DEALS_SETUP_GEN]: 'Brand Deals Setup',
  [SETUP_ATHLETE_STREAM_GEN]: 'Setup Stream',
  [MANAGE_ATHLETE_MERCH_GEN]: 'Manage Merch',
  [MANAGE_ATHLETE_PRODUCTS_GEN]: 'Manage Products',
  [MANAGE_ATHLETE_EXPERIENCE_GEN]: 'Manage Experience',

  [MANAGE_ORGANIZATION_PRODUCTS_GEN]: 'Manage Products',
  [MANAGE_ORGANIZATION_EXPERIENCE_GEN]: 'Manage Experience',
  [MANAGE_CONTENT_CREATOR_PRODUCTS_GEN]: 'Manage Products',
  [MANAGE_CONTENT_CREATOR_EXPERIENCE_GEN]: 'Manage Experience',
  [MANAGE_ATHLETE_DESIGNS_GEN]: 'Manage Designs',
  [MANAGE_ATHLETE_BIO_GEN]: 'Manage bio',
  [MEDIA_FEED_ATHLETE_GEN]: 'Media Feed',
  [MEDIA_FEED_ATHLETE]: 'Media Feed',
  [ATHLETE_STORE_SALES_GEN]: 'Sales',
  [ATHLETE_SCHEDULED_EVENTS_GEN]: 'Scheduled Events',
  [ORGANIZATION_SCHEDULED_EVENTS_GEN]: 'Scheduled Events',
  [CONTENT_CREATOR_SCHEDULED_EVENTS_GEN]: 'Scheduled Events',

  [MEDIA_FEED_ORGANIZATION_GEN]: 'Media Feed',
  [MEDIA_FEED_ORGANIZATION]: 'Media Feed',
  [MEDIA_FEED_CONTENT_CREATOR_GEN]: 'Media Feed',
  [MEDIA_FEED_CONTENT_CREATOR]: 'Media Feed',

  [MANAGE_ORGANIZATIONS_STORES]: 'Manage Organizations',
  [MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION]: 'Manage Organizations',
  [ORGANIZATION_DASHBOARD_GEN]: 'Dashboard',
  [MANAGE_ORGANIZATION_AMA_REQUESTS_GEN]: 'Manage AMA requests',
  [MANAGE_ORGANIZATION_SCHEDULED_STREAMS_GEN]: 'Manage Scheduled Streams',
  [SETUP_ORGANIZATION_AMA_GEN]: 'Setup AMA',
  [SETUP_ORGANIZATION_STREAM_GEN]: 'Setup Stream',
  [MANAGE_ORGANIZATION_MERCH_GEN]: 'Manage Merch',
  [MANAGE_ORGANIZATION_DESIGNS_GEN]: 'Manage Designs',
  [MANAGE_ORGANIZATION_BIO_GEN]: 'Manage bio',
  [ORGANIZATION_STORE_SALES_GEN]: 'Sales',

  [MANAGE_CONTENT_CREATORS_STORES]: 'Manage Content Creators',
  [MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION]: 'Manage Content Creators',
  [CONTENT_CREATOR_DASHBOARD_GEN]: 'Dashboard',
  [MANAGE_CONTENT_CREATOR_AMA_REQUESTS_GEN]: 'Manage AMA requests',
  [MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS_GEN]: 'Manage Scheduled Streams',
  [SETUP_CONTENT_CREATOR_AMA_GEN]: 'Setup AMA',
  [CONTENT_CREATOR_DEALS_SETUP_GEN]: 'Brand Deals Setup',
  [SETUP_CONTENT_CREATOR_STREAM_GEN]: 'Setup Stream',
  [MANAGE_CONTENT_CREATOR_MERCH_GEN]: 'Manage Merch',
  [MANAGE_CONTENT_CREATOR_DESIGNS_GEN]: 'Manage Designs',
  [MANAGE_CONTENT_CREATOR_BIO_GEN]: 'Manage bio',
  [CONTENT_CREATOR_STORE_SALES_GEN]: 'Sales',

  [CUSTOMERS_WITH_PAGINATION]: 'Customers',
  [CUSTOMERS]: 'Customers',

  [CUSTOMER_PURCHASES_GEN]: 'Purchases',
  [CUSTOMER_SETTINGS_GEN]: 'Settings',
  [CUSTOMER_ADDRESSES_GEN]: 'Addresses',
  [CUSTOMER_PAYMENT_GEN]: 'Payment',

  [ALL_PRODUCT_MEMORABILIA]: 'Product and Memorabilia',

  [ALL_AMA_REQUESTS]: 'AMA Requests',

  [STREAMS_CALENDAR]: 'Streams Calendar',
  [ALL_STREAMS_TABLE]: 'All Streams',
  [WATCH_STREAM_GEN]: 'Watch Stream',
  [MANAGE_WATCH_STREAM_MODERATORS_GEN]: 'Moderators',

  [PROMO_CODES]: 'Promo Codes',

  [BULK_UPLOAD]: 'Bulk Media Uploads',
  [DESIGN_REQUESTS]: 'Design Requests',
  [DESIGNERS]: 'Designers',
  [MY_DESIGN_REQUESTS]: 'My Design Requests',
  [DESIGN_REQUEST_RESULT_DETAILS_GEN]: 'Design Result Details',
  [DESIGN_REQUEST_DETAILS_GEN]: 'Design request Details',
  [DESIGN_REQUEST_RESULT_DETAILS]: 'Design Request Results',
  [DESIGN_REQUEST_DETAILS]: 'Design Request Details',
  [MERCH_SALES_REPORT]: 'Merch sales report',
  [AMA_SALES_REPORT]: 'AMA sales report',
  [STREAM_SALES_REPORT]: 'Stream sales report',

  [EDITING_REQUESTS]: 'Editing Request',
  [MY_EDITING_REQUESTS]: 'My Editing Request',
  [VIDEO_EDITORS]: 'Video Editors',
  [VIDEO_EDITOR_PROFILE]: 'My Profile',
  [VIDEO_EDIT_REQUEST_SETTINGS]: 'Video Edit Request Settings',

  [MY_INTERVIEWS]: 'My Interviews',
  [MY_SCHEDULED_EVENTS]: 'My Scheduled Events',
  [MY_AVAILABILITY]: 'Availability',
  [INTERVIEWS]: 'Interviews',
  [INTERVIEWERS]: 'Interviewers',
  [INTERVIEW_SETTINGS]: 'Interview Settings',

  [LIST_OF_ASSIGNED_STREAMS]: 'List of assigned streams',
  [GENERAL_SALES]: 'General sales',
  [EXPERIENCE_SALES]: 'Experience sales',
  [MANUAL_MERCH_MERGE]: 'Manual merch merge',
  [MANAGE_MESSAGES]: 'Messaging',
  [MANAGE_MESSAGES_WITH_PAGINATION]: 'Messages',
  [CAMPAIGNS]: 'Campaigns',
  [CAMPAIGN_DETAILS_GEN]: 'Campaign Details',
  [ADMINS]: 'Admins',
  [ACCOUNT]: 'Account',

  [REDIRECTS]: 'Redirects',
  [ARTICLES]: 'Articles',
  [ARTICLES_WITH_PAGINATION]: 'Articles',
};

type LayoutProps = {
  children?: React.ReactNode;
};

export const LayoutWrapper: React.FC = ({ children }: LayoutProps) => {
  const [menu, updateMenu] = useState<State>({
    collapsed: false,
  });
  const { authUser, clearAuthUser } = useAppContext();
  const match = useRouteMatch();
  const { storeId, customerId, streamId, manageMerchRole, campaignId } =
    useParams<{
      storeId?: string;
      customerId?: string;
      streamId?: string;
      manageMerchRole?: string;
      campaignId?: string;
    }>();
  const location = useLocation<LocationStateType>();
  const history = useHistory();
  const currentItem = LINKS_MENU[match.path];
  const pathName = `/${location.pathname.split('/')[1]}`;
  const isAdmin = authUser?.role === UserRole.Admin;
  const isDesigner = authUser?.role === UserRole.Designer;
  const isVideoEditor = authUser?.role === UserRole.VideoEditor;
  const isModerator = authUser?.role === UserRole.Moderator;
  const isInterviewer = authUser?.isInterviewer;

  const [signOut, { loading: signOutLoading }] = useMutation<
    SignOut,
    SignOutVariables
  >(SIGN_OUT);

  const onCollapse = (collapsed: boolean): void => {
    updateMenu(() => ({ collapsed }));
  };

  const handleMenuItemSelect = ({ key = '' }: { key: string }): void => {
    if (currentItem !== key) {
      let redirectLink = MENU_ITEMS_LINKS[key];

      if (
        MERCH_MENU_ITEMS.includes(key) ||
        ATHLETE_MENU_ITEMS.includes(key) ||
        ORGANIZATION_MENU_ITEMS.includes(key) ||
        CONTENT_CREATOR_MENU_ITEMS.includes(key)
      ) {
        redirectLink = `${MENU_ITEMS_LINKS[key]}/${storeId}`;
      }

      // TODO: uncomment it when "Watch Stream" menu will have at least one menu item
      // if (WATCH_STREAM_MENU_ITEMS.includes(key)) {
      //   redirectLink = `${MENU_ITEMS_LINKS[key]}/${streamId}`;
      // }

      if (CUSTOMER_MENU_ITEMS.includes(key)) {
        redirectLink = `${MENU_ITEMS_LINKS[key]}/${customerId}`;
      }

      if (key === MENU_ITEMS.CUSTOMERS_WITH_PAGINATION) {
        redirectLink = CUSTOMERS_DEFAULT_ROUTE;
      }

      if (key === MENU_ITEMS.ARTICLES_WITH_PAGINATION) {
        redirectLink = ARTICLES_DEFAULT_ROUTE;
      }

      if (key === MENU_ITEMS.MANAGE_ATHLETES_STORES_WITH_PAGINATION) {
        redirectLink = MANAGE_ATHLETES_STORES_DEFAULT_ROUTE;
      }

      if (key === MENU_ITEMS.MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION) {
        redirectLink = MANAGE_ORGANIZATIONS_STORES_DEFAULT_ROUTE;
      }

      if (key === MENU_ITEMS.MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION) {
        redirectLink = MANAGE_CONTENT_CREATORS_STORES_DEFAULT_ROUTE;
      }

      if (key === MENU_ITEMS.MANAGE_MESSAGES_WITH_PAGINATION) {
        redirectLink = MANAGE_MESSAGES_DEFAULT_ROUTE;
      }

      if (key === MENU_ITEMS.CAMPAIGNS) {
        redirectLink = CAMPAIGNS;
      }

      if (key === MENU_ITEMS.ARTICLES) {
        redirectLink = ARTICLES;
      }

      if (key === MENU_ITEMS.DESIGN_REQUESTS) {
        redirectLink = DESIGN_REQUESTS;
      }

      if (key === MENU_ITEMS.BULK_UPLOAD) {
        redirectLink = BULK_UPLOAD;
      }

      if (key === MENU_ITEMS.DESIGNERS) {
        redirectLink = DESIGNERS;
      }

      if (key === MENU_ITEMS.EDITING_REQUESTS) {
        redirectLink = EDITING_REQUESTS;
      }

      if (key === MENU_ITEMS.VIDEO_EDITORS) {
        redirectLink = VIDEO_EDITORS;
      }

      if (key === MENU_ITEMS.VIDEO_EDIT_REQUEST_SETTINGS) {
        redirectLink = VIDEO_EDIT_REQUEST_SETTINGS;
      }

      if (key === MENU_ITEMS.VIDEO_EDITOR_PROFILE) {
        redirectLink = VIDEO_EDITOR_PROFILE;
      }

      if (key === MENU_ITEMS.MY_EDITING_REQUESTS) {
        redirectLink = MY_EDITING_REQUESTS;
      }

      if (key === MENU_ITEMS.MANAGE_WATCH_STREAM_MODERATORS_GEN) {
        redirectLink = MANAGE_WATCH_STREAM_MODERATORS_GEN;
      }

      if (key === MENU_ITEMS.MY_DESIGN_REQUESTS) {
        redirectLink = MY_DESIGN_REQUESTS;
      }

      if (key === MENU_ITEMS.MY_INTERVIEWS) {
        redirectLink = MY_INTERVIEWS;
      }

      if (key === MENU_ITEMS.MY_SCHEDULED_EVENTS) {
        redirectLink = MY_SCHEDULED_EVENTS;
      }

      if (key === MENU_ITEMS.INTERVIEWS) {
        redirectLink = INTERVIEWS;
      }

      if (key === MENU_ITEMS.INTERVIEWERS) {
        redirectLink = INTERVIEWERS;
      }

      if (key === MENU_ITEMS.INTERVIEW_SETTINGS) {
        redirectLink = INTERVIEW_SETTINGS;
      }

      if (key === MENU_ITEMS.MY_AVAILABILITY) {
        redirectLink = MY_AVAILABILITY;
      }

      if (key === MENU_ITEMS.LIST_OF_ASSIGNED_STREAMS) {
        redirectLink = LIST_OF_ASSIGNED_STREAMS;
      }

      if (key === MENU_ITEMS.GENERAL_SALES) {
        redirectLink = GENERAL_SALES;
      }

      if (key === MENU_ITEMS.EXPERIENCE_SALES) {
        redirectLink = EXPERIENCE_SALES;
      }

      if (key === MENU_ITEMS.ADMINS) {
        redirectLink = ADMINS;
      }

      history.push(redirectLink);
    }
  };

  const handleLogout = async (): Promise<void> => {
    if (authUser?.refreshToken) {
      await signOut({
        variables: {
          input: {
            refreshToken: authUser.refreshToken,
          },
        },
      });
    }

    clearAuthUser();
    history.push(LOGIN);
  };

  const handleGoToAccountPage = (): void => {
    history.push(ACCOUNT);
  };

  const handleGoBack = (): void => {
    // "manageMerchRole" param as an indicator of the create/edit merch page
    if (manageMerchRole) {
      history.push(`/${manageMerchRole}/${storeId}`);
    } else if (storeId) {
      history.push(
        pathName.includes(STORE_TYPE.ORGANIZATION)
          ? MANAGE_ORGANIZATIONS_STORES_DEFAULT_ROUTE
          : pathName.includes('content-creator')
          ? MANAGE_CONTENT_CREATORS_STORES_DEFAULT_ROUTE
          : MANAGE_ATHLETES_STORES_DEFAULT_ROUTE
      );
    }

    if (customerId) {
      history.push(CUSTOMERS_DEFAULT_ROUTE);
    }

    if (streamId) {
      if (location.state?.fromCalendar) {
        history.push(STREAMS_CALENDAR);
      } else {
        history.push(ALL_STREAMS_TABLE);
      }
    }

    if (campaignId) {
      history.push(CAMPAIGNS);
    }
  };

  const renderMenuItem = (
    key: keyof typeof MENU_ITEMS,
    icon: JSX.Element,
    title: string
  ) => (
    <Menu.Item key={MENU_ITEMS[key]} icon={icon}>
      {title}
    </Menu.Item>
  );

  const getBasicMenu = () => {
    const defaultOpenKeys = (): string[] | undefined => {
      switch (currentItem) {
        case MENU_ITEMS.MANAGE_ATHLETES_STORES_WITH_PAGINATION:
          return ['manage-store'];
        case MENU_ITEMS.MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION:
          return ['manage-store'];
        case MENU_ITEMS.MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION:
          return ['manage-store'];
        case MENU_ITEMS.STREAMS_CALENDAR:
          return ['streams'];
        case MENU_ITEMS.ALL_STREAMS_TABLE:
          return ['streams'];
        case MENU_ITEMS.MANAGE_WATCH_STREAM_MODERATORS_GEN:
          return ['streams'];
        case MENU_ITEMS.DESIGN_REQUESTS:
          return ['design-lab'];
        case MENU_ITEMS.DESIGNERS:
          return ['design-lab'];
        case MENU_ITEMS.EDITING_REQUESTS:
          return ['video-lab'];
        case MENU_ITEMS.VIDEO_EDITORS:
          return ['video-lab'];
        case MENU_ITEMS.VIDEO_EDIT_REQUEST_SETTINGS:
          return ['video-lab'];
        case MENU_ITEMS.MERCH_SALES_REPORT:
          return ['reports'];
        case MENU_ITEMS.AMA_SALES_REPORT:
          return ['reports'];
        case MENU_ITEMS.STREAM_SALES_REPORT:
          return ['reports'];
        case MENU_ITEMS.MANAGE_MESSAGES_WITH_PAGINATION:
          return ['manage-messages'];
        case MENU_ITEMS.CAMPAIGNS:
          return ['manage-campaigns'];
        case MENU_ITEMS.INTERVIEW_SETTINGS:
          return ['interviews'];
        case MENU_ITEMS.INTERVIEWS:
          return ['interviews'];
        case MENU_ITEMS.INTERVIEWERS:
          return ['interviews'];
        case MENU_ITEMS.ARTICLES:
          return ['articles'];
        case MENU_ITEMS.ARTICLES_WITH_PAGINATION:
          return ['articles'];

        default:
          return undefined;
      }
    };

    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[currentItem]}
        defaultOpenKeys={defaultOpenKeys()}
        onSelect={handleMenuItemSelect}
        className={styles.menu}
      >
        {renderMenuItem('ADMINS', <UserOutlined />, 'Admins')}

        {renderMenuItem(
          'HOME_PAGE_MANAGEMENT',
          <PicCenterOutlined />,
          'Home Page Management'
        )}

        {renderMenuItem('DASHBOARD', <DashboardOutlined />, 'Dashboard')}

        {renderMenuItem('TAGS', <BorderlessTableOutlined />, 'Manage Tags')}

        <SubMenu
          key="manage-store"
          icon={<ControlOutlined />}
          title="Manage Store"
        >
          {renderMenuItem(
            'MANAGE_ATHLETES_STORES_WITH_PAGINATION',
            <TeamOutlined />,
            'Athletes'
          )}

          {renderMenuItem(
            'MANAGE_ORGANIZATIONS_STORES_WITH_PAGINATION',
            <DribbbleOutlined />,
            'Organizations'
          )}

          {renderMenuItem(
            'MANAGE_CONTENT_CREATORS_STORES_WITH_PAGINATION',
            <DribbbleOutlined />,
            'Content Creators'
          )}
        </SubMenu>

        {renderMenuItem(
          'MANAGE_MESSAGES_WITH_PAGINATION',
          <MessageOutlined />,
          'Messaging'
        )}

        {renderMenuItem('CAMPAIGNS', <TeamOutlined />, 'Campaigns')}

        {renderMenuItem(
          'ARTICLES_WITH_PAGINATION',
          <FileTextOutlined />,
          'Articles'
        )}

        {renderMenuItem(
          'CUSTOMERS_WITH_PAGINATION',
          <TeamOutlined />,
          'Customers'
        )}

        {renderMenuItem(
          'ALL_PRODUCT_MEMORABILIA',
          <SkinOutlined />,
          'Product and Memorabilia'
        )}

        <SubMenu key="interviews" icon={<AudioOutlined />} title="Interviews">
          {renderMenuItem(
            'INTERVIEWS',
            <UnorderedListOutlined />,
            'All interviews'
          )}
          {renderMenuItem('INTERVIEWERS', <TeamOutlined />, 'Interviewers')}

          {renderMenuItem(
            'INTERVIEW_SETTINGS',
            <TeamOutlined />,
            'Interview settings'
          )}
        </SubMenu>

        {renderMenuItem(
          'ALL_AMA_REQUESTS',
          <CarryOutOutlined />,
          'AMA Requests'
        )}

        <SubMenu key="streams" icon={<VideoCameraOutlined />} title="Streams">
          {renderMenuItem('STREAMS_CALENDAR', <CalendarOutlined />, 'Calendar')}
          {renderMenuItem(
            'ALL_STREAMS_TABLE',
            <TableOutlined />,
            'All Streams'
          )}
          {renderMenuItem(
            'MANAGE_WATCH_STREAM_MODERATORS_GEN',
            <TeamOutlined />,
            'Moderators'
          )}
        </SubMenu>

        {renderMenuItem('PROMO_CODES', <BarcodeOutlined />, 'Promo Codes')}

        <SubMenu
          key="design-lab"
          icon={<FormatPainterOutlined />}
          title="Design lab"
        >
          {renderMenuItem(
            'DESIGN_REQUESTS',
            <AuditOutlined />,
            'Design Requests'
          )}
          {renderMenuItem('DESIGNERS', <TeamOutlined />, 'Designers')}
        </SubMenu>

        <SubMenu
          key="video-lab"
          icon={<PlaySquareOutlined />}
          title="Video lab"
        >
          {renderMenuItem(
            'EDITING_REQUESTS',
            <AuditOutlined />,
            'Editing Requests'
          )}
          {renderMenuItem('VIDEO_EDITORS', <TeamOutlined />, 'Video Editors')}
          {renderMenuItem(
            'VIDEO_EDIT_REQUEST_SETTINGS',
            <TeamOutlined />,
            'Video Edit Request Settings'
          )}
        </SubMenu>

        {renderMenuItem(
          'BULK_UPLOAD',
          <CarryOutOutlined />,
          'Bulk media upload'
        )}

        <SubMenu key="reports" icon={<TableOutlined />} title="Reports">
          {renderMenuItem(
            'MERCH_SALES_REPORT',
            <TableOutlined />,
            'Merch Sales'
          )}
          {renderMenuItem('AMA_SALES_REPORT', <TableOutlined />, 'Ama Sales')}
          {renderMenuItem(
            'STREAM_SALES_REPORT',
            <TableOutlined />,
            'Stream Sales'
          )}
        </SubMenu>

        {renderMenuItem('REDIRECTS', <RedoOutlined />, 'Redirects')}

        {renderMenuItem('GENERAL_SALES', <DollarOutlined />, 'General sales')}

        {renderMenuItem(
          'EXPERIENCE_SALES',
          <DollarOutlined />,
          'Experience sales'
        )}

        {renderMenuItem(
          'MANUAL_MERCH_MERGE',
          <SkinOutlined />,
          'Manual merch merge'
        )}
      </Menu>
    );
  };

  const getAthleteMenu = () => {
    const defaultOpenKeys = (): string[] | undefined => {
      switch (currentItem) {
        case MENU_ITEMS.SETUP_ATHLETE_AMA:
          return ['manage-ama'];
        case MENU_ITEMS.MANAGE_ATHLETE_AMA_REQUESTS:
          return ['manage-ama'];
        case MENU_ITEMS.ATHLETE_DEALS_SETUP:
          return ['deals-setup'];
        case MENU_ITEMS.SETUP_ATHLETE_STREAM:
          return ['streams'];
        case MENU_ITEMS.MANAGE_ATHLETE_SCHEDULED_STREAMS:
          return ['streams'];
        default:
          return undefined;
      }
    };

    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[currentItem]}
        defaultOpenKeys={defaultOpenKeys()}
        onSelect={handleMenuItemSelect}
        className={styles.menu}
      >
        {renderMenuItem(
          'ATHLETE_DASHBOARD',
          <DashboardOutlined />,
          'Dashboard'
        )}

        {renderMenuItem(
          'MANAGE_ATHLETE_MERCH',
          <SkinOutlined />,
          'Manage Merch'
        )}

        {renderMenuItem(
          'MANAGE_ATHLETE_PRODUCTS',
          <SkinOutlined />,
          'Manage Products'
        )}

        {renderMenuItem(
          'MANAGE_ATHLETE_EXPERIENCE',
          <SkinOutlined />,
          'Manage Experience'
        )}

        {renderMenuItem(
          'MANAGE_ATHLETE_DESIGNS',
          <FormatPainterOutlined />,
          'Manage Designs'
        )}

        <SubMenu
          key="manage-ama"
          icon={<ReconciliationOutlined />}
          title="Manage AMA"
        >
          {renderMenuItem(
            'SETUP_ATHLETE_AMA',
            <SlidersOutlined />,
            'Setup AMA'
          )}
          {renderMenuItem(
            'MANAGE_ATHLETE_AMA_REQUESTS',
            <CarryOutOutlined />,
            'Requests'
          )}
        </SubMenu>

        <SubMenu
          key="manage-deals"
          icon={<ReconciliationOutlined />}
          title="Manage Deals"
        >
          {renderMenuItem(
            'ATHLETE_DEALS_SETUP',
            <SlidersOutlined />,
            'Set Brand Deals'
          )}
        </SubMenu>

        <SubMenu key="streams" icon={<EyeOutlined />} title="Manage Streams">
          {renderMenuItem(
            'SETUP_ATHLETE_STREAM',
            <SlidersOutlined />,
            'Setup Stream'
          )}
          {renderMenuItem(
            'MANAGE_ATHLETE_SCHEDULED_STREAMS',
            <CarryOutOutlined />,
            'Streams'
          )}
        </SubMenu>

        {renderMenuItem(
          'MANAGE_ATHLETE_BIO',
          <ProfileOutlined />,
          'Manage bio'
        )}

        {renderMenuItem('MEDIA_FEED_ATHLETE', <UploadOutlined />, 'Media Feed')}

        {renderMenuItem('ATHLETE_STORE_SALES', <DollarOutlined />, 'Sales')}

        {renderMenuItem(
          'ATHLETE_SCHEDULED_EVENTS',
          <CalendarOutlined />,
          'Scheduled Events'
        )}
      </Menu>
    );
  };

  const getOrganizationMenu = () => {
    const defaultOpenKeys = (): string[] | undefined => {
      switch (currentItem) {
        case MENU_ITEMS.SETUP_ORGANIZATION_AMA:
          return ['manage-ama'];
        case MENU_ITEMS.MANAGE_ORGANIZATION_AMA_REQUESTS:
          return ['manage-ama'];
        case MENU_ITEMS.SETUP_ORGANIZATION_STREAM:
          return ['streams'];
        case MENU_ITEMS.MANAGE_ORGANIZATION_SCHEDULED_STREAMS:
          return ['streams'];
        default:
          return undefined;
      }
    };

    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[currentItem]}
        defaultOpenKeys={defaultOpenKeys()}
        onSelect={handleMenuItemSelect}
        className={styles.menu}
      >
        {renderMenuItem(
          'ORGANIZATION_DASHBOARD',
          <DashboardOutlined />,
          'Dashboard'
        )}
        {renderMenuItem(
          'MANAGE_ORGANIZATION_MERCH',
          <SkinOutlined />,
          'Manage Merch'
        )}
        {renderMenuItem(
          'MANAGE_ORGANIZATION_PRODUCTS',
          <SkinOutlined />,
          'Manage Products'
        )}
        {renderMenuItem(
          'MANAGE_ORGANIZATION_EXPERIENCE',
          <SkinOutlined />,
          'Manage Experience'
        )}

        {renderMenuItem(
          'MANAGE_ORGANIZATION_DESIGNS',
          <FormatPainterOutlined />,
          'Manage Designs'
        )}

        <SubMenu
          key="manage-ama"
          icon={<ReconciliationOutlined />}
          title="Manage AMA"
        >
          {renderMenuItem(
            'SETUP_ORGANIZATION_AMA',
            <SlidersOutlined />,
            'Setup AMA'
          )}
          {renderMenuItem(
            'MANAGE_ORGANIZATION_AMA_REQUESTS',
            <CarryOutOutlined />,
            'Requests'
          )}
        </SubMenu>

        <SubMenu key="streams" icon={<EyeOutlined />} title="Manage Streams">
          {renderMenuItem(
            'SETUP_ORGANIZATION_STREAM',
            <SlidersOutlined />,
            'Setup Stream'
          )}
          {renderMenuItem(
            'MANAGE_ORGANIZATION_SCHEDULED_STREAMS',
            <CarryOutOutlined />,
            'Streams'
          )}
        </SubMenu>

        {renderMenuItem(
          'MANAGE_ORGANIZATION_BIO',
          <ProfileOutlined />,
          'Manage bio'
        )}

        {renderMenuItem(
          'MEDIA_FEED_ORGANIZATION',
          <UploadOutlined />,
          'Media Feed'
        )}

        {renderMenuItem(
          'ORGANIZATION_STORE_SALES',
          <DollarOutlined />,
          'Sales'
        )}

        {renderMenuItem(
          'ORGANIZATION_SCHEDULED_EVENTS',
          <CalendarOutlined />,
          'Scheduled Events'
        )}
      </Menu>
    );
  };

  const getContentCreatorMenu = () => {
    const defaultOpenKeys = (): string[] | undefined => {
      switch (currentItem) {
        case MENU_ITEMS.SETUP_CONTENT_CREATOR_AMA:
          return ['manage-ama'];
        case MENU_ITEMS.MANAGE_CONTENT_CREATOR_AMA_REQUESTS:
          return ['manage-ama'];
        case MENU_ITEMS.CONTENT_CREATOR_DEALS_SETUP:
          return ['deals-setup'];
        case MENU_ITEMS.SETUP_CONTENT_CREATOR_STREAM:
          return ['streams'];
        case MENU_ITEMS.MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS:
          return ['streams'];
        default:
          return undefined;
      }
    };

    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[currentItem]}
        defaultOpenKeys={defaultOpenKeys()}
        onSelect={handleMenuItemSelect}
        className={styles.menu}
      >
        {renderMenuItem(
          'CONTENT_CREATOR_DASHBOARD',
          <DashboardOutlined />,
          'Dashboard'
        )}
        {renderMenuItem(
          'MANAGE_CONTENT_CREATOR_MERCH',
          <SkinOutlined />,
          'Manage Merch'
        )}
        {renderMenuItem(
          'MANAGE_CONTENT_CREATOR_PRODUCTS',
          <SkinOutlined />,
          'Manage Products'
        )}
        {renderMenuItem(
          'MANAGE_CONTENT_CREATOR_EXPERIENCE',
          <SkinOutlined />,
          'Manage Experience'
        )}

        {renderMenuItem(
          'MANAGE_CONTENT_CREATOR_DESIGNS',
          <FormatPainterOutlined />,
          'Manage Designs'
        )}

        <SubMenu
          key="manage-ama"
          icon={<ReconciliationOutlined />}
          title="Manage AMA"
        >
          {renderMenuItem(
            'SETUP_CONTENT_CREATOR_AMA',
            <SlidersOutlined />,
            'Setup AMA'
          )}
          {renderMenuItem(
            'MANAGE_CONTENT_CREATOR_AMA_REQUESTS',
            <CarryOutOutlined />,
            'Requests'
          )}
        </SubMenu>

        <SubMenu
          key="manage-deals"
          icon={<ReconciliationOutlined />}
          title="Manage Deals"
        >
          {renderMenuItem(
            'CONTENT_CREATOR_DEALS_SETUP',
            <SlidersOutlined />,
            'Set Brand Deals'
          )}
        </SubMenu>

        <SubMenu key="streams" icon={<EyeOutlined />} title="Manage Streams">
          {renderMenuItem(
            'SETUP_CONTENT_CREATOR_STREAM',
            <SlidersOutlined />,
            'Setup Stream'
          )}
          {renderMenuItem(
            'MANAGE_CONTENT_CREATOR_SCHEDULED_STREAMS',
            <CarryOutOutlined />,
            'Streams'
          )}
        </SubMenu>

        {renderMenuItem(
          'MANAGE_CONTENT_CREATOR_BIO',
          <ProfileOutlined />,
          'Manage bio'
        )}

        {renderMenuItem(
          'MEDIA_FEED_CONTENT_CREATOR',
          <UploadOutlined />,
          'Media Feed'
        )}

        {renderMenuItem(
          'CONTENT_CREATOR_STORE_SALES',
          <DollarOutlined />,
          'Sales'
        )}

        {renderMenuItem(
          'CONTENT_CREATOR_SCHEDULED_EVENTS',
          <CalendarOutlined />,
          'Scheduled Events'
        )}
      </Menu>
    );
  };

  const getManagedStoreMenu = () => {
    if (pathName.includes(STORE_TYPE.ATHLETE)) {
      return getAthleteMenu();
    } else if (pathName.includes(STORE_TYPE.ORGANIZATION)) {
      return getOrganizationMenu();
    } else if (pathName.includes('content-creator')) {
      return getContentCreatorMenu();
    }
  };

  const getManagedCustomerMenu = () => {
    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[currentItem]}
        onSelect={handleMenuItemSelect}
        className={styles.menu}
      >
        {renderMenuItem(
          'CUSTOMER_PURCHASES',
          <ShoppingOutlined />,
          'Purchases'
        )}
        {renderMenuItem('CUSTOMER_ADDRESSES', <HomeOutlined />, 'Addresses')}
        {renderMenuItem('CUSTOMER_SETTINGS', <ControlOutlined />, 'Settings')}
        {renderMenuItem(
          'CUSTOMER_PAYMENT',
          <DollarOutlined />,
          'Payment Options'
        )}
      </Menu>
    );
  };

  const getWatchStreamMenu = () => {
    return <></>;
    // TODO: uncomment it when "Watch Stream" menu will have at least one menu item
    // return (
    //   <Menu
    //     theme="dark"
    //     mode="inline"
    //     selectedKeys={[currentItem]}
    //     onSelect={handleMenuItemSelect}
    //     className={styles.menu}
    //   />
    // );
  };

  const getDesignerMenu = () => {
    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[currentItem]}
        onSelect={handleMenuItemSelect}
        className={styles.menu}
      >
        {renderMenuItem(
          'MY_DESIGN_REQUESTS',
          <AuditOutlined />,
          'My Design Requests'
        )}
      </Menu>
    );
  };

  const getInterviewerMenu = () => {
    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[currentItem]}
        onSelect={handleMenuItemSelect}
        className={styles.menu}
      >
        {renderMenuItem('MY_INTERVIEWS', <AuditOutlined />, 'My Interviews')}
        {renderMenuItem(
          'MY_SCHEDULED_EVENTS',
          <AuditOutlined />,
          'My Scheduled Events'
        )}
        {renderMenuItem('MY_AVAILABILITY', <AuditOutlined />, 'Availability')}
      </Menu>
    );
  };

  const getVideoEditorMenu = () => {
    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[currentItem]}
        onSelect={handleMenuItemSelect}
        className={styles.menu}
      >
        {renderMenuItem(
          'MY_EDITING_REQUESTS',
          <AuditOutlined />,
          'My Video Editor Request'
        )}
        {renderMenuItem(
          'VIDEO_EDITOR_PROFILE',
          <AuditOutlined />,
          'My Profile'
        )}
      </Menu>
    );
  };

  const getModeratorMenu = () => {
    return (
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[currentItem]}
        onSelect={handleMenuItemSelect}
        className={styles.menu}
      >
        {renderMenuItem(
          'LIST_OF_ASSIGNED_STREAMS',
          <AuditOutlined />,
          'List of assigned streams'
        )}
      </Menu>
    );
  };

  return (
    <Spin
      tip="Logging out …"
      indicator={<LoadingOutlined spin />}
      spinning={signOutLoading}
    >
      <Layout className={styles.root}>
        {authUser && (
          <Sider collapsible collapsed={menu.collapsed} onCollapse={onCollapse}>
            <Link to="/" className={styles.logoWrapper}>
              <div className={styles.logo} aria-label="Home" />
            </Link>

            {!isDesigner &&
              !isModerator &&
              (storeId || customerId || streamId || campaignId) && (
                <Button
                  ghost
                  type="primary"
                  className={styles.menuTopButton}
                  onClick={handleGoBack}
                >
                  <RollbackOutlined />
                  <p className={styles.menuTopButtonTitle}>Back</p>
                </Button>
              )}

            {isAdmin &&
              !storeId &&
              !customerId &&
              !streamId &&
              !campaignId &&
              getBasicMenu()}

            {isAdmin && storeId && !manageMerchRole && getManagedStoreMenu()}

            {isAdmin && customerId && getManagedCustomerMenu()}

            {isAdmin && streamId && getWatchStreamMenu()}

            {isDesigner && getDesignerMenu()}

            {isVideoEditor && getVideoEditorMenu()}

            {isModerator && getModeratorMenu()}

            {isInterviewer && getInterviewerMenu()}

            <BackTop aria-label="scroll top" className={styles.backTopCta}>
              <div className={styles.backTopCtaIconWrapper}>
                <ArrowUpOutlined />
              </div>
            </BackTop>
          </Sider>
        )}

        <Layout id={LAYOUT_ID}>
          <Header className={styles.header}>
            {authUser ? (
              <>
                <Title level={3} className={styles.title}>
                  {PAGE_TITLES[pathName] || pathName}
                </Title>

                <Space direction="horizontal" size="middle" align="center">
                  <Button type="primary" onClick={handleGoToAccountPage}>
                    Account
                  </Button>

                  <Button type="primary" onClick={handleLogout}>
                    Log out
                  </Button>
                </Space>
              </>
            ) : (
              <div className={styles.logo} aria-label="Millions.co logo" />
            )}
          </Header>

          <Content className={styles.content}>
            {authUser ? (
              children
            ) : (
              <LoginForm
                title="You are not authorized"
                subTitle="Please, login"
              />
            )}
          </Content>
        </Layout>
      </Layout>
    </Spin>
  );
};
