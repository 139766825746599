import { gql } from '@apollo/client';

export const GET_ALL_COUNTRIES_V2 = gql`
  query GetAllCountriesV2 {
    getAllCountriesV2 {
      id
      iso2
      latitude
      longitude
      name
    }
  }
`;

export const GET_COUNTRY_STATES_V2 = gql`
  query GetCountryStates($input: GetCountryStatesInput!) {
    getCountryStatesV2(input: $input) {
      id
      countryCode
      countryId
      latitude
      longitude
      name
      stateCode
      type
    }
  }
`;

export const GET_STATE_CITIES_V2 = gql`
  query GetStateCitiesV2($input: GetStateCitiesInput!) {
    getStateCitiesV2(input: $input) {
      entities {
        id
        countryCode
        countryId
        latitude
        longitude
        name
        stateCode
        stateId
        stateName
      }
      limit
      offset
      total
    }
  }
`;
