import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import {
  Form,
  Input,
  Typography,
  Select,
  DatePicker,
  Checkbox,
  Button,
} from 'antd';
// Api
import {
  CREATE_CAMPAIGN_EXAMPLE_FILES,
  UPDATE_BOOK_ME_CAMPAIGN,
} from 'api/campaignV2/mutations';
import { GET_CAMPAIGN_OFFERS } from 'api/campaignV2/queries';
import { GET_BOOK_ME_STORES, GET_STORE_INFO } from 'api/store/queries';
// Helpers
import { PRODUCT_TYPES, uploadImages } from 'helpers/campaignEditModal';
// Constants
import { dateTimeFormat, onlyDateTimeFormat } from 'constants/global';
// Types
import {
  UpdateBookMeCampaign,
  UpdateBookMeCampaignVariables,
} from 'api/campaignV2/types/UpdateBookMeCampaign';
import {
  AdminStoresOrderBy,
  BookMeActivityInput,
  CampaignActivityCoveredExpense,
  CampaignActivityType,
  CustomActivityInput,
  InPersonActivityInput,
  SocialActivityInput,
  StoreStatus,
  UpdateBookMeCampaignInput,
  UserRole,
  VirtualAppearanceActivityInput,
} from 'api/graphql-global-types';
import {
  GetCampaignsV2_getCampaigns_entities,
  GetCampaignsV2_getCampaigns_entities_activities_CustomActivity,
  GetCampaignsV2_getCampaigns_entities_activities_InPersonAppearanceActivity,
  GetCampaignsV2_getCampaigns_entities_activities_SocialActivity,
  GetCampaignsV2_getCampaigns_entities_activities_VirtualAppearanceActivity,
} from 'api/campaignV2/types/GetCampaignsV2';
import { GetStore, GetStoreVariables } from 'api/store/types/GetStore';
import {
  CreateCampaignExampleFiles,
  CreateCampaignExampleFilesVariables,
} from 'api/campaignV2/types/CreateCampaignExampleFiles';
import {
  GetCampaignOffers,
  GetCampaignOffersVariables,
} from 'api/campaignV2/types/GetCampaignOffers';
import { RangePickerProps } from 'antd/lib/date-picker';
import {
  GetBookMeStores,
  GetBookMeStoresVariables,
} from 'api/store/types/GetBookMeStores';
// Ui
import UploadMultipleImages, {
  UploadImage,
} from 'ui/UploadMultipleImages/UploadMultipleImages';
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import TalentItem from '../../../CampaignDetails/CampaignDetails/components/TalentItem/TalentItem';
// Style
import styles from './BookMeCampaignsEditModal.module.scss';

const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

type FormValues = {
  name: string;
  dueDate: any;
  brandName: string;
  flatFeePerStore: number;
  quantityInstructions?: string | null;
  suggestedCaption?: string | null;
  profileMentions?: string | null;
  hashtags?: string | null;
  physicalProductType?: string | null;
  promoCodes?: string | null;
  promoUrls?: string | null;
  encouraged?: string | null;
  avoid?: string | null;
  scriptUrl?: string | null;
  submissionDue?: any;
  socialDateFrom?: any;
  socialDateTo?: any;
  inPersonAppearanceDuration?: string | null;
  inPersonAppearanceDescription?: string | null;
  venueName?: string | null;
  address?: string | null;
  state?: string | null;
  city?: string | null;
  zipCode?: string | null;
  instructions?: string | null;
  virtualAppearanceDuration?: string | null;
  virtualAppearanceDescription?: string | null;
  virtualLocation?: string | null;
  requirements?: string | null;
  customRequirements?: string | null;
};

type ImageFiles = {
  url: string | null;
  key: string;
  file: File | undefined;
};

type BookMeCampaignsEditModalProps = {
  record: GetCampaignsV2_getCampaigns_entities | null;
  onFinish: () => void;
};

const BookMeCampaignsEditModal = ({
  record,
  onFinish,
}: BookMeCampaignsEditModalProps): JSX.Element | null => {
  const [form] = Form.useForm();

  const socialActivity = record?.activities?.find(
    (item) => item.__typename === 'SocialActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_SocialActivity;

  const virtualAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'VirtualAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_VirtualAppearanceActivity;

  const inPersonAppearanceActivity = record?.activities?.find(
    (item) => item.__typename === 'InPersonAppearanceActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_InPersonAppearanceActivity;

  const customActivity = record?.activities?.find(
    (item) => item.__typename === 'CustomActivity'
  ) as GetCampaignsV2_getCampaigns_entities_activities_CustomActivity;

  const { data: offerData } = useQuery<
    GetCampaignOffers,
    GetCampaignOffersVariables
  >(GET_CAMPAIGN_OFFERS, {
    variables: {
      input: { campaignId: record?.id },
    },
    fetchPolicy: 'cache-and-network',
    skip: !record?.id,
  });

  const talentId = offerData?.getCampaignOffers?.entities[0]?.userId;

  const [isPhysicalProduct, setIsPhysicalProduct] = useState<boolean>(
    socialActivity?.physicalProduct || false
  );

  const [selectedStoreOption, setSelectedStoreOption] = useState<{
    value: string;
    label: string;
  } | null>(null);

  const [isSocial, setIsSocial] = useState<boolean>(!!socialActivity);

  const [isInPerson, setIsInPerson] = useState<boolean>(
    !!inPersonAppearanceActivity
  );

  const [isVirtual, setIsVirtual] = useState<boolean>(
    !!virtualAppearanceActivity
  );

  const [isCustom, setIsCustom] = useState<boolean>(!!customActivity);

  const [customImageFiles, setCustomImageFiles] = useState<ImageFiles[]>(
    customActivity && 'files' in customActivity
      ? customActivity?.files?.map((item) => {
          return { url: item.url, key: item.key, file: undefined };
        })
      : []
  );

  const [inPersonImageFiles, setInPersonImageFiles] = useState<ImageFiles[]>(
    inPersonAppearanceActivity && 'files' in inPersonAppearanceActivity
      ? inPersonAppearanceActivity?.files?.map((item) => {
          return { url: item.url, key: item.key, file: undefined };
        })
      : []
  );

  const [virtualImageFiles, setVirtualImageFiles] = useState<ImageFiles[]>(
    virtualAppearanceActivity && 'files' in virtualAppearanceActivity
      ? virtualAppearanceActivity?.files?.map((item) => {
          return { url: item.url, key: item.key, file: undefined };
        })
      : []
  );

  const [inPersonDates, setInPersonDates] = useState<any[]>(
    inPersonAppearanceActivity &&
      'possibleDates' in inPersonAppearanceActivity &&
      inPersonAppearanceActivity?.possibleDates?.length
      ? inPersonAppearanceActivity.possibleDates.map((date) => moment(date))
      : []
  );

  const [virtualDates, setVirtualDates] = useState<any[]>(
    virtualAppearanceActivity &&
      'possibleDates' in virtualAppearanceActivity &&
      virtualAppearanceActivity?.possibleDates?.length
      ? virtualAppearanceActivity.possibleDates.map((date) => moment(date))
      : []
  );

  const [coveredExpenses, setCoveredExpenses] = useState<
    CampaignActivityCoveredExpense[]
  >(
    inPersonAppearanceActivity &&
      'coveredExpenses' in inPersonAppearanceActivity &&
      inPersonAppearanceActivity?.coveredExpenses?.length
      ? [...inPersonAppearanceActivity?.coveredExpenses]
      : []
  );

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const customImages: UploadImage[] = [];
  customImageFiles?.forEach((image) => {
    customImages.push({
      data_key: image.key,
      data_url: image.url || undefined,
    });
  });

  const inPersonImages: UploadImage[] = [];
  inPersonImageFiles?.forEach((image) => {
    inPersonImages.push({
      data_key: image.key,
      data_url: image.url || undefined,
    });
  });

  const virtualImages: UploadImage[] = [];
  virtualImageFiles?.forEach((image) => {
    virtualImages.push({
      data_key: image.key,
      data_url: image.url || undefined,
    });
  });

  const [updateCampaign, { loading: loadingUpdateCampaign }] = useMutation<
    UpdateBookMeCampaign,
    UpdateBookMeCampaignVariables
  >(UPDATE_BOOK_ME_CAMPAIGN);

  const [
    createCampaignExampleFiles,
    { loading: loadingCreateCampaignExampleFiles },
  ] = useMutation<
    CreateCampaignExampleFiles,
    CreateCampaignExampleFilesVariables
  >(CREATE_CAMPAIGN_EXAMPLE_FILES);

  const { data: storesData, refetch } = useQuery<
    GetBookMeStores,
    GetBookMeStoresVariables
  >(GET_BOOK_ME_STORES, {
    variables: {
      input: {
        orderBy: AdminStoresOrderBy.storeName,
        status: [StoreStatus.Active],
        isOnboarded: true,
      },
      storeRoles: [
        UserRole.Athlete,
        UserRole.Organization, // this is different from the campaign builder project-it's a requested addition
        UserRole.ContentCreator,
      ],
    },
  });

  const { data: storeInfo } = useQuery<GetStore, GetStoreVariables>(
    GET_STORE_INFO,
    {
      variables: {
        input: {
          id: talentId,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: !talentId,
    }
  );

  const storeName = storeInfo?.store.storeDetails?.storeName;

  const initialHashtags = socialActivity?.hashtags.join(',');
  const initialPromoCodes = socialActivity?.promoCodes?.join(',');
  const initialPromoURL = socialActivity?.promoUrls?.join(',');

  const initialFormValues: FormValues = {
    name: record?.name || '',
    dueDate: record?.dueDate ? moment(record?.dueDate) : null,
    brandName: record?.brandName || '',
    flatFeePerStore: Number(record?.flatFeePerStore),

    quantityInstructions: socialActivity?.quantityInstructions || '',
    suggestedCaption: socialActivity?.suggestedCaption || '',
    profileMentions: socialActivity?.profileMentions || '',
    hashtags: initialHashtags ?? null,
    physicalProductType: socialActivity?.physicalProductType || null,
    promoCodes: initialPromoCodes ?? null,
    promoUrls: initialPromoURL ?? null,
    encouraged: socialActivity?.encouraged,
    avoid: socialActivity?.avoid,
    scriptUrl: socialActivity?.script || null,
    submissionDue: socialActivity?.submissionDue
      ? moment(socialActivity?.submissionDue)
      : null,
    socialDateFrom: socialActivity?.postDueFrom
      ? moment(socialActivity?.postDueFrom)
      : null,
    socialDateTo: socialActivity?.postDueTo
      ? moment(socialActivity?.postDueTo)
      : null,

    inPersonAppearanceDuration: inPersonAppearanceActivity?.duration || '',
    inPersonAppearanceDescription:
      inPersonAppearanceActivity?.description || '',
    venueName: inPersonAppearanceActivity?.venueName || '',
    address: inPersonAppearanceActivity?.address || '',
    state: inPersonAppearanceActivity?.state || '',
    city: inPersonAppearanceActivity?.city || '',
    zipCode: inPersonAppearanceActivity?.zip || '',
    instructions: inPersonAppearanceActivity?.instructions || '',

    virtualAppearanceDuration: virtualAppearanceActivity?.duration || '',
    virtualAppearanceDescription: virtualAppearanceActivity?.description || '',
    virtualLocation: virtualAppearanceActivity?.location || '',
    requirements: virtualAppearanceActivity?.instructions || '',

    customRequirements: customActivity?.instructions || '',
  };

  useEffect(() => {
    // updates the talent input with correct data once it's loaded
    setSelectedStoreOption({
      value: `${talentId}`,
      label: storeName || '',
    });
    form.setFields([
      {
        name: 'talentId',
        errors: [],
        value: storeName,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeName, talentId]);

  const handleSelectStore = (value: string, option: any): void => {
    setSelectedStoreOption({ value: option.key, label: option.children });
  };

  const handleSubmit = async () => {
    const {
      name,
      dueDate,
      brandName,
      flatFeePerStore,

      quantityInstructions,
      suggestedCaption,
      profileMentions,
      hashtags,
      physicalProductType,
      promoCodes,
      promoUrls,
      encouraged,
      avoid,
      scriptUrl,
      submissionDue,
      socialDateFrom,
      socialDateTo,

      inPersonAppearanceDuration,
      inPersonAppearanceDescription,
      venueName,
      address,
      state,
      city,
      zipCode,
      instructions,

      virtualAppearanceDuration,
      virtualAppearanceDescription,
      virtualLocation,
      requirements,

      customRequirements,
    } = form.getFieldsValue();

    const activities: BookMeActivityInput[] = [];

    // social has no files so it's added to activities(if there is a Social), while others might need their images uploaded first
    if (isSocial) {
      const social: SocialActivityInput = {
        ...(avoid && { avoid }),
        brandName,
        ...(encouraged && { encouraged }),
        ...(hashtags && {
          hashtags:
            hashtags?.split(',')?.map((tag: string) => tag.trim()) || hashtags,
        }),
        physicalProduct: isPhysicalProduct,
        physicalProductType: isPhysicalProduct ? physicalProductType : null,
        ...(profileMentions && { profileMentions }),
        ...(promoCodes && {
          promoCodes:
            promoCodes?.split(',')?.map((tag: string) => tag.trim()) ||
            promoCodes,
        }),
        ...(promoUrls && {
          promoUrls:
            promoUrls?.split(',')?.map((tag: string) => tag.trim()) ||
            promoUrls,
        }),
        quantityInstructions,
        ...(scriptUrl && { script: scriptUrl }),
        ...(suggestedCaption && { suggestedCaption }),
        submissionDue,
        postDueFrom: socialDateFrom,
        postDueTo: socialDateTo,
      };
      activities.push({ social, type: CampaignActivityType.Social });
    }

    const inPersonImageFilesToUpload: File[] = [];
    const virtualImageFilesToUpload: File[] = [];
    const customImageFilesToUpload: File[] = [];

    const inPersonUploadUrls: string[] = [];
    const virtualUploadUrls: string[] = [];
    const customUploadUrls: string[] = [];

    inPersonImageFiles.forEach((image) => {
      if (image.file) {
        inPersonImageFilesToUpload.push(image.file);
      } else if (image.key) {
        inPersonUploadUrls.push(image.key);
      }
    });

    virtualImageFiles.forEach((image) => {
      if (image.file) {
        virtualImageFilesToUpload.push(image.file);
      } else if (image.key) {
        virtualUploadUrls.push(image.key);
      }
    });

    customImageFiles.forEach((image) => {
      if (image.file) {
        customImageFilesToUpload.push(image.file);
      } else if (image.key) {
        customUploadUrls.push(image.key);
      }
    });

    const hasImagesToUpload =
      !!inPersonImageFilesToUpload.length ||
      !!virtualImageFilesToUpload.length ||
      !!customImageFilesToUpload.length;

    try {
      if (hasImagesToUpload) {
        setIsUploading(true);
        if (inPersonImageFilesToUpload.length) {
          const { data: inPersonFilesData } = await createCampaignExampleFiles({
            variables: {
              input: {
                files: inPersonImageFilesToUpload.map((file) => ({
                  name: file.name,
                })),
              },
            },
          });
          if (inPersonFilesData?.createCampaignExampleFiles?.length) {
            const urls = await uploadImages(
              inPersonFilesData.createCampaignExampleFiles,
              inPersonImageFilesToUpload
            );
            inPersonUploadUrls.push(...urls);
          }
        }

        if (virtualImageFilesToUpload.length) {
          const { data: virtualFilesData } = await createCampaignExampleFiles({
            variables: {
              input: {
                files: virtualImageFilesToUpload.map((file) => ({
                  name: file.name,
                })),
              },
            },
          });
          if (virtualFilesData?.createCampaignExampleFiles?.length) {
            const urls = await uploadImages(
              virtualFilesData.createCampaignExampleFiles,
              virtualImageFilesToUpload
            );
            virtualUploadUrls.push(...urls);
          }
        }

        if (customImageFilesToUpload.length) {
          const { data: customFilesData } = await createCampaignExampleFiles({
            variables: {
              input: {
                files: customImageFilesToUpload.map((file) => ({
                  name: file.name,
                })),
              },
            },
          });
          if (customFilesData?.createCampaignExampleFiles?.length) {
            const urls = await uploadImages(
              customFilesData.createCampaignExampleFiles,
              customImageFilesToUpload
            );
            customUploadUrls.push(...urls);
          }
        }

        setIsUploading(false);
      }

      if (isInPerson) {
        const inPersonAppearance: InPersonActivityInput = {
          address,
          city,
          coveredExpenses: [...coveredExpenses],
          description: inPersonAppearanceDescription,
          duration: inPersonAppearanceDuration,
          instructions,
          possibleDates: inPersonDates.map((date) => date !== ''), // eliminate the empty dates
          state,
          venueName,
          zip: zipCode,
          files: inPersonUploadUrls.map((url) => ({ key: url })),
        };
        activities.push({
          inPersonAppearance,
          type: CampaignActivityType.InPersonAppearance,
        });
      }

      if (isVirtual) {
        const virtualAppearance: VirtualAppearanceActivityInput = {
          description: virtualAppearanceDescription,
          duration: virtualAppearanceDuration,
          location: virtualLocation,
          instructions: requirements,
          possibleDates: virtualDates.map((date) => date !== ''), // eliminate the empty dates
          files: virtualUploadUrls.map((url) => ({ key: url })),
        };
        activities.push({
          virtualAppearance,
          type: CampaignActivityType.VirtualAppearance,
        });
      }

      if (isCustom) {
        const custom: CustomActivityInput = {
          instructions: customRequirements,
          files: customUploadUrls.map((url) => ({ key: url })),
        };
        activities.push({
          custom,
          type: CampaignActivityType.Custom,
        });
      }

      const updateCampaignInputVariables: UpdateBookMeCampaignInput = {
        activities,
        brandName,
        dueDate,
        flatFeePerStore: Number(flatFeePerStore),
        id: record?.id || '',
        name,
        talentId: `${selectedStoreOption?.value}`,
      };

      const { data } = await updateCampaign({
        variables: {
          input: updateCampaignInputVariables,
        },
      });
      if (data?.updateBookMeCampaign) {
        successNotification('The campaign was successfully updated');
        onFinish();
      }
    } catch (error) {
      errorNotification((error as Error)?.message);
      console.error('Campaign update error:', { error });
    }
  };

  const rearrangeImageData = (incomingData: UploadImage[]) => {
    const newImages: ImageFiles[] = [];

    incomingData.forEach((image) => {
      newImages.push({
        key: image.data_key || '',
        url: image.data_url || null,
        file: image.file || undefined,
      });
    });

    return newImages;
  };

  const handleIsPhysicalProductChange = () => {
    if (isPhysicalProduct) {
      form.setFieldsValue({
        physicalProductType: null,
      });
    }

    setIsPhysicalProduct(!isPhysicalProduct);
  };

  const handleChangeIsInPerson = () => {
    setIsVirtual(false);
    setIsInPerson(!isInPerson);
    form.setFieldsValue({ isVirtual: false });
  };

  const handleChangeIsVirtual = () => {
    setIsInPerson(false);
    setIsVirtual(!isVirtual);
    form.setFieldsValue({ isInPerson: false });
  };

  const handleInPersonDateChange = (value: any, index: number) => {
    const newDateTimes = [...inPersonDates];
    newDateTimes[index] = moment(value);

    setInPersonDates(newDateTimes);
  };

  const handleVirtualDateChange = (value: any, index: number) => {
    const newDateTimes = [...virtualDates];
    newDateTimes[index] = moment(value);

    setVirtualDates(newDateTimes);
  };

  const handleDeleteInPersonDate = (index: number) => {
    setInPersonDates(inPersonDates.filter((_, counter) => counter !== index));
  };

  const handleDeleteVirtualDate = (index: number) => {
    setVirtualDates(virtualDates.filter((_, counter) => counter !== index));
  };

  const handleAddInPersonDate = () => {
    setInPersonDates([...inPersonDates, '']);
  };

  const handleAddVirtualDate = () => {
    setVirtualDates([...virtualDates, '']);
  };

  const handleChangeCoveredExpense = (
    expense: CampaignActivityCoveredExpense
  ) => {
    setCoveredExpenses((prevCoveredExpenses) =>
      prevCoveredExpenses.includes(expense)
        ? prevCoveredExpenses.filter((e) => e !== expense)
        : [...prevCoveredExpenses, expense]
    );
  };

  const handleSetCustomImages = (incomingData: UploadImage[]) => {
    const images = rearrangeImageData(incomingData);

    setCustomImageFiles(images);
  };

  const handleSetInPersonImages = (incomingData: UploadImage[]) => {
    const images = rearrangeImageData(incomingData);

    setInPersonImageFiles(images);
  };

  const handleSetVirtualImages = (incomingData: UploadImage[]) => {
    const images = rearrangeImageData(incomingData);

    setVirtualImageFiles(images);
  };

  const handleOnSearchStores = useMemo(() => {
    const loadOptions = (storeName: string) => {
      refetch({
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
        input: {
          storeName,
          orderBy: AdminStoresOrderBy.storeName,
          status: [StoreStatus.Active],
        },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // allow days starting with today
    return current && current < moment();
  };

  // find the talent for display in storesData by comparing the id
  // if it's initial load, we don't have storesData so use storeInfo(it's fetched for that talent's Id)
  const selectedStore =
    storesData?.adminStores.entities.find(
      (store) => store.id === selectedStoreOption?.value
    ) || storeInfo?.store;

  const submitLoading =
    isUploading || loadingUpdateCampaign || loadingCreateCampaignExampleFiles;

  if (!record) return null;

  return (
    <div className={styles.root}>
      <Form
        name="EditCampaignForm"
        aria-label="Update Social Campaign"
        onFinish={handleSubmit}
        form={form}
        initialValues={initialFormValues}
      >
        <div className={styles.columnWrapper}>
          <div className={styles.rowWrapper}>
            <Form.Item
              name="talentId"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label="Select talent"
            >
              <Select
                id="talentId"
                placeholder="Select talent"
                filterOption={(inputValue, option) =>
                  option?.props.children && // adding a check while waiting for response
                  option?.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                onSearch={handleOnSearchStores}
                onSelect={handleSelectStore}
                showSearch
              >
                {storesData?.adminStores.entities.map((item) => {
                  return (
                    <Option value={`${item.id}`} key={item.id}>
                      {item.storeDetails?.storeName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          {selectedStore ? (
            <div className={styles.talent}>
              <TalentItem talent={selectedStore} isVertical />
            </div>
          ) : null}

          <div className={styles.heading}>Campaign Details</div>

          <div className={styles.threeItemsRowWrapper}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Campaign name</Text>}
            >
              <Input
                type="name"
                placeholder="Enter Campaign name"
                aria-label="Campaign name"
              />
            </Form.Item>

            <Form.Item
              name="dueDate"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
                {
                  validator: (_, value) => {
                    if (value && moment().endOf('day').isAfter(value)) {
                      return Promise.reject(
                        new Error(
                          'Application deadline must not be in the past'
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              label={<Text>Application Deadline</Text>}
            >
              <DatePicker
                name="dueDate"
                format={onlyDateTimeFormat}
                disabledDate={disabledDate}
              />
            </Form.Item>

            <Form.Item
              name="brandName"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
              label={<Text>Brand name</Text>}
            >
              <Input
                type="brandName"
                placeholder="Enter Brand name"
                aria-label="Brand name"
              />
            </Form.Item>
          </div>

          <Form.Item
            name="flatFeePerStore"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
            label={<Text>Offer Amount</Text>}
          >
            <Input
              type="number"
              placeholder="Choose your payment method and specify the amount you're offering."
              aria-label="Your Offer"
            />
          </Form.Item>
        </div>

        <div className={styles.columnWrapper}>
          <Form.Item
            name="isSocial"
            label={<Text>Social Post</Text>}
            valuePropName="checked"
            initialValue={isSocial}
          >
            <Checkbox
              value={isSocial}
              onChange={() => {
                setIsSocial(!isSocial);
              }}
            />
          </Form.Item>
          {isSocial && (
            <div className={styles.columnWrapper}>
              <div className={styles.heading}>Social Post</div>

              <Form.Item
                name="quantityInstructions"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Social Platform Post Type and Quantity</Text>}
              >
                <Input
                  type="quantityInstructions"
                  placeholder="Enter Social Platform Post Type and Quantity"
                  aria-label="Social Platform Post Type"
                />
              </Form.Item>
              <Form.Item
                name="suggestedCaption"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Suggested Post Caption</Text>}
              >
                <Input
                  type="suggestedCaption"
                  placeholder="Enter Suggested Post Caption"
                  aria-label="Suggested Post Caption"
                />
              </Form.Item>
              <Form.Item
                name="profileMentions"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Profiles to Tag</Text>}
              >
                <Input
                  type="profileMentions"
                  placeholder="Enter Profiles to Tag"
                  aria-label="Profiles to Tag"
                />
              </Form.Item>

              <Form.Item
                name="hashtags"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label="Hashtags To Use"
              >
                <Input
                  type="hashtags"
                  placeholder="Enter hashtags to use"
                  aria-label="hashtags"
                />
              </Form.Item>

              <div className={styles.rowWrapper}>
                <Form.Item
                  name="physicalProduct"
                  label={<Text>Physical Products</Text>}
                  valuePropName="checked"
                  initialValue={isPhysicalProduct}
                >
                  <Checkbox
                    value={isPhysicalProduct}
                    onChange={handleIsPhysicalProductChange}
                  />
                </Form.Item>
                <Form.Item
                  name="physicalProductType"
                  rules={[
                    {
                      required: isPhysicalProduct,
                      message: 'This field is required',
                    },
                  ]}
                  label={<Text>Product Shipping Category</Text>}
                >
                  <Select
                    id="physicalProductType"
                    placeholder="Select"
                    disabled={!isPhysicalProduct}
                    filterOption={(inputValue, option) =>
                      option?.props.children
                        .toString()
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                  >
                    {PRODUCT_TYPES.map((item) => {
                      return (
                        <Option value={`${item.value}`} key={item.label}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>

              <Form.Item
                name="promoCodes"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Promo Code</Text>}
              >
                <Input
                  type="text"
                  placeholder="Enter Promo Codes"
                  aria-label="Promo Codes"
                />
              </Form.Item>

              <Form.Item
                name="promoUrls"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Promo URL</Text>}
              >
                <Input
                  type="text"
                  placeholder="Enter Promo URL"
                  aria-label="Promo URL"
                />
              </Form.Item>

              <Form.Item
                name="encouraged"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Encouraged</Text>}
              >
                <Input
                  type="text"
                  placeholder="Enter encouraged"
                  aria-label="Encouraged"
                />
              </Form.Item>

              <Form.Item
                name="avoid"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Please Avoid</Text>}
              >
                <Input
                  type="text"
                  placeholder="Enter please avoid"
                  aria-label="Avoid"
                />
              </Form.Item>

              <Form.Item
                name="scriptUrl"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Script URL</Text>}
              >
                <Input
                  type="text"
                  placeholder="Enter script URL"
                  aria-label="Script"
                />
              </Form.Item>

              <Form.Item
                name="submissionDue"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Application deadline date</Text>}
              >
                <DatePicker name="submissionDue" format={onlyDateTimeFormat} />
              </Form.Item>

              <Form.Item
                name="socialDateFrom"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Post Date From</Text>}
              >
                <DatePicker name="socialDateFrom" format={onlyDateTimeFormat} />
              </Form.Item>
              <Form.Item
                name="socialDateTo"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Post Date To</Text>}
              >
                <DatePicker name="socialDateTo" format={onlyDateTimeFormat} />
              </Form.Item>
            </div>
          )}
        </div>

        <div className={styles.columnWrapper}>
          <div className={styles.rowWrapper}>
            <Form.Item
              name="isInPerson"
              label={<Text>In Person Appearance</Text>}
              valuePropName="checked"
              initialValue={isInPerson}
            >
              <Checkbox value={isInPerson} onChange={handleChangeIsInPerson} />
            </Form.Item>

            <Form.Item
              name="isVirtual"
              label={<Text>Virtual Appearance</Text>}
              valuePropName="checked"
              initialValue={isVirtual}
            >
              <Checkbox value={isVirtual} onChange={handleChangeIsVirtual} />
            </Form.Item>
          </div>

          {isInPerson && (
            <div className={styles.columnWrapper}>
              <div className={styles.heading}>In Person Appearance</div>

              <div className={styles.dateManager}>
                {inPersonDates.map((date, index) => {
                  return (
                    <div key={index} className={styles.dateRow}>
                      <div className={styles.datePickerWrapper}>
                        <DatePicker
                          format={dateTimeFormat}
                          value={date}
                          onChange={(value) =>
                            handleInPersonDateChange(value, index)
                          }
                          showTime
                        />
                      </div>

                      {index > 0 && (
                        <Button
                          className={styles.deleteDateButton}
                          type="primary"
                          danger
                          onClick={() => handleDeleteInPersonDate(index)}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  );
                })}
                <div className={styles.addDateButtonWrapper}>
                  <Button type="primary" onClick={handleAddInPersonDate}>
                    Add another date/time option
                  </Button>
                </div>
              </div>
              <Form.Item
                name="inPersonAppearanceDuration"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Appearance Duration</Text>}
              >
                <Input
                  type="inPersonAppearanceDuration"
                  placeholder="e.g., 2 hours"
                  aria-label="Appearance Duration"
                />
              </Form.Item>
              <Form.Item
                name="inPersonAppearanceDescription"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Appearance Description</Text>}
              >
                <TextArea
                  autoSize={{ minRows: 3 }}
                  name="inPersonAppearanceDescription"
                  placeholder="Write Description"
                />
              </Form.Item>
              <Form.Item
                name="venueName"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Venue Name</Text>}
              >
                <Input
                  type="venueName"
                  placeholder="Enter event/venue name"
                  aria-label="Venue Name"
                />
              </Form.Item>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Address</Text>}
              >
                <Input
                  type="address"
                  placeholder="Enter address"
                  aria-label="Address"
                />
              </Form.Item>
              <Form.Item
                name="state"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>State</Text>}
              >
                <Input
                  type="state"
                  placeholder="Enter state"
                  aria-label="State"
                />
              </Form.Item>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>City</Text>}
              >
                <Input type="city" placeholder="Enter city" aria-label="City" />
              </Form.Item>
              <Form.Item
                name="zipCode"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Zip Code</Text>}
              >
                <Input
                  type="zipCode"
                  placeholder="Enter zip code"
                  aria-label="Zip Code"
                />
              </Form.Item>

              <div className={styles.noMarginHeading}>
                Travel & Accommodations
              </div>
              <Text>
                {`Specify any travel or accommodations you'll cover. (if needed)`}
              </Text>
              <div className={styles.expenseWrapper}>
                {Object.keys(CampaignActivityCoveredExpense).map((expense) => (
                  <div key={expense}>
                    <Text>{expense}</Text>
                    <Checkbox
                      className={styles.expenseCheckbox}
                      type="checkbox"
                      checked={coveredExpenses.some((item) => item === expense)}
                      onChange={() =>
                        handleChangeCoveredExpense(
                          expense as CampaignActivityCoveredExpense
                        )
                      }
                    />
                  </div>
                ))}
              </div>

              <Form.Item
                name="instructions"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Special Instructions</Text>}
              >
                <TextArea
                  autoSize={{ minRows: 3 }}
                  name="instructions"
                  placeholder="Special instructions or additional information (e.g., attire, required wifi, required video quality, specific devices needed, topics of conversation, etc...)"
                />
              </Form.Item>

              <div className={styles.uploadRowWrapper}>
                <UploadMultipleImages
                  images={inPersonImages}
                  setImages={handleSetInPersonImages}
                  isMainImage={null}
                />
              </div>
            </div>
          )}
          {isVirtual && (
            <div className={styles.columnWrapper}>
              <div className={styles.heading}>Virtual Appearance</div>

              <div className={styles.heading}>Appearance Date & Time</div>
              <Text>
                {`Choose the date and time for the talent's appearance.`}
              </Text>

              <div className={styles.dateManager}>
                {virtualDates.map((date, index) => (
                  <div key={index} className={styles.dateRow}>
                    <div className={styles.datePickerWrapper}>
                      <Text>Date</Text>
                      <DatePicker
                        format={dateTimeFormat}
                        value={date}
                        onChange={(value) =>
                          handleVirtualDateChange(value, index)
                        }
                        showTime
                      />
                    </div>

                    {index > 0 && (
                      <Button
                        className={styles.deleteDateButton}
                        type="primary"
                        danger
                        onClick={() => handleDeleteVirtualDate(index)}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                ))}
                <div className={styles.addDateButtonWrapper}>
                  <Button type="primary" onClick={handleAddVirtualDate}>
                    Add another date/time option
                  </Button>
                </div>
              </div>
              <Form.Item
                name="virtualAppearanceDuration"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={<Text>Appearance Duration</Text>}
              >
                <Input
                  type="virtualAppearanceDuration"
                  placeholder="e.g., 2 hours"
                  aria-label="Appearance Duration"
                />
              </Form.Item>

              <div className={styles.heading}>Appearance Description</div>

              <Form.Item
                name="virtualAppearanceDescription"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={
                  <Text>{`Detailed description of the event and talent's role at the
                event.`}</Text>
                }
              >
                <TextArea
                  autoSize={{ minRows: 3 }}
                  name="virtualAppearanceDescription"
                  placeholder="Write Description"
                />
              </Form.Item>

              <div className={styles.heading}>Appearance Location</div>
              <Form.Item
                name="virtualLocation"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={
                  <Text>
                    What platform will the talent be using to virtually join?
                  </Text>
                }
              >
                <Input
                  type="virtualLocation"
                  placeholder="E.g. Zoom, Phone Call, IG LIVE"
                  aria-label="Appearance Location"
                />
              </Form.Item>

              <div className={styles.heading}>Appearance Requirements</div>
              <Form.Item
                name="requirements"
                rules={[
                  {
                    required: true,
                    message: 'This field is required',
                  },
                ]}
                label={
                  <Text>
                    Upload examples and provide special instructions for the
                    appearance.
                  </Text>
                }
              >
                <TextArea
                  autoSize={{ minRows: 3 }}
                  name="requirements"
                  placeholder="Special instructions or additional information (e.g., attire, required wifi, required video quality, specific devices needed, topics of conversation, etc...)"
                />
              </Form.Item>
              <div className={styles.uploadRowWrapper}>
                <UploadMultipleImages
                  images={virtualImages}
                  setImages={handleSetVirtualImages}
                  isMainImage={null}
                />
              </div>
            </div>
          )}
        </div>

        <div className={styles.columnWrapper}>
          <div className={styles.rowWrapper}>
            <Form.Item
              name="isCustom"
              label={<Text>Custom</Text>}
              valuePropName="checked"
              initialValue={isCustom}
            >
              <Checkbox
                value={isCustom}
                onChange={() => {
                  setIsCustom(!isCustom);
                }}
              />
            </Form.Item>
          </div>

          {isCustom && (
            <div className={styles.columnWrapper}>
              <div className={styles.heading}>Custom</div>
              <div className={styles.heading}>
                Details & Talent Requirements
              </div>
              <Text>
                Upload examples, briefs, or other files and provide specific
                instructions for the booking.
              </Text>
              <Form.Item name="customRequirements">
                <TextArea
                  autoSize={{ minRows: 3 }}
                  name="customRequirements"
                  placeholder="Please be as specific as possible, including dates, times, exact requirements of the talent and what is being requested of them.
                  E.g. I want to create a long-term partnership that spans over 12 months where you’ll be doing x,y,z, I want to do a video/photo shoot on these dates, I’d like you to make a video for our brand to promote to clients with, etc..."
                />
              </Form.Item>

              <div className={styles.heading}>Examples and Uploads</div>
              <Text>
                Upload files (examples, brand guidelines, campaign/content
                brief, etc...)
              </Text>
              <div className={styles.rowWrapper}>
                <UploadMultipleImages
                  images={customImages}
                  setImages={handleSetCustomImages}
                  isMainImage={null}
                />
              </div>
            </div>
          )}
        </div>

        <div className={styles.submitButtonWrapper}>
          <Button type="primary" htmlType="submit" loading={submitLoading}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default BookMeCampaignsEditModal;
