import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
// API
import { GET_AGENCIES } from 'api/campaignV2/queries';
import { UPDATE_CAMPAIGN_OWNERSHIP } from 'api/campaignV2/mutations';
// Types
import { GetCampaignsV2_getCampaigns_entities } from 'api/campaignV2/types/GetCampaignsV2';
import {
  GetAgencies,
  GetAgenciesVariables,
} from 'api/campaignV2/types/GetAgencies';
import {
  UpdateCampaignOwnership,
  UpdateCampaignOwnershipVariables,
} from 'api/campaignV2/types/UpdateCampaignOwnership';
import { AgencyListOrderBy, SortDirection } from 'api/graphql-global-types';
// Ui
import { successNotification, errorNotification } from 'ui/Notification';
import Table, { SortedInfo, TableFilter } from 'ui/Table';
// Styles
import styles from './ChangeOwnershipModal.module.scss';

const { Text } = Typography;

type FormattedData = {
  id: string;
  name: string;
  companyName: string;
  email: string;
};

type ChangeOwnershipModalProps = {
  record: GetCampaignsV2_getCampaigns_entities | null;
  onFinish: () => void;
};

const ChangeOwnershipModal = ({
  record,
  onFinish,
}: ChangeOwnershipModalProps): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<AgencyListOrderBy>>({
    order: SortDirection.DESC,
    key: AgencyListOrderBy.firstName,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getAgenciesInput: any = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    return { input };
  };

  const { data: agenciesData } = useQuery<GetAgencies, GetAgenciesVariables>(
    GET_AGENCIES,
    {
      variables: {
        ...getAgenciesInput(),
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  const [updateOwnership, { loading }] = useMutation<
    UpdateCampaignOwnership,
    UpdateCampaignOwnershipVariables
  >(UPDATE_CAMPAIGN_OWNERSHIP);

  const formattedData: FormattedData[] =
    agenciesData?.getAgencies.entities?.map(
      ({ id, firstName, lastName, email, agencyDetails }) => {
        return {
          id: id,
          name: `${firstName} ${lastName}`,
          companyName: `${agencyDetails?.companyName}`,
          email: `${email}`,
        };
      }
    ) || [];

  const handleChangeOwnership = async (agencyId: string) => {
    try {
      await updateOwnership({
        variables: {
          input: {
            campaignId: record?.id as string,
            newOwnerId: agencyId,
          },
        },
      });
      successNotification(`Ownership changed successfully`);
      onFinish();
    } catch (err) {
      errorNotification((err as Error)?.message);
      console.error('update ownership error:', { ...(err as Error) });
    }
  };

  const columns = [
    {
      title: 'Agency name',
      dataIndex: 'name',
      align: 'left' as const,
      key: 'name',
      width: 200,
      withSearch: true,
      render: function RequestDetails(firstName: string, item: FormattedData) {
        return <Text>{item.name}</Text>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center' as const,
      width: 200,
      withSearch: true,
      key: 'email',
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      align: 'center' as const,
      width: 200,
      withSearch: true,
      key: 'companyName',
    },
    {
      title: 'Action',
      dataIndex: 'id',
      align: 'center' as const,
      width: 50,
      render: function ActionsWrap(id: string) {
        if (id !== record?.agency.id) {
          return (
            <Button
              type="primary"
              onClick={() => handleChangeOwnership(id)}
              loading={loading}
            >
              Assign
            </Button>
          );
        } else {
          return <div>Current Owner</div>;
        }
      },
    },
  ];

  return (
    <div className={styles.root}>
      <div className={styles.ownerData}>
        <div>
          Name:
          <span
            className={styles.value}
          >{`${record?.agency.firstName} ${record?.agency.lastName}`}</span>
        </div>
        <div>
          Email:
          <span className={styles.value}>{`${record?.agency.email}`}</span>
        </div>
        <div>
          Company:
          <span
            className={styles.value}
          >{`${record?.agency.agencyDetails?.companyName}`}</span>
        </div>
      </div>

      <Table<FormattedData, AgencyListOrderBy>
        columns={columns}
        data={formattedData}
        scroll={{ x: 450 }}
        loading={loading}
        total={agenciesData?.getAgencies?.total}
        defaultPageSize={pageSize}
        setSearchFilters={setSearchFilters}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />
    </div>
  );
};

export default ChangeOwnershipModal;
