import { useMutation, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { Button, Select } from 'antd';
import { debounce } from 'lodash';
import React from 'react';
// API
import { GET_STORES_BY_NAME_AND_EMAIL } from 'api/store/queries';
import { ADD_ATHLETE_TO_CAMPAIGN } from 'api/campaignV2/mutations';
// Types
import {
  GetStoresByNameAndEmail,
  GetStoresByNameAndEmail_emailStores_entities,
  GetStoresByNameAndEmail_nameStores_entities,
  GetStoresByNameAndEmailVariables,
} from 'api/store/types/GetStoresByNameAndEmail';
import { GetCampaignsV2_getCampaigns_entities } from 'api/campaignV2/types/GetCampaignsV2';
import {
  AddAthleteToCampaign,
  AddAthleteToCampaignVariables,
} from 'api/campaignV2/types/AddAthleteToCampaign';
// Ui
import { successNotification, errorNotification } from 'ui/Notification';
// Components
import TalentItem from '../../../TalentItem/TalentItem';
// Style
import styles from './SearchByNameAndEmail.module.scss';

const { Option, OptGroup } = Select;

type OptionType = {
  label: string;
  value: string;
};

type OptionGroupType = {
  title: string;
  options: OptionType[];
};

type SelectedStoreType =
  | GetStoresByNameAndEmail_nameStores_entities
  | GetStoresByNameAndEmail_emailStores_entities
  | null;

type SearchByNameAndEmailProps = {
  record: GetCampaignsV2_getCampaigns_entities | null;
  onAdd: () => void;
};

const SearchByNameAndEmail = ({
  record,
  onAdd,
}: SearchByNameAndEmailProps): JSX.Element => {
  const { data: storesData, refetch } = useQuery<
    GetStoresByNameAndEmail,
    GetStoresByNameAndEmailVariables
  >(GET_STORES_BY_NAME_AND_EMAIL);

  const [addAthlete, { loading }] = useMutation<
    AddAthleteToCampaign,
    AddAthleteToCampaignVariables
  >(ADD_ATHLETE_TO_CAMPAIGN);

  const [selectedStore, setSelectedStore] = useState<SelectedStoreType>(null);

  const handleSelectStore = (value: string): void => {
    setSelectedStore(
      storesData?.nameStores.entities.find((store) => store.id === value) ||
        storesData?.emailStores.entities.find((store) => store.id === value) ||
        null
    );
  };

  const handleAddTalent = async () => {
    const campaignId = record?.id;
    const talentId = selectedStore?.id;
    if (campaignId && talentId) {
      try {
        const { data } = await addAthlete({
          variables: {
            input: {
              campaignId,
              talentId,
            },
          },
        });

        if (data?.addOfferToCampaign.id) {
          successNotification('Talent successfully added to the campaign');
          setSelectedStore(null);
          onAdd();
        }
      } catch (error: any) {
        console.error('Error adding talent', error);
        errorNotification(error?.message || 'Error adding talent');
      }
    } else {
      errorNotification('Error loading campaign or talent id');
    }
  };

  const handleOnSearchStores = useMemo(() => {
    const fetchTalents = (value: string) => {
      refetch({
        searchTerm: value,
      });
    };

    return debounce(fetchTalents, 400);
  }, [refetch]);

  const searchSelectOptions: OptionGroupType[] = [
    {
      title: 'Stores by name',
      options:
        storesData?.nameStores.entities.map((item) => {
          return {
            value: item.id,
            label: item.storeDetails?.storeName || '',
          };
        }) || [],
    },
    {
      title: 'Stores by email',
      options:
        storesData?.emailStores.entities.map((item) => {
          return {
            value: item.id,
            label: item.email || '',
          };
        }) || [],
    },
  ];

  return (
    <div className={styles.addTalentWrapper}>
      <p className={styles.label}>Add new Talent:</p>
      <Select
        id="talentId"
        placeholder="Search talents"
        filterOption={(inputValue, option) => {
          return (
            option?.props.children &&
            option?.props.children
              .toString()
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          );
        }}
        onSearch={handleOnSearchStores}
        onSelect={handleSelectStore}
        showSearch
        style={{ width: 350 }}
      >
        {searchSelectOptions.map((group, index) =>
          group.options.length ? (
            <OptGroup label={group.title} key={group.title}>
              {group.options.map((item) => (
                <Option key={item.value + '_' + index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </OptGroup>
          ) : null
        )}
      </Select>

      {selectedStore ? (
        <div className={styles.talent}>
          <TalentItem talent={selectedStore} isVertical />
          <Button type="primary" loading={loading} onClick={handleAddTalent}>
            Add Talent
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default SearchByNameAndEmail;
