import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Space, Typography } from 'antd';
// Api
import { GET_STORE_INTERVIEWERS } from 'api/interviewer/queries';
// Types
import { TableFilter } from 'ui/Table';
import {
  GetStoreInterviewers,
  GetStoreInterviewersVariables,
  GetStoreInterviewers_getStoreInterviewers_entities,
} from 'api/interviewer/types/GetStoreInterviewers';
import {
  StoreInterviewerFilterInput,
  StoreInterviewerOrderBy,
  StoreStatus,
} from 'api/graphql-global-types';
// UI
import Table from 'ui/Table';
// Components
import Actions from 'pages/Interviewers/components/Actions/Actions';
import CreateEditInterviewerModal from 'pages/Interviewers/components/CreateEditInterviewModal/CreateEditInterviewerModal';
import InterviewTasks from './components/InterviewTasks/InterviewTasks';
// Styles
import styles from './Interviewers.module.scss';

const { Text } = Typography;

const getName = (
  firstName: string | undefined | null,
  lastName: string | undefined | null,
  email: string | undefined | null
): string => {
  return firstName || lastName ? `${firstName} ${lastName}` : email || '';
};

const Interviewers = (): JSX.Element => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);
  const [createEditModalVisible, setCreateEditModalVisible] =
    useState<boolean>(false);

  const [selectedInterviewer, setSelectedInterviewer] =
    useState<GetStoreInterviewers_getStoreInterviewers_entities | null>(null);

  const [showTasksModal, setShowTasksModal] = useState<boolean>(false);

  const handleShowModal = (
    interviewer: GetStoreInterviewers_getStoreInterviewers_entities
  ) => {
    setSelectedInterviewer(interviewer);
    setShowTasksModal(true);
  };

  const handleHideModal = () => {
    setShowTasksModal(false);
    setSelectedInterviewer(null);
  };

  const getInterviewersInput = (): GetStoreInterviewersVariables => {
    const input: StoreInterviewerFilterInput = {
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      status: [StoreStatus.Active, StoreStatus.Inactive, StoreStatus.Pending],
    };

    searchFilters.forEach(({ key, value }) => {
      input[key as keyof StoreInterviewerFilterInput] = value as any;
    });

    return { input };
  };

  const { data } = useQuery<
    GetStoreInterviewers,
    GetStoreInterviewersVariables
  >(GET_STORE_INTERVIEWERS, {
    variables: { ...getInterviewersInput() },
    fetchPolicy: 'cache-and-network',
  });

  const handleCreateEditModalToggle = () => {
    setCreateEditModalVisible((prev) => !prev);
  };

  const columns = [
    {
      title: 'Interviewer name',
      dataIndex: 'firstName',
      align: 'left' as const,
      key: 'firstName',
      width: 130,
      withSearch: true,
      render: function RequestDetails(
        firstName: string,
        record: GetStoreInterviewers_getStoreInterviewers_entities
      ) {
        return (
          <Text>{`${record?.firstName || ''} ${record?.lastName || ''}`}</Text>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'left' as const,
      width: 130,
      withSearch: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'left' as const,
      width: 60,
    },
    {
      title: 'Scheduled',
      dataIndex: 'scheduledInterviewCnt',
      key: 'scheduledInterviewCnt',
      align: 'center' as const,
      width: 80,
      render: function TasksWrap(
        scheduledInterviewCnt: number,
        record: GetStoreInterviewers_getStoreInterviewers_entities
      ) {
        return (
          <Button
            type="link"
            onClick={() => handleShowModal(record)}
            title={`${record.firstName} ${record.lastName} scheduled interviews`}
            disabled={!scheduledInterviewCnt}
          >
            {scheduledInterviewCnt > 0
              ? scheduledInterviewCnt
              : 'No scheduled interviews yet'}
          </Button>
        );
      },
    },
    {
      title: 'Auto Assign',
      dataIndex: ['interviewerDetails', 'isIncludedInAutoAssignment'],
      align: 'center' as const,
      width: 60,
      render: function AutoAssignWrap(isIncludedInAutoAssignment: boolean) {
        return <span>{isIncludedInAutoAssignment ? 'Yes' : 'No'}</span>;
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      fixed: 'right' as const,
      align: 'center' as const,
      width: 60,
      render: function ActionsWrap(
        id: string,
        record: GetStoreInterviewers_getStoreInterviewers_entities
      ) {
        return <Actions interviewer={record} key={id} />;
      },
    },
  ];

  return (
    <>
      <div>
        <Space direction="vertical" size="middle" align="end">
          <Button
            className={styles.button}
            type="primary"
            onClick={handleCreateEditModalToggle}
          >
            Add new interviewer
          </Button>
        </Space>

        <Table<
          GetStoreInterviewers_getStoreInterviewers_entities,
          StoreInterviewerOrderBy
        >
          columns={columns}
          data={data?.getStoreInterviewers?.entities}
          scroll={{ x: 300 }}
          total={data?.getStoreInterviewers.total}
          setPageSize={setPageSize}
          setCurrentPage={setCurrentPage}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
        />
      </div>

      <CreateEditInterviewerModal
        autoAssignDisabled={true}
        isVisible={createEditModalVisible}
        onClose={handleCreateEditModalToggle}
      />

      {selectedInterviewer && (
        <InterviewTasks
          show={showTasksModal}
          onClose={handleHideModal}
          interviewerId={selectedInterviewer.id}
          interviewerName={`${getName(
            selectedInterviewer.firstName,
            selectedInterviewer.lastName,
            selectedInterviewer.email
          )}`}
        />
      )}
    </>
  );
};

export default Interviewers;
