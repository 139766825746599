import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Modal } from 'antd';
import { useDropzone } from 'react-dropzone';
import { useSetState } from 'react-use';
import cn from 'classnames';
// Api
import {
  COMPLETE_STREAM_MULTIPART_UPLOAD,
  CREATE_SPONSORED_APPEARANCE_IMAGE,
  INIT_STREAM_MULTIPART_UPLOAD,
  SUBMIT_PAST_APPEARANCE_STREAM,
} from 'api/campaignV2/mutations';
import { CREATE_UNREGISTERED_CONTACT } from 'api/mentions/mutations';
// Types
import { GetCampaignOffers_getCampaignOffers_entities } from 'api/campaignV2/types/GetCampaignOffers';
import {
  CreateUnregisteredContact,
  CreateUnregisteredContactVariables,
} from 'api/mentions/types/CreateUnregisteredContact';
import {
  InitStreamMultipartUpload,
  InitStreamMultipartUploadVariables,
} from 'api/campaignV2/types/InitStreamMultipartUpload';
import {
  CompleteStreamMultipartUpload,
  CompleteStreamMultipartUploadVariables,
} from 'api/campaignV2/types/CompleteStreamMultipartUpload';
import {
  CreateStreamImage,
  CreateStreamImageVariables,
} from 'api/interview/types/CreateStreamImage';
import {
  SubmitPastAppearanceStream,
  SubmitPastAppearanceStreamVariables,
} from 'api/campaignV2/types/SubmitPastAppearanceStream';
import {
  CampaignOfferStatus,
  CreateMentionInput,
  CreatePastAppearanceStreamInput,
  CreateUnregisteredContactInput,
} from 'api/graphql-global-types';
// Helpers
import { calculateChunkSize, uploadImageToS3 } from 'helpers/mediaFeed';
import { formatMentionsInput } from 'helpers/mentions';
import { Uploader } from 'helpers/uploader';
import { bytesToSize } from 'helpers/bytesToSize';
import { getProperErrorMessage } from 'helpers/errors';
import { getIfVideoSupportedByBrowser } from 'helpers/media';
// Components
import EasyImageCrop from 'components/common/EasyImageCrop/EasyImageCrop';
import UploadImagePlaceholder from 'components/common/UploadImagePlaceholder/UploadImagePlaceholder';
import SubmittedSponsored from './SubmittedSponsored/SubmittedSponsored';
// Ui
import { errorNotification, successNotification } from 'ui/Notification';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import TagsInput from 'uiShared/TagsInput/TagsInput';
// UiShared
import Button from 'uiShared/Button/Button';
import TextArea from 'uiShared/Textarea/Textarea';
import VideoPreview from 'uiShared/VideoPreview/VideoPreview';
import Mentions, { MentionValues } from 'uiShared/Mentions/Mentions';
// Styles
import styles from './SponsoredDeliverables.module.scss';

const MAX_VIDEO_SIZE_B = 5 * 1024 * 1024 * 1024 * 1024; // equals to 5 terabytes, our current limit

type FormInputs = {
  title: string;
  description?: string;
  image?: File | null;
  video?: string | null;
  previewImage?: string;
  hashtags?: string[];
  mentions?: MentionValues[];
};

type SponsoredDeliverablesProps = {
  record: GetCampaignOffers_getCampaignOffers_entities | null;
  onClose: () => void;
  onFinish: () => void;
};

const getValidationSchema = (): any => {
  return yup.object().shape({
    title: yup
      .string()
      .max(200, 'Max 200 characters')
      .required('Field is required'),
    description: yup
      .string()
      .max(2200, 'Max 2200 characters')
      .required('Field is required'),
  });
};

const formatHashtagInput = (hashtags: string[] | undefined) => {
  if (hashtags?.length) {
    return hashtags?.map((hashtag) => ({ name: hashtag }));
  } else {
    return null;
  }
};

const SponsoredDeliverables = ({
  record,
  onClose,
  onFinish,
}: SponsoredDeliverablesProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [mentionsValues, setMentionsValues] = useState<MentionValues[]>([]);

  const [videoProgressContainer, setVideoProgressContainer] =
    useState<string>('');

  const initialVideoStateValues = {
    videoFile: null,
    videoPreviewURL: null,
    cropArea: null,
    isScrollDisabled: false,
    videoFileSize: 0,
    showVideoPreview: false,
    isCropNotSupported: false,
    isCropVisible: false,
  };

  const [state, setState] = useSetState<{
    videoFile: File | null;
    videoPreviewURL: string | null;
    isScrollDisabled: boolean;
    videoFileSize: number;
    showVideoPreview: boolean;
  }>(initialVideoStateValues);

  const { videoFile, videoPreviewURL, videoFileSize, showVideoPreview } = state;

  const uploadVideoRef = useRef<HTMLInputElement | null>(null);
  const imageUploadRef = useRef<HTMLInputElement>(null);

  const [createUnregisteredContact] = useMutation<
    CreateUnregisteredContact,
    CreateUnregisteredContactVariables
  >(CREATE_UNREGISTERED_CONTACT);

  const [initStreamMultipartUpload] = useMutation<
    InitStreamMultipartUpload,
    InitStreamMultipartUploadVariables
  >(INIT_STREAM_MULTIPART_UPLOAD);

  const [completeStreamMultipartUpload] = useMutation<
    CompleteStreamMultipartUpload,
    CompleteStreamMultipartUploadVariables
  >(COMPLETE_STREAM_MULTIPART_UPLOAD);

  const [createStreamImage] = useMutation<
    CreateStreamImage,
    CreateStreamImageVariables
  >(CREATE_SPONSORED_APPEARANCE_IMAGE);

  const [submitPastAppearanceStream] = useMutation<
    SubmitPastAppearanceStream,
    SubmitPastAppearanceStreamVariables
  >(SUBMIT_PAST_APPEARANCE_STREAM);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    unregister,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(getValidationSchema()),
    mode: 'onChange',
    defaultValues: {
      title: '',
      description: '',
      previewImage: '',
      hashtags: undefined,
      mentions: [],
    },
  });
  const image = watch('image');
  const description = watch('description');
  const title = watch('title');
  const previewImage = watch('previewImage');
  const hashtags = watch('hashtags');

  const handleReturnUploadPercentage = (percentage: string) => {
    setVideoProgressContainer('Uploading: ' + percentage);
  };

  const handleUploadVideoError = (error: any) => {
    errorNotification(
      error.graphQLErrors[0].extensions.response.message[0] ||
        (error as Error).message ||
        'Error uploading video!'
    );

    setIsLoading(false);
  };

  const submissionId = record?.submissions[0]?.id || '';

  const uploadImageStep = async () => {
    if (image) {
      try {
        const getUrl = await createStreamImage({
          variables: {
            input: {
              files: [
                {
                  contentType: (image as File).type || '',
                  ext: (image as File).name.split('.').pop() || '',
                },
              ],
            },
          },
        });
        if (getUrl?.data) {
          const uploadImageData = await uploadImageToS3(
            getUrl.data.createStreamImage[0],
            {
              data_url: '',
              file: image,
            }
          );
          return uploadImageData;
        }
      } catch (error) {
        errorNotification((error as Error).message || 'Error uploading image!');
      }
    }
  };

  const handleVideoSubmit = async (values: FormInputs) => {
    const { videoFile } = state;
    const videoFileName = `${record?.firstName} ${record?.lastName} sport video.`;

    const imageFileKey = await uploadImageStep();

    if (imageFileKey) {
      if (videoFile) {
        try {
          const chunkSize = calculateChunkSize((videoFile as File).size);
          const contentType = (videoFile as File).type;
          const numberOfParts = Math.ceil(videoFile.size / chunkSize);
          const fileExtension = (videoFile as File).name.split('.').pop() || '';

          const { data } = await initStreamMultipartUpload({
            variables: {
              input: {
                contentType,
                fileExt: fileExtension,
                partCnt: numberOfParts,
              },
            },
          });

          if (data?.initStreamMultipartUpload) {
            let fileKey: string;

            const uploader = new Uploader({
              chunkSize: chunkSize,
              file: new File([videoFile], `${videoFileName}.${fileExtension}`),
              initData: data.initStreamMultipartUpload,
            });

            uploader.start();

            uploader
              .onProgress(({ percentage: newPercentage }) => {
                handleReturnUploadPercentage(`${newPercentage}%`);
              })
              .onComplete(async (input) => {
                fileKey = input.fileKey;

                await completeStreamMultipartUpload({
                  variables: {
                    input,
                  },
                });

                const createUnregisteredContactInput =
                  (mentionsValues as MentionValues[])?.filter(
                    (mention) => !mention?.targetType
                  ) || [];

                const unregisterContactsData = await createUnregisteredContact({
                  variables: {
                    input:
                      createUnregisteredContactInput as CreateUnregisteredContactInput[],
                  },
                });

                const newUnregisteredContacts =
                  unregisterContactsData.data?.createUnregisteredContact?.map(
                    (contact) => ({
                      unregisteredContactId: contact.id,
                    })
                  ) || [];

                const oldMentions = (mentionsValues?.filter(
                  (mention) => mention?.targetType
                ) || []) as MentionValues[];
                const formattedOldMentions = formatMentionsInput(oldMentions);

                const submitPastAppearanceStreamInput: CreatePastAppearanceStreamInput =
                  {
                    submissionId,
                    stream: {
                      name: values.title,
                      imageFileKey,
                      description: values.description || '',
                      records: [{ key: fileKey || '' }],
                      hashtagInputs: formatHashtagInput(hashtags),
                      mentionsInputs: [
                        ...formattedOldMentions,
                        ...newUnregisteredContacts,
                      ] as CreateMentionInput[],
                    },
                    ownerId: record?.userId,
                  };

                try {
                  await submitPastAppearanceStream({
                    variables: {
                      input: submitPastAppearanceStreamInput,
                    },
                  });
                  setIsLoading(false);
                  handleFinish();

                  successNotification('Video Submitted Successfully!');
                } catch (error) {
                  errorNotification(
                    getProperErrorMessage(error, 'Something went wrong!')
                  );

                  setIsLoading(false);
                }
              })
              .onError(handleUploadVideoError);
          }
        } catch (error) {
          setIsLoading(false);
          handleUploadVideoError(error);
        }
      }
    }
  };

  const handleUploadVideoButtonClick = () => {
    uploadVideoRef.current?.click();
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);

      setState({
        videoFile: file,
        videoPreviewURL: fileURL,
        videoFileSize: file.size,
        showVideoPreview: getIfVideoSupportedByBrowser(file),
      });
    }
  };

  const handleMentionChange = useCallback(
    (mentions) => {
      if (JSON.stringify(mentions) !== JSON.stringify(mentionsValues)) {
        return setMentionsValues(mentions);
      }
    },
    [mentionsValues]
  );

  const handleFormSubmit: SubmitHandler<FormInputs> = async (
    values
  ): Promise<void> => {
    setIsLoading(true);

    await handleVideoSubmit(values);
  };

  useEffect(() => {
    register('image');
    register('previewImage');

    return () => {
      unregister('image');
      unregister('previewImage');
    };
  }, [register, unregister]);

  const isVideoFileSizeError = videoFileSize > MAX_VIDEO_SIZE_B;

  const videoPreviewErrorMessage = isVideoFileSizeError ? (
    <span>
      The size of uploaded video is more than our limit (
      <strong>{bytesToSize(videoFileSize)}</strong>
      ). Please, select a video smaller than 5 TB.
    </span>
  ) : null;

  const videoPreviewNotSupported = Boolean(
    videoPreviewURL && !showVideoPreview
  );

  const noFile = !videoFile;

  const isSubmitDisabled =
    isLoading ||
    isVideoFileSizeError ||
    noFile ||
    !image ||
    title === '' ||
    description === '' ||
    (hashtags && hashtags?.length > 5);

  const handleApplyImage = useCallback(
    (newImage: File) => {
      setValue('previewImage', URL.createObjectURL(newImage), {
        shouldValidate: true,
      });
      setValue('image', newImage, { shouldValidate: true });
    },
    [setValue]
  );

  const renderUploadedImage = () => {
    if (previewImage) {
      return (
        <img
          src={previewImage}
          className={styles.previewImage}
          alt="Post img"
        />
      );
    }

    if (image) {
      return (
        <EasyImageCrop
          imageFile={image}
          aspect={1}
          onApplyImage={handleApplyImage}
          className={cn(styles.croppedImage, styles.croppedThumbnailImage)}
        />
      );
    }
  };

  const handlePlaceholderClick = () => {
    imageUploadRef.current?.click();
  };

  const handlePreviewImageRemove = () => {
    setValue('image', null);
    setValue('previewImage', null);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setValue('image', acceptedFiles[0], { shouldValidate: true });
    },
    [setValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'image/jpg, image/jpeg, image/png',
  });

  const renderUploadImageDropzone = (
    title = 'Image',
    thumbnailDropzone = false
  ) => {
    return (
      <div
        className={cn(styles.imageContainer, styles.thumbnailImageContainer)}
      >
        <p className={styles.imageLabel}>{title}</p>
        {image || previewImage ? (
          <div
            className={cn(
              styles.previewImageWrapper,
              styles.previewThumbnailImageWrapper
            )}
          >
            {renderUploadedImage()}
            <button
              className={styles.deleteButton}
              onClick={handlePreviewImageRemove}
              aria-label="Delete image"
            >
              <DeleteOutlined />
            </button>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className={cn(styles.dropzone, {
              [styles.thumbnailDropzone]: thumbnailDropzone,
            })}
          >
            <input {...getInputProps()} aria-label="upload image" />
            <div className={styles.dragArea}>
              <UploadImagePlaceholder
                onClick={handlePlaceholderClick}
                title="Drop your images here or browse"
                description="JPG and PNG supported"
                error={!!errors.image?.message}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleFinish = () => {
    onFinish();
    handleReset();
  };

  const handleReset = () => {
    reset();
    onClose();
    setIsLoading(false);
  };

  const isCompletedOrPublished =
    record?.statusV2 === CampaignOfferStatus.COMPLETED ||
    record?.statusV2 === CampaignOfferStatus.PUBLISHED;

  if (record && isCompletedOrPublished) {
    return (
      <Modal
        title="Appearance Details"
        visible={!!record}
        footer={null}
        onCancel={onClose}
        width={900}
      >
        <SubmittedSponsored record={record} />
      </Modal>
    );
  } else {
    return (
      <Modal
        title="Upload media"
        visible={!!record}
        footer={null}
        onCancel={handleReset}
        width={900}
      >
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          autoComplete="off"
          aria-label="upload media"
          className={styles.root}
        >
          <div className={styles.body}>
            <div className={styles.details}>
              <p className={styles.itemTitle}>Details</p>
              <TextArea
                placeholder="Example of title"
                label="Title"
                name="title"
                ref={register}
                error={errors?.title?.message}
                className={cn(styles.textarea, styles.nameTextarea)}
                rows={1}
              />

              <TextArea
                name="description"
                label="Description"
                ref={register}
                placeholder="Add description"
                error={errors?.description?.message}
                className={cn(styles.textarea, styles.nameTextarea)}
                rows={6}
              />

              <p className={styles.itemTitle}>Tags</p>
              <p className={styles.itemSubTitle}>Tagging</p>

              <p className={styles.itemNote}>
                Use hashtags to categorize and increase the visibility of your
                content, making it easier for users to find related topics or
                products.
              </p>

              <div>
                <Controller
                  name="hashtags"
                  control={control}
                  render={({ onChange }) => (
                    <TagsInput
                      hashtags={hashtags || []}
                      onChange={onChange}
                      name="hashtags"
                    />
                  )}
                />
              </div>

              <div className={styles.mentionsSection}>
                <p className={styles.itemTitle}>Mentioning options</p>
              </div>
              <p className={styles.itemNote}>
                Mention athletes, brands, and organizations by using their names
                and links to directly engage them and increase interaction on
                your content. Write down their names and url links.
              </p>
              <Controller
                name="mentions"
                control={control}
                render={({ value: mentions }) => (
                  <Mentions
                    mentions={mentions}
                    onChange={handleMentionChange}
                  />
                )}
              />
            </div>
            <div className={styles.upload}>
              <p className={styles.itemTitle}>Upload Video</p>
              <p className={styles.imageLabel}>Video (Video must be in .mp4)</p>
              <div
                className={cn(styles.videoBlock, {
                  [styles.withVideo]: videoPreviewURL,
                })}
              >
                <VideoPreview
                  video={videoPreviewURL}
                  show={showVideoPreview}
                  isCropVisible={false}
                  videoPreviewNotSupported={videoPreviewNotSupported}
                  wrapperClassName={styles.previewRoot}
                  errorMessage={videoPreviewErrorMessage}
                  deviceSize="onboarding"
                />
                <input
                  name="video"
                  type="file"
                  accept="video/*"
                  hidden
                  onChange={handleFileUpload}
                  ref={uploadVideoRef}
                  aria-label="upload video"
                />
                {!videoPreviewURL && (
                  <button
                    className={styles.videoBlockButton}
                    type="button"
                    onClick={handleUploadVideoButtonClick}
                  >
                    <UploadOutlined />

                    <span className={styles.chooseFileLabel}>Upload video</span>
                  </button>
                )}
                {videoPreviewURL && !showVideoPreview && (
                  <span className={styles.unsupportedLabel}>
                    The video preview is not supported
                  </span>
                )}
              </div>
              <div
                className={cn(styles.progressBar, {
                  [styles.hideProgressBar]: !videoProgressContainer,
                })}
              >
                {videoProgressContainer}
              </div>
              <div className={styles.coverImageWrapper}>
                <h3 className={styles.itemTitle}>Cover image</h3>
                {renderUploadImageDropzone('Cover Image ', true)}
              </div>
            </div>
          </div>

          <div className={styles.submitButton}>
            <Button
              type="submit"
              x-s="default"
              loading={isLoading}
              disabled={isSubmitDisabled}
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>
    );
  }
};

export default SponsoredDeliverables;
