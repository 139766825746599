import moment from 'moment';
// Types
import { VideoEditRequestStatus } from 'api/graphql-global-types';
import { GetVideoEditRequests_getVideoEditRequests_entities } from 'api/videoLab/types/GetVideoEditRequests';
import { CreatePresignedUrls_createPresignedUrls } from 'api/videoLab/types/CreatePresignedUrls';

type ReturnType = {
  value: VideoEditRequestStatus;
  text: string;
};

const SECONDS_IN_HOUR = 3600;

export const getVideoRequestDueDateCountdown = (
  entity: GetVideoEditRequests_getVideoEditRequests_entities
): string => {
  if (entity.dueDate) {
    const isOverdue = moment(entity.dueDate).unix() - moment().unix() < 0;

    if (entity.status === VideoEditRequestStatus.InProgress && isOverdue) {
      return `Overdue by ${Math.round(
        (moment().unix() - moment(entity.createdAt).unix()) / SECONDS_IN_HOUR
      )} hours`;
    }

    if (entity.status === VideoEditRequestStatus.InProgress && !isOverdue) {
      return `Remaining ${Math.round(
        (moment(entity.dueDate).unix() - moment().unix()) / SECONDS_IN_HOUR
      )} hours`;
    }

    if (
      entity.status === VideoEditRequestStatus.Accepted ||
      entity.status === VideoEditRequestStatus.AutoAccepted
    ) {
      return `Completed`;
    }

    return moment(entity.dueDate).local().format('MM/DD/YYYY, h:mm a');
  } else {
    return 'N/A';
  }
};

export const getStatues = (tabKey: string): ReturnType[] => {
  let statuses = [];

  switch (tabKey) {
    case 'isInProgress':
      statuses = [
        { value: VideoEditRequestStatus.InProgress, text: 'In Progress' },
        { value: VideoEditRequestStatus.OnReview, text: 'On Review' },
      ];
      break;
    default:
      statuses = [
        { value: VideoEditRequestStatus.Archived, text: 'Archived' },
        { value: VideoEditRequestStatus.InProgress, text: 'In Progress' },
        { value: VideoEditRequestStatus.OnReview, text: 'On Review' },
        { value: VideoEditRequestStatus.Accepted, text: 'Accepted' },
        { value: VideoEditRequestStatus.AutoAccepted, text: 'Auto-Accepted' },
        { value: VideoEditRequestStatus.Rejected, text: 'Rejected' },
      ];
  }
  return statuses;
};

export const calculateChunkSize = (fileSize: number): number => {
  // <= 100MB ==> chunk 10MB
  if (fileSize <= 1024 * 1024 * 100) {
    return 1024 * 1024 * 10;
  }

  // <= 1GB ==> chunk 100MB
  if (fileSize <= 1024 * 1024 * 1024) {
    return 1024 * 1024 * 100;
  }

  // > 1GB ==> chunk 500MB
  return 1024 * 1024 * 500;
};

export type ImageToUpload = {
  fields: string;
  url: string;
  name: string;
  type: string;
  file: File | '';
};

export const getImageToUpload = (
  preSignedUrl: CreatePresignedUrls_createPresignedUrls,
  image: UploadImage
): ImageToUpload => {
  const { file } = image;

  const imageExtension = file
    ? file.name.split('.')[file.name.split('.').length - 1]
    : '';

  const imageName = `${preSignedUrl?.key || ''}.${imageExtension}`;

  const res: ImageToUpload = {
    fields: preSignedUrl?.fields || '',
    url: preSignedUrl?.url || '',
    name: imageName,
    type: file?.type || '',
    file: file || '',
  };

  return res;
};

export type UploadImage = {
  data_url?: string;
  file?: File;
};

export const uploadImageToS3 = async (
  preSignedUrl: CreatePresignedUrls_createPresignedUrls,
  imageToUpload: UploadImage
): Promise<string> => {
  const {
    fields,
    url,
    name: imageName,
    type,
    file,
  } = getImageToUpload(preSignedUrl, imageToUpload);

  const formData = new FormData();

  Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
    formData.append(key, value as string);
  });

  const parts = imageName.split('.');
  const newImageName = parts.slice(0, -1).join('.');

  formData.append('key', newImageName);
  formData.append('Content-Type', type);
  formData.append('file', file);

  await fetch(url, {
    method: 'POST',
    body: formData,
  });

  return newImageName;
};
