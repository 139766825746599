import React, { useState } from 'react';
// Types
import { FormInstance } from 'antd/es/form';
import {
  PromoCodesChargeSubject,
  ProductTypesType,
} from 'api/graphql-global-types';
// Helpers
import { formItemRules } from './form-item-rules';
import { amountFormatter, amountParser } from './discont-amount-helpers';
// Constants
import { TIME_ZONE_OPTIONS } from 'constants/timeZone';
// UI
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Switch,
} from 'antd';
// Components
import FormItemLabel from '../FormItemLabel/FormItemLabel';
import StoreDataSelect from '../StoreDataSelect/StoreDataSelect';
import SiteWideData from '../SiteWideData/SiteWideData';

import './styles.scss';

const { RangePicker } = DatePicker;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
};

const DATEPICKER_DATE_FORMAT = 'MMMM D, YYYY';

// TODO: delete filter when Shoutout feature will be turned on
const initialSiteWideOptions = Object.values(ProductTypesType).filter(
  (item) => item !== ProductTypesType.Shoutout
);

const initialFormValues = {
  name: null,
  startDate: null,
  validityPeriod: [null, null],
  timeZone: TIME_ZONE_OPTIONS[0].tzCode,
  amount: null,
  usageLimit: null,
  perUserLimit: null,
  chargeSubject: PromoCodesChargeSubject.Store,
  isSiteWide: true,
  storeProducts: [],
  siteWideOptions: initialSiteWideOptions,
};

const chargeSubjectOptions = [
  {
    label: 'Store',
    value: PromoCodesChargeSubject.Store,
  },
  {
    label: 'Platform',
    value: PromoCodesChargeSubject.Platform,
  },
  {
    label: 'Internal',
    value: PromoCodesChargeSubject.Internal,
  },
];

export type CreatePromoCodeFormProps = {
  formInstance?: FormInstance;
};

const CreatePromoCodeForm: React.FC<CreatePromoCodeFormProps> = ({
  formInstance,
}) => {
  const [formLocal] = Form.useForm();

  const form = formInstance || formLocal;
  const [chargingOption, setChargingOption] = useState(
    PromoCodesChargeSubject.Store
  );
  const chargeTooltipTitle =
    chargingOption === PromoCodesChargeSubject.Internal
      ? 'If Internal is selected, no revenues or profit is recorded for Platform and Store'
      : "If Store selected the system will charge the store's profit for the discount. If chosen Platform, then the system will charge the platform's margin for the discount.";

  const [isSiteWide, setIsSiteWide] = useState<boolean>(
    initialFormValues.isSiteWide
  );
  const [timeLimited, setTimeLimited] = useState<boolean>(false);
  const [codeUsageLimited, setCodeUsageLimited] = useState<boolean>(false);
  const [perUserUsageLimited, setPerUserUsageLimited] =
    useState<boolean>(false);

  const handleTimeLimitedSwitchOnChange = (value: boolean): void => {
    setTimeLimited(value);
    form.resetFields(['startDate', 'validityPeriod']);
  };

  const handleCodeUsageLimitedCheckboxOnChange = (): void => {
    setCodeUsageLimited(!codeUsageLimited);
    form.resetFields(['limitations']);
  };

  const handlePerUserUsageLimitedCheckboxOnChange = (): void => {
    setPerUserUsageLimited(!perUserUsageLimited);
    form.resetFields(['usagePerUser']);
  };

  const handleSitewideSwitchOnChange = (value: boolean): void => {
    setIsSiteWide(value);
  };

  const handleChargeSubjectChange = (e: RadioChangeEvent) => {
    const selectedValue = e.target.value;
    setChargingOption(selectedValue);

    if (selectedValue === PromoCodesChargeSubject.Internal) {
      form.setFieldsValue({ amount: 100 });
      form.setFieldsValue({ siteWideOptions: 'Stream' });
    } else {
      form.setFieldsValue({ amount: null });
    }
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      name="CreatePromoCodeForm"
      initialValues={initialFormValues}
      autoComplete="off"
      validateTrigger="onSubmit"
      {...formItemLayout}
    >
      <Form.Item
        name="name"
        label={<FormItemLabel labelText="Name" />}
        rules={formItemRules.name}
      >
        <Input className="promo-code-form__input-medium" />
      </Form.Item>

      <Form.Item label={<FormItemLabel labelText="Time-limited" />}>
        <Switch
          onChange={handleTimeLimitedSwitchOnChange}
          checked={timeLimited}
        />
      </Form.Item>

      {timeLimited ? (
        <Form.Item
          name="validityPeriod"
          label={<FormItemLabel labelText="Validity Period" />}
          rules={formItemRules.validityPeriod}
        >
          <RangePicker />
        </Form.Item>
      ) : (
        <Form.Item
          name="startDate"
          label={<FormItemLabel labelText="Start date" />}
          rules={formItemRules.startDate}
        >
          <DatePicker
            className="promo-code-form__input-medium"
            format={DATEPICKER_DATE_FORMAT}
          />
        </Form.Item>
      )}

      <Form.Item
        name="timeZone"
        label={<FormItemLabel labelText="Time Zone" />}
        rules={formItemRules.timeZone}
      >
        <Select
          showSearch
          placeholder="Select time zone"
          optionFilterProp="children"
          className="promo-code-form__input-medium"
        >
          {TIME_ZONE_OPTIONS.map(({ tzCode, label }) => (
            <Option key={tzCode} value={tzCode}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="amount"
        label={
          <FormItemLabel
            labelText="Amount (%)"
            tooltipTitle="The amount of the discount in %. Can't be less than 0 and more than 100."
          />
        }
        rules={formItemRules.amount}
      >
        <InputNumber
          min={0}
          max={100}
          value={chargingOption === PromoCodesChargeSubject.Internal ? 100 : 0}
          className="promo-code-form__input-small"
          formatter={amountFormatter}
          parser={amountParser}
          disabled={chargingOption === PromoCodesChargeSubject.Internal}
        />
      </Form.Item>

      <Form.Item
        name="usageLimit"
        label={
          <FormItemLabel
            labelText="Limitations"
            tooltipTitle="Enter the limitation for the code."
          />
        }
        rules={formItemRules.limitations(codeUsageLimited)}
      >
        <Space size="small" align="start">
          <InputNumber
            min={1}
            className="promo-code-form__input-small"
            disabled={!codeUsageLimited}
          />
          <Checkbox
            onChange={handleCodeUsageLimitedCheckboxOnChange}
            checked={codeUsageLimited}
          >
            Set limit
          </Checkbox>
        </Space>
      </Form.Item>

      <Form.Item
        name="perUserLimit"
        label={
          <FormItemLabel
            labelText="Usage per user"
            tooltipTitle="Enter the usage per one user."
          />
        }
        rules={formItemRules.usagePerUser(perUserUsageLimited)}
      >
        <Space size="small" align="start">
          <InputNumber
            min={1}
            className="promo-code-form__input-small"
            disabled={!perUserUsageLimited}
          />
          <Checkbox
            onChange={handlePerUserUsageLimitedCheckboxOnChange}
            checked={perUserUsageLimited}
          >
            Set limit
          </Checkbox>
        </Space>
      </Form.Item>

      <Form.Item
        name="chargeSubject"
        label={
          <FormItemLabel
            labelText="Charging"
            tooltipTitle={chargeTooltipTitle}
          />
        }
        rules={formItemRules.chargeSubject}
      >
        <Radio.Group
          options={chargeSubjectOptions}
          onChange={handleChargeSubjectChange}
        />
      </Form.Item>

      <Form.Item
        name="isSiteWide"
        valuePropName="checked"
        label={<FormItemLabel labelText="Site Wide" />}
      >
        <Switch
          onChange={handleSitewideSwitchOnChange}
          defaultChecked={isSiteWide}
        />
      </Form.Item>

      {isSiteWide ? (
        <SiteWideData
          rules={formItemRules.siteWideData(isSiteWide)}
          form={form}
        />
      ) : null}
      {!isSiteWide ? <StoreDataSelect /> : null}
    </Form>
  );
};

export default CreatePromoCodeForm;
