import React from 'react';
import { Button, Dropdown, Menu, Space } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { isEqual, uniq, sortBy } from 'lodash';
// Types
import {
  DesignRequestType,
  DesignRequestStatus,
  StoreStatus,
  UserRole,
} from 'api/graphql-global-types';
import { DesignRequestsFormattedData } from 'helpers/designRequests';
import { DesignRequests_designRequests_entities_store } from 'api/designLab/types/DesignRequests';
// Components
import AssignDesigner from '../AssignDesigner/AssignDesigner';
import ManageDesignRequestResult from '../ManageDesignRequestResult/ManageDesignRequestResult';
import ArchiveDesignAction from '../ArchiveDesign/ArchiveDesign';

type DesignRequestsActionsProps = {
  request: DesignRequestsFormattedData;
  designerId?: string;
  store?: DesignRequests_designRequests_entities_store | null;
  handleShowModal?: (request: DesignRequestsFormattedData) => void;
};

const DesignRequestsActions = ({
  request,
  designerId,
  store,
  handleShowModal,
}: DesignRequestsActionsProps): JSX.Element | null => {
  const { id: designRequestId, status, type } = request;

  const merchItems = request.designCreationDetails?.merchItems || [];
  const uniqueMerchTypes = uniq(merchItems.map((item) => item.merch?.type));
  const submitDesignIsAvailable = isEqual(
    sortBy(request.designCreationDetails?.merchTypes || []),
    sortBy(uniqueMerchTypes)
  );

  const isMerchCreationAvailable =
    status === DesignRequestStatus.InProgress &&
    type === DesignRequestType.MerchCreation;

  const isMerchCreationWithLogoAvailable =
    status === DesignRequestStatus.InProgress &&
    type === DesignRequestType.MerchCreationWithLogo;

  const updatedRole =
    store?.role === UserRole.ContentCreator
      ? 'content-creator'
      : store?.role === UserRole.Organization
      ? 'organization'
      : 'athlete';

  // this will eliminate the merch types that are already created in previous session (if there was one)
  const designMerchTypes = request.designCreationDetails?.merchTypes.filter(
    (merchType) => {
      return !merchItems.some((item) => {
        return item.merch?.type === merchType;
      });
    }
  );

  const designerMenuItems = (
    <Space align="center" direction="vertical">
      <Button
        type="primary"
        onClick={() => handleShowModal && handleShowModal(request)}
        disabled={!submitDesignIsAvailable}
      >
        Submit design
      </Button>
      {!submitDesignIsAvailable && (
        <Link
          to={`/manage-${updatedRole}-merch/${designMerchTypes?.[0]}/${store?.id}?designRequestId=${request.id}&merchTypes=${designMerchTypes}`}
        >
          <Space direction="vertical" align="center">
            Design merch
          </Space>
        </Link>
      )}
    </Space>
  );

  // when store is not approved and design is in TODO column, is should show no actions
  const isNotAssignable =
    store &&
    store.status !== StoreStatus.Active &&
    status === DesignRequestStatus.Todo;

  const getAdminMenuItem = (): JSX.Element | null => {
    switch (status) {
      case DesignRequestStatus.Archived:
        return (
          <Menu.Item key={request.id + 'Archived'}>
            <Space direction="vertical" size="middle" align="center">
              <ManageDesignRequestResult request={request} isAwaitingApproval />
              <AssignDesigner designRequestId={designRequestId} />
            </Space>
          </Menu.Item>
        );

      case DesignRequestStatus.Todo:
        return (
          <Menu.Item key={request.id + 'todo'}>
            <AssignDesigner designRequestId={designRequestId} />
          </Menu.Item>
        );

      case DesignRequestStatus.OnReview:
        return (
          <Menu.Item key={request.id + 'OnReview'}>
            <Space direction="vertical" size="middle" align="center">
              <ArchiveDesignAction designRequestId={designRequestId} />
              <ManageDesignRequestResult request={request} />
            </Space>
          </Menu.Item>
        );

      case DesignRequestStatus.InProgress:
        return (
          <Menu.Item key={request.id + 'OnReview'}>
            <Space direction="vertical" size="middle" align="center">
              <ArchiveDesignAction designRequestId={designRequestId} />
              <AssignDesigner designRequestId={designRequestId} />
            </Space>
          </Menu.Item>
        );

      case DesignRequestStatus.AwaitingApproval:
        return (
          <Menu.Item key={request.id + 'AwaitingApproval'}>
            <Space direction="vertical" size="middle" align="center">
              <ArchiveDesignAction designRequestId={designRequestId} />
              <ManageDesignRequestResult request={request} isAwaitingApproval />
            </Space>
          </Menu.Item>
        );

      case DesignRequestStatus.Rejected:
        return (
          <Menu.Item key={request.id + 'Rejected'}>
            <Space direction="vertical" size="middle" align="center">
              <ArchiveDesignAction designRequestId={designRequestId} />
              <AssignDesigner
                designRequestId={designRequestId}
                buttonTitle="Re-assign"
                buttonType="default"
              />
            </Space>
          </Menu.Item>
        );
      case DesignRequestStatus.AutoAccepted:
      case DesignRequestStatus.Accepted:
        return (
          <Menu.Item key={request.id + 'Accepted'}>
            <AssignDesigner
              designRequestId={designRequestId}
              buttonTitle="Re-assign"
              buttonType="default"
            />
          </Menu.Item>
        );
      default:
        return null;
    }
  };

  const showDesignerMenu =
    designerId &&
    (isMerchCreationAvailable || isMerchCreationWithLogoAvailable);

  const showAdminMenu = !designerId && !isNotAssignable;
  const menu = (
    <Menu>
      {showDesignerMenu
        ? designerMenuItems
        : showAdminMenu
        ? getAdminMenuItem()
        : null}
    </Menu>
  );

  if (!showDesignerMenu && !showAdminMenu) {
    return null;
  }

  return (
    <Dropdown trigger={['click']} placement="bottomCenter" overlay={menu}>
      <Button
        aria-label="show the available actions"
        icon={<EllipsisOutlined />}
      />
    </Dropdown>
  );
};

export default DesignRequestsActions;
