import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
  QueryFunctionOptions,
} from '@apollo/client';
// Api
import { GET_ALL_COUNTRIES_V2 } from 'api/locations/queries';
// Types
import { GetAllCountriesV2 } from 'api/locations/types/GetAllCountriesV2';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
} & QueryFunctionOptions;

type UseGetCountriesV2Options = {
  options?: BasicQueryOptions;
};

export const useGetCountriesV2 = (
  options?: UseGetCountriesV2Options
): QueryResult<GetAllCountriesV2> => {
  const countries = useQuery<GetAllCountriesV2>(GET_ALL_COUNTRIES_V2, {
    ...options?.options,
    fetchPolicy: 'cache-and-network',
  });

  return countries;
};
