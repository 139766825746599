import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { Modal, Form, Typography, Switch, AutoComplete, Select } from 'antd';
// Api
import { SET_INTERVIEWER_CAPABILITY } from 'api/interviewer/mutations';
import { GET_STORE_INTERVIEWERS } from 'api/interviewer/queries';
import { GET_STORES } from 'api/store/queries';
// Hooks
import { useGetSports } from 'hooks';
// Types
import { AutoCompleteOption } from 'pages/Interviews/Admin/components/SendInviteModal/SendInviteModal';
import {
  AdminStoresOrderBy,
  StoreInterviewerOrderBy,
  UserRole,
} from 'api/graphql-global-types';
import {
  SetInterviewerCapability,
  SetInterviewerCapabilityVariables,
} from 'api/interviewer/types/SetInterviewerCapability';
import { GetStoreInterviewers_getStoreInterviewers_entities } from 'api/interviewer/types/GetStoreInterviewers';
import { GetStores, GetStoresVariables } from 'api/store/types/GetStores';
// UI
import { errorNotification, successNotification } from 'ui/Notification';
import Tip from 'ui/Tip';
// Styles
import styles from './CreateEditInterviewModal.module.scss';

const { Text } = Typography;
const { Option } = Select;

type CreateEditInterviewerModalProps = {
  onClose: () => void;
  isVisible: boolean;
  interviewer?: GetStoreInterviewers_getStoreInterviewers_entities;
  autoAssignDisabled: boolean;
};

const CreateEditInterviewerModal = ({
  onClose,
  isVisible,
  interviewer,
  autoAssignDisabled,
}: CreateEditInterviewerModalProps): JSX.Element => {
  const [form] = Form.useForm();
  const { data: sportsData } = useGetSports();
  const sports = sportsData?.sports || [];

  const [storeOptions, setStoreOptions] = useState<AutoCompleteOption[]>([]);
  const [selectedStoreOption, setSelectedStoreOption] =
    useState<AutoCompleteOption | null>(null);

  const [isIncludedInAutoAssignment, setIsIncludedInAutoAssignment] =
    useState<boolean>(
      Boolean(interviewer?.interviewerDetails?.isIncludedInAutoAssignment)
    );

  const handleAutoAssignChange = (checked: boolean) => {
    setIsIncludedInAutoAssignment(checked);
  };

  const [setInterviewerCapability, { loading }] = useMutation<
    SetInterviewerCapability,
    SetInterviewerCapabilityVariables
  >(SET_INTERVIEWER_CAPABILITY, {
    refetchQueries: [
      {
        query: GET_STORE_INTERVIEWERS,
        variables: {
          input: {
            limit: 100,
            offset: 0,
            orderBy: StoreInterviewerOrderBy.createdAt,
          },
        },
      },
    ],
  });

  const handleSubmit = async () => {
    const values = form.getFieldsValue();

    try {
      await setInterviewerCapability({
        variables: {
          input: {
            storeId: interviewer
              ? interviewer.id
              : (selectedStoreOption?.value as string),
            isIncludedInAutoAssignment,
            sportIds: values?.sportType?.map(Number),
          },
        },
      });

      successNotification(
        'Interviewer capability has been updated successfully'
      );
      form.resetFields();
      setSelectedStoreOption(null);
      setIsIncludedInAutoAssignment(false);
      onClose();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  const onFormSubmit = async () => {
    try {
      await form.validateFields();
      return handleSubmit();
    } catch (err) {
      errorNotification((err as Error)?.message || 'Something went wrong');
    }
  };

  const { data: storesData, refetch } = useQuery<GetStores, GetStoresVariables>(
    GET_STORES,
    {
      variables: {
        input: {
          orderBy: AdminStoresOrderBy.storeName,
        },
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
      },
    }
  );

  const handleSelectStore = (
    value: string,
    option: AutoCompleteOption
  ): void => {
    setSelectedStoreOption(option);
  };

  const handleOnClearStore = () => {
    setSelectedStoreOption(null);
  };

  const handleOnSearchStores = useMemo(() => {
    const loadOptions = (storeName: string) => {
      refetch({
        storeRoles: [
          UserRole.Athlete,
          UserRole.Organization,
          UserRole.ContentCreator,
        ],
        input: { storeName, orderBy: AdminStoresOrderBy.storeName },
      });
    };

    return debounce(loadOptions, 400);
  }, [refetch]);

  useEffect(() => {
    if (storesData?.adminStores.entities) {
      setStoreOptions(
        storesData.adminStores.entities.map((store) => ({
          value: store.id,
          label: store.storeDetails?.storeName
            ? store.storeDetails.storeName
            : `${store.firstName} ${store.lastName}`,
        }))
      );
    }
  }, [storesData]);

  useEffect(() => {
    if (interviewer) {
      form.setFieldsValue({
        isIncludedInAutoAssignment:
          interviewer.interviewerDetails?.isIncludedInAutoAssignment,
        sportType: interviewer.interviewerDetails?.sports?.map((sport) =>
          sport.id.toString()
        ),
      });
    }
  }, [interviewer, form]);

  return (
    <Modal
      title={`${interviewer ? 'Edit' : 'Create new'} Interviewer`}
      destroyOnClose
      visible={isVisible}
      okText="Save"
      cancelText="Cancel"
      onOk={onFormSubmit}
      onCancel={onClose}
      okButtonProps={{
        loading,
      }}
    >
      {!interviewer && (
        <div className={styles.storeSection}>
          <Text className={styles.accountText}>Account:</Text>
          <AutoComplete
            id="store-search"
            allowClear
            className={styles.autoComplete}
            placeholder="Search Account Name"
            options={storeOptions}
            value={selectedStoreOption?.label}
            onSearch={handleOnSearchStores}
            onSelect={handleSelectStore}
            onClear={handleOnClearStore}
          />

          {selectedStoreOption && (
            <Text className={styles.text}>
              Selected Account: {selectedStoreOption?.label}
            </Text>
          )}
        </div>
      )}

      <Form
        form={form}
        layout="horizontal"
        name="createEditInterviewerForm"
        autoComplete="off"
      >
        <Form.Item
          name="isIncludedInAutoAssignment"
          label={
            <Text>
              Auto Assign
              <Tip title="Enable auto assignment for interviewer. Schedule has to be set" />
            </Text>
          }
        >
          <Switch
            onChange={handleAutoAssignChange}
            disabled={autoAssignDisabled}
            checked={isIncludedInAutoAssignment}
          />
        </Form.Item>

        <Form.Item name="sportType" label="Specific sports">
          <Select
            id="sportType"
            placeholder="Select sports"
            mode="multiple"
            filterOption={(inputValue, option) =>
              option?.props.children
                .toString()
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
          >
            {sports.map((item) => {
              return (
                <Option value={`${item.id}`} key={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateEditInterviewerModal;
