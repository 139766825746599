import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Modal, Rate } from 'antd';
// Api
import { GET_VIDEO_EDIT_REQUESTS_FOR_EDITORS } from 'api/videoLab/queries';
// Types
import {
  GetVideoEditRequests,
  GetVideoEditRequests_getVideoEditRequests_entities,
  GetVideoEditRequestsVariables,
} from 'api/videoLab/types/GetVideoEditRequests';
import {
  SortDirection,
  VideoEditRequestStatus,
  VideoEditRequestsOrderBy,
} from 'api/graphql-global-types';
// UI
import Table, { Column } from 'ui/Table';
import { SortedInfo, TableFilter } from 'ui/Table';
// Components
import VideoEditingRequestsActions from 'pages/VideoEditingRequest/Admin/components/VideoEditingRequestsActions/VideoEditingRequestsActions';
import MyVideoEditRequestDetailsModal from '../MyVideoEditRequestDetailsModal/MyVideoEditRequestDetailsModal';
import CommentsModal from '../CommentsModal/CommentsModal';
// Columns
import {
  dueDateColumn,
  requestDateColumn,
  requestTypeColumn,
  storeNameColumn,
} from 'pages/VideoEditingRequest/common/Columns';
// Styles
import styles from './MyVideoEditRequestTable.module.scss';

const statuses = [
  { value: VideoEditRequestStatus.Archived, text: 'Archived' },
  { value: VideoEditRequestStatus.InProgress, text: 'In Progress' },
  { value: VideoEditRequestStatus.OnReview, text: 'On Review' },
  { value: VideoEditRequestStatus.Accepted, text: 'Accepted' },
  { value: VideoEditRequestStatus.AutoAccepted, text: 'Auto-Accepted' },
  { value: VideoEditRequestStatus.Rejected, text: 'Rejected' },
];

type MyVideoEditRequestTableProps = {
  videoEditorId?: string;
};

const MyVideoEditRequestTable: React.FC<MyVideoEditRequestTableProps> = ({
  videoEditorId,
}) => {
  const [isDetailsModalVisible, setIsDetailsModalVisible] =
    useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isCommentsModalVisible, setIsCommentsModalVisible] =
    useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] =
    useState<GetVideoEditRequests_getVideoEditRequests_entities | null>(null);

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<VideoEditRequestsOrderBy>>({
    order: SortDirection.ASC,
    key: VideoEditRequestsOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const showDetailsModal = (
    record: GetVideoEditRequests_getVideoEditRequests_entities | null
  ) => {
    setSelectedRecord(record);
    setIsDetailsModalVisible(true);
  };

  const hideDetailsModal = () => {
    setIsDetailsModalVisible(false);
    setSelectedRecord(null);
  };

  const showEditModal = (
    record: GetVideoEditRequests_getVideoEditRequests_entities | null
  ) => {
    setSelectedRecord(record);
    setIsEditModalVisible(true);
  };

  const hideEditModal = () => {
    setIsEditModalVisible(false);
    setSelectedRecord(null);
  };
  const getVideoRequestsInput = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      videoEditorIds: [videoEditorId || ''],
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    const { status, ...rest } = input;

    return {
      input: {
        ...rest,
        ...(status && { videoEditRequestStatuses: status }),
      },
    };
  };

  const { data, loading } = useQuery<
    GetVideoEditRequests,
    GetVideoEditRequestsVariables
  >(GET_VIDEO_EDIT_REQUESTS_FOR_EDITORS, {
    variables: { ...getVideoRequestsInput() },
    fetchPolicy: 'network-only', // cache-and-network didn't render any data
  });

  const handleOnShowComments = (
    record: GetVideoEditRequests_getVideoEditRequests_entities
  ) => {
    setSelectedRecord(record);
    setIsCommentsModalVisible(true);
  };

  const handleOnCloseComments = () => {
    setIsCommentsModalVisible(false);
    setSelectedRecord(null);
  };

  const requestIdColumn = {
    title: 'Task ID',
    dataIndex: 'id',
    key: 'id',
    align: 'left' as const,
    fixed: 'left' as const,
    width: 120,
    render: function VideoEditorIdWrap(
      id: string,
      record: GetVideoEditRequests_getVideoEditRequests_entities
    ) {
      return (
        <span
          onKeyDown={() => showDetailsModal(record)}
          onClick={() => showDetailsModal(record)}
          className={styles.detailsModalLink}
          role="button"
          tabIndex={0}
        >
          {record.id}
        </span>
      );
    },
  };

  const assignedEditorRate = {
    title: 'Rate',
    key: 'rate',
    dataIndex: 'rate',
    align: 'center' as const,
    width: 200,
    render: function RateWrap(rate: number | null) {
      return <Rate value={rate || 0} disabled allowHalf count={5} />;
    },
  };

  const statusColumn = {
    title: 'Status',
    dataIndex: VideoEditRequestsOrderBy.status,
    key: VideoEditRequestsOrderBy.status,
    filters: statuses,
    filterMultiple: true,
    withCheckboxFilters: true,
    align: 'center' as const,
    width: 130,
  };

  const commentsColumn = {
    title: 'Comments',
    dataIndex: 'comments',
    key: 'comments',
    align: 'center' as const,
    width: 200,
    render: function CommentsWrap(
      _: any,
      record: GetVideoEditRequests_getVideoEditRequests_entities
    ) {
      return record.comments?.length && record.comments?.length > 0 ? (
        <Button type="link" onClick={() => handleOnShowComments(record)}>
          Show Comments
        </Button>
      ) : (
        '-'
      );
    },
  };

  const actionColumn = {
    title: 'Action',
    dataIndex: 'id',
    fixed: 'right' as const,
    align: 'center' as const,
    width: 90,
    render: function ActionsWrap(
      id: string,
      record: GetVideoEditRequests_getVideoEditRequests_entities
    ) {
      return (
        <VideoEditingRequestsActions
          request={record}
          store={record?.store}
          isVideoEditor
          handleShowEditModal={() => showEditModal(record)}
        />
      );
    },
  };

  const adminColumns: Column<
    GetVideoEditRequests_getVideoEditRequests_entities,
    VideoEditRequestsOrderBy
  >[] = [
    requestIdColumn,
    storeNameColumn,
    requestDateColumn,
    dueDateColumn,
    requestTypeColumn,
    assignedEditorRate,
    statusColumn,
    commentsColumn,
    actionColumn,
  ];

  return (
    <>
      <Table<
        GetVideoEditRequests_getVideoEditRequests_entities,
        VideoEditRequestsOrderBy
      >
        columns={adminColumns}
        data={data?.getVideoEditRequests.entities}
        scroll={{ x: 1280 }}
        loading={loading}
        total={data?.getVideoEditRequests?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
      />

      <Modal
        title="Video editing request details"
        visible={!!selectedRecord && isDetailsModalVisible}
        destroyOnClose
        width={800}
        onCancel={hideDetailsModal}
        footer={null}
      >
        <MyVideoEditRequestDetailsModal
          videoEditRequest={selectedRecord}
          hideDetailsModal={hideDetailsModal}
          isViewOnly
          getVideoRequestsInput={getVideoRequestsInput}
        />
      </Modal>

      <Modal
        title="Video editing request details"
        visible={!!selectedRecord && isEditModalVisible}
        destroyOnClose
        width={800}
        onCancel={hideEditModal}
        footer={null}
      >
        <MyVideoEditRequestDetailsModal
          videoEditRequest={selectedRecord}
          hideDetailsModal={hideEditModal}
          isViewOnly={
            selectedRecord?.status === VideoEditRequestStatus.Accepted
          }
          getVideoRequestsInput={getVideoRequestsInput}
        />
      </Modal>

      <CommentsModal
        show={isCommentsModalVisible}
        data={selectedRecord?.comments || []}
        onClose={handleOnCloseComments}
      />
    </>
  );
};

export default MyVideoEditRequestTable;
