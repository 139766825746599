import React, { useState } from 'react';
import { Modal, Typography } from 'antd';
import { useQuery } from '@apollo/client';
// Api
import { GET_VIDEO_EDIT_REQUESTS_MINIMAL } from 'api/videoLab/queries';
// Types
import {
  GetVideoEditRequestsMinimal,
  GetVideoEditRequestsMinimalVariables,
} from 'api/videoLab/types/GetVideoEditRequestsMinimal';
import {
  SortDirection,
  VideoEditRequestsOrderBy,
  VideoEditRequestStatus,
} from 'api/graphql-global-types';
// UI
import Table, { SortedInfo, TableFilter } from 'ui/Table';

const { Text } = Typography;

type RequestsProps = {
  videoEditorId: string;
  videoEditorName: string;
  show: boolean;
  onClose: () => void;
};

type VideoEditorRequestsTableData = {
  id: string;
  storeName: string;
  status: VideoEditRequestStatus;
};

const RequestStatusTitle = {
  [VideoEditRequestStatus.Accepted]: 'Accepted',
  [VideoEditRequestStatus.AutoAccepted]: 'Auto-Accepted',
  [VideoEditRequestStatus.Archived]: 'Archived',
  [VideoEditRequestStatus.PartialyRejected]: 'Partially rejected',
  [VideoEditRequestStatus.InProgress]: 'In progress',
  [VideoEditRequestStatus.OnReview]: 'On review',
  [VideoEditRequestStatus.Rejected]: 'Rejected',
  [VideoEditRequestStatus.Todo]: 'Todo',
};

const Requests: React.FC<RequestsProps> = ({
  show,
  onClose,
  videoEditorId,
  videoEditorName,
}) => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<VideoEditRequestsOrderBy>>({
    order: SortDirection.DESC,
    key: VideoEditRequestsOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const getVideoRequestsInput = () => {
    const input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
      videoEditorIds: [videoEditorId || ''],
    };

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });
    return {
      input,
    };
  };

  const { data, loading } = useQuery<
    GetVideoEditRequestsMinimal,
    GetVideoEditRequestsMinimalVariables
  >(GET_VIDEO_EDIT_REQUESTS_MINIMAL, {
    variables: {
      ...getVideoRequestsInput(),
    },
    fetchPolicy: 'cache-and-network',
  });

  const getFormattedData = (): VideoEditorRequestsTableData[] => {
    return (
      data?.getVideoEditRequests?.entities?.map((videoEditRequest) => {
        return {
          id: videoEditRequest?.id,
          storeName: `${
            videoEditRequest?.store?.storeDetails?.storeName || ''
          }`,
          status: videoEditRequest?.status || '',
        };
      }) || []
    );
  };

  const columns = [
    {
      title: 'Request id',
      dataIndex: 'id',
      align: 'left' as const,
      width: 120,
    },
    {
      title: 'Store Name',
      dataIndex: 'storeName',
      key: 'storeName',
      align: 'left' as const,
      width: 100,
      withSearch: true,
    },
    {
      title: 'Request status',
      dataIndex: 'status',
      align: 'left' as const,
      width: 180,
      render: function RequestsStatus(status: VideoEditRequestStatus) {
        return <Text>{RequestStatusTitle?.[status] || status}</Text>;
      },
    },
  ];

  return (
    <Modal
      title={
        <>
          <strong>{videoEditorName}</strong> video editor requests
        </>
      }
      visible={show}
      footer={null}
      onCancel={onClose}
      width="70vw"
    >
      <Table<VideoEditorRequestsTableData, VideoEditRequestsOrderBy>
        columns={columns}
        data={getFormattedData()}
        total={data?.getVideoEditRequests?.total}
        defaultPageSize={pageSize}
        defaultCurrent={currentPage}
        setPageSize={setPageSize}
        setCurrentPage={setCurrentPage}
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
        sortInfo={sort}
        setSortInfo={setSortInfo}
        loading={loading}
      />
    </Modal>
  );
};

export default Requests;
