import React, { useEffect, useState } from 'react';
import { Tree, Form } from 'antd';
import { Rule, FormInstance } from 'antd/es/form';
// Api
import { PromoCodesChargeSubject } from 'api/graphql-global-types';
// Components
import FormItemLabel from '../FormItemLabel/FormItemLabel';

export type SiteWideDataProps = {
  rules: Rule[] | undefined;
  form: FormInstance;
};

const SiteWideData: React.FC<SiteWideDataProps> = ({ rules, form }) => {
  const [checkedKeys, setCheckedKeys] = useState<React.ReactText[]>([
    'Merch',
    'Ama',
    'Stream',
  ]);

  const isInternalCharging =
    form.getFieldValue('chargeSubject') === PromoCodesChargeSubject.Internal;

  useEffect(() => {
    setCheckedKeys(
      isInternalCharging ? ['Stream'] : ['Merch', 'Ama', 'Stream']
    );
  }, [form, isInternalCharging]);

  const treeData = [
    {
      title: 'Merch Products',
      key: 'Merch',
      disabled: isInternalCharging,
    },
    // TODO: add 'AMAs' back and hide 'Ama' when Shoutout feature will be turned on
    // {
    //   title: 'AMAs',
    //   key: 'Amas',
    //   children: [
    //     {
    //       title: 'Ama',
    //       key: 'Ama',
    //     },
    //     {
    //       title: 'Shoutout',
    //       key: 'Shoutout',
    //     },
    //   ],
    // },
    {
      title: 'Ama',
      key: 'Ama',
      disabled: isInternalCharging,
    },
    {
      title: 'Stream',
      key: 'Stream',
    },
  ];

  const onCheck = (
    checked:
      | React.ReactText[]
      | { checked: React.ReactText[]; halfChecked: React.ReactText[] }
  ): void => {
    const filteredItems = Array.isArray(checked)
      ? checked.filter((item: React.ReactText) => item !== 'Amas')
      : checked.checked.filter((item: React.ReactText) => item !== 'Amas');

    setCheckedKeys(filteredItems);

    form.setFieldsValue({
      siteWideOptions: filteredItems,
    });
  };

  return (
    <Form.Item
      name="siteWideOptions"
      label={<FormItemLabel labelText="Products List" />}
      rules={rules}
    >
      <Tree
        checkable
        defaultExpandAll
        selectable={false}
        // TODO: add 'Shoutout' back in defaultCheckedKeys when Shoutout feature will be turned on
        checkedKeys={checkedKeys}
        onCheck={onCheck}
        treeData={treeData}
      />
    </Form.Item>
  );
};

export default SiteWideData;
