import React, { useEffect } from 'react';
import Input from 'uiShared/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { DeleteOutlined } from '@ant-design/icons';
import * as yup from 'yup';
// Types
import { ContactType } from 'api/graphql-global-types';
// Components
import PhoneNumberInput from 'components/common/PhoneInput/PhoneNumberInput';
// UIShared
import { Mention } from 'uiShared/Mentions/Mentions';
// Styles
import styles from './UnregisteredMentionsSelect.module.scss';

type UnregisteredMentionsSelectProps = {
  mention: Mention | FormValues | null;
  index: number;
  type: ContactType;
  onChange: (value: FormValues, index: number) => void;
  handleMillionsMentionRemove: (index: number) => void;
};

type FormValues = {
  name: string;
  email: string;
  phoneNumberE164?: string;
  url?: string;
  type: ContactType;
  socialMediaLink?: string;
};

const getValidationSchema = (type: ContactType) => {
  if (type === ContactType.ATHLETE) {
    return yup.object().shape({
      name: yup
        .string()
        .max(140, 'Max 140 characters')
        .required('You need to provide the name'),
      email: yup.string().max(1000, 'Max 1000 characters'),
      phoneNumberE164: yup.string().nullable(),
      url: yup.string().nullable(),
      socialMediaLink: yup.string().nullable(),
    });
  } else if (type === ContactType.BRAND) {
    return yup.object().shape({
      name: yup
        .string()
        .max(140, 'Max 140 characters')
        .required('You need to provide the name'),
      email: yup.string().nullable(),
      phoneNumberE164: yup.string().nullable(),
      url: yup.string(),
    });
  }
  return yup.object().shape({});
};

export const UnregisteredMentionsSelect = ({
  mention,
  onChange,
  type,
  index,
  handleMillionsMentionRemove,
}: UnregisteredMentionsSelectProps): JSX.Element => {
  const isEditOption = !!(mention as Mention)?.id;

  const defaultValues = isEditOption
    ? {
        name: (mention as Mention)?.unregisteredContact?.name || '',
        email: (mention as Mention)?.unregisteredContact?.email || '',
        phoneNumberE164:
          (mention as Mention)?.unregisteredContact?.phoneNumber || '',
        url: (mention as Mention)?.unregisteredContact?.url || '',
        socialMediaLink:
          (mention as Mention)?.unregisteredContact?.socialMediaLink || '',
      }
    : {
        name: (mention as FormValues)?.name || '',
        email: (mention as FormValues)?.email || '',
        phoneNumberE164: (mention as FormValues)?.phoneNumberE164 || '',
        url: (mention as FormValues)?.url || '',
        socialMediaLink: (mention as FormValues)?.socialMediaLink || '',
      };

  const { register, errors, control, trigger, getValues, setValue } =
    useForm<FormValues>({
      resolver: yupResolver(getValidationSchema(type)),
      mode: 'onBlur',
      defaultValues,
    });

  useEffect(() => {
    if (mention) {
      const nameValue = isEditOption
        ? (mention as Mention)?.unregisteredContact?.name || ''
        : (mention as FormValues).name || '';
      setValue('name', nameValue);
    }
  }, [isEditOption, mention, setValue]);

  const mentionRemove = (index: number) => handleMillionsMentionRemove(index);

  const handleBlur = async () => {
    const isValid = await trigger(); // Validate the form on blur
    if (isValid) {
      const currentValues = getValues();
      currentValues['type'] = type;
      onChange(currentValues, index); // Trigger parent change handler
    }
  };

  const handlePhoneChange = (val: string) => setValue('phoneNumberE164', val);

  return (
    <div className={styles.root}>
      <div className={styles.unregisteredSelectContainer}>
        <Input
          name="name"
          placeholder="Enter name"
          ref={register}
          error={errors.name?.message}
          label="Name"
          disabled={!!isEditOption}
          onBlur={handleBlur}
        />
        <button
          className={styles.deleteButton}
          onClick={() => mentionRemove(index)}
          aria-label="Delete mention"
          type="button"
        >
          <DeleteOutlined className={styles.deleteIcon} />
        </button>
      </div>
      {!isEditOption && (
        <div className={styles.row}>
          <Input
            name="email"
            ref={register}
            placeholder="Enter email"
            error={errors.email?.message}
            label="Email"
            className={styles.halfRow}
            onBlur={handleBlur}
          />

          {type === ContactType.ATHLETE ? (
            <Controller
              name="phoneNumberE164"
              control={control}
              render={({ ...props }) => (
                <PhoneNumberInput
                  {...props}
                  name="phoneNumberE164"
                  label="Phone number"
                  classNames={styles.phoneNumberField}
                  placeholder="Phone Number"
                  onBlur={handleBlur}
                  handlePhoneChange={handlePhoneChange}
                />
              )}
            />
          ) : (
            <Input
              name="url"
              ref={register}
              placeholder="Enter URL"
              label="URL"
              error={errors.url?.message}
              className={styles.halfRow}
              onBlur={handleBlur}
            />
          )}
        </div>
      )}
      {type === ContactType.ATHLETE && (
        <div className={styles.row}>
          <Input
            name="socialMediaLink"
            placeholder="Enter social media link"
            ref={register}
            label="Social media link"
            disabled={!!isEditOption}
            onBlur={handleBlur}
          />
        </div>
      )}
    </div>
  );
};

export default UnregisteredMentionsSelect;
