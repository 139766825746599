export type FileMetadata = {
  ext: string;
  contentType: string;
  file: File;
};

export type CreatePresignedUrlOptions = {
  isStrict?: boolean;
  withoutContentType?: boolean;
};

export type CreatePresignedUrlProps = {
  files: File[];
  getPresignedUrls: (fileMetas: FileMetadata[]) => Promise<PresignedUrl[]>;
} & CreatePresignedUrlOptions;

export type PresignedUrl = {
  fields: string;
  url: string;
  key: string;
};

export type PresignedUrlWithFile = PresignedUrl & {
  fileWithMeta: FileMetadata;
};

export type PresignedUrlResultItem = {
  key: string;
};
const contentTypeToExtMap = new Map([
  ['image/png', 'png'],
  ['image/jpeg', 'jpeg'],
  ['image/jpeg', 'jpg'],
  ['video/mpeg', 'mp4'],
  ['text/csv', 'csv'],
  // add more here
]);

export const deriveExtFromContentType = (
  contentType: string
): string | undefined => {
  return contentTypeToExtMap.get(contentType.toLowerCase());
};

const createPresignedUrls = async ({
  files,
  getPresignedUrls,
}: CreatePresignedUrlProps): Promise<PresignedUrlWithFile[]> => {
  const fileMetas: FileMetadata[] = files.map((file) => {
    const imageExtFromFileNameParts = file.name.split('.');
    const ext =
      imageExtFromFileNameParts.length > 1
        ? imageExtFromFileNameParts[imageExtFromFileNameParts.length - 1]
        : deriveExtFromContentType(file.type);

    if (!ext) {
      throw new Error('Unknown file ext');
    }

    return {
      ext,
      contentType: file.type,
      file,
    };
  });

  const presignedUrls = await getPresignedUrls(fileMetas);

  return presignedUrls.map((presignedUrl, idx) => ({
    fields: presignedUrl.fields,
    url: presignedUrl.url,
    key: presignedUrl.key,
    fileWithMeta: fileMetas[idx],
  }));
};

export const uploadFilesToS3 = async (
  preSignedUrls: PresignedUrlWithFile[],
  options: CreatePresignedUrlOptions
): Promise<PresignedUrlWithFile[]> => {
  return await Promise.all(
    preSignedUrls.map(async (presigned) => {
      const { fields, url, key, fileWithMeta } = presigned;
      const formData = new FormData();

      Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      const keyWithExt = options.isStrict ? key : `${key}.${fileWithMeta.ext}`;

      formData.append('key', keyWithExt);

      if (!options.withoutContentType) {
        formData.append('Content-Type', fileWithMeta.file.type);
      }
      formData.append('file', fileWithMeta.file);

      await fetch(url, {
        method: 'POST',
        body: formData,
      });

      return {
        ...presigned,
        key: keyWithExt,
      };
    })
  );
};

/**
 * Presigned POST uploader
 */
export const createPresignedUrlsAndUploadToS3 = async ({
  files,
  getPresignedUrls,
  isStrict = true,
  withoutContentType = false,
}: CreatePresignedUrlProps): Promise<PresignedUrlResultItem[]> => {
  const preSignedUrls = await createPresignedUrls({ files, getPresignedUrls });

  const uploadedPresignedObjects = await uploadFilesToS3(preSignedUrls, {
    isStrict,
    withoutContentType,
  });

  return uploadedPresignedObjects.map((presigned) => ({ key: presigned.key }));
};
