import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import moment from 'moment';
import {
  Button,
  Form,
  Input,
  Select,
  Typography,
  Checkbox,
  InputNumber,
} from 'antd';
import { isValidPhoneNumber } from 'libphonenumber-js';
// API
import {
  GET_COUNTRY_STATES_V2,
  GET_STATE_CITIES_V2,
} from 'api/locations/queries';
import { GET_ALL_AREAS_OF_INFLUENCE } from 'api/areaOfInfluence/queries';
import { GET_ALL_PSYCHOSOCIAL_KEYWORDS } from 'api/psychosocialKeyword/queries';
import { GET_HASHTAGS } from 'api/hashtags/queries';
import { EDIT_STORE_ADMIN } from 'api/store/mutations';
// Constants
import { commonTimeFormat } from 'constants/global';
// Types
import { GetAllCountriesV2_getAllCountriesV2 } from 'api/locations/types/GetAllCountriesV2';
import {
  GetCountryStates,
  GetCountryStates_getCountryStatesV2,
  GetCountryStatesVariables,
} from 'api/locations/types/GetCountryStates';
import {
  GetStateCitiesV2,
  GetStateCitiesV2Variables,
} from 'api/locations/types/GetStateCitiesV2';
import {
  GetAllUserPsychosocialKeywords,
  GetAllUserPsychosocialKeywordsVariables,
} from 'api/psychosocialKeyword/types/GetAllUserPsychosocialKeywords';
import {
  GetAllAreasOfInfluence,
  GetAllAreasOfInfluenceVariables,
} from 'api/areaOfInfluence/types/GetAllAreasOfInfluence';
import {
  EditStoreAdmin,
  EditStoreAdminVariables,
} from 'api/store/types/EditStoreAdmin';
import {
  GetHashtags,
  GetHashtagsVariables,
} from 'api/hashtags/types/GetHashtags';
import { UploadFile } from 'antd/es/upload/interface';
import { GetManageBioStore_adminStores_entities } from 'api/store/types/GetManageBioStore';
import {
  StoreGender,
  StoreStatus,
  StoreEditAdminInput,
} from 'api/graphql-global-types';
// Hooks
import { useGetAdmins, useGetCountriesV2, useGetSports } from 'hooks';
// Helpers
import { findCountryByIso2, isEmptyString, isZero } from 'helpers/editStore';
// UI
import UploadImageFile from 'ui/UploadImageFile/UploadImageFile';
import { errorNotification, successNotification } from 'ui/Notification';
// Components
import PhoneNumberInput from 'components/common/PhoneInput/PhoneNumberInput';
import CroppedPictureInput from 'components/common/CroppedPictureInput/CroppedPictureInput';
import RichText from 'components/common/RichText/RichText';
import SearchCitiesComponent, {
  AutoCompleteOption,
} from '../../SearchCities/SearchCities';
// Styles
import './styles.scss';

const { Option } = Select;
const { Title } = Typography;

const buttonItemLayout = {
  wrapperCol: { span: 10, offset: 8 },
};

type EditContentCreatorFormProps = {
  contentCreator: GetManageBioStore_adminStores_entities | undefined;
};

type FormValues = {
  storeName?: string;
  sportType?: string[];
  country?: string;
  city?: string;
  uploadAvatarImage?: Array<UploadFile>;
  uploadCoverImage?: Array<UploadFile>;
  isOrphanPage: boolean;
  description?: string;
  hashtags?: number[];
  contactsFirstName?: string;
  contactsLastName?: string;
  contactsRole?: string;
  contactsEmail?: string;
  contactsPhoneNumber?: string;
  redirectSlugs?: string[];
  facebookLink: string;
  instagramLink: string;
  tiktokLink: string;
  website: string;
  twitterLink: string;
  socialMediaLink: string;
  youtubeLink: string;
  adConnected?: boolean;
  agencyName: string;
  agencyWebsite: string;
  agentEmail: string;
  agentName: string;
  facebookFollowing: number;
  gender: StoreGender;
  inbound: string;
  initialAssessment: number;
  instagramFollowing: number;
  latestAssessment: number;
  managedById: string;
  socialConnected: boolean;
  tiktokFollowing: number;
  twitterFollowing: number;
  youtubeFollowing: number;
  areasOfInfluence?: string[];
  psychosocialKeywords?: string[];
  state: string;
  isBirthDayKnown: boolean;
  isBirthMonthKnown: boolean;
  birthDate: string;
  designRequestsPerMonthLimit: number;
  signing?: string | null;
  socialPost?: string | null;
  appearance?: string | null;
  pressConferenceOrAmplifiedInterview?: string | null;
  watchParty?: string | null;
  affinities?: string | null;
  currentSponsors?: string | null;
  conflictsOfInterestsOrNonInterests?: string | null;
};

const EditContentCreatorForm = ({
  contentCreator,
}: EditContentCreatorFormProps): JSX.Element => {
  const [form] = Form.useForm();
  // eslint-disable-next-line
  const [formFields, setFormFields] = useState<FormValues | {}>({});
  const [pictureValidation, setPictureValidation] = useState<string>('');
  const uploadAvatarImage = form.getFieldValue('uploadAvatarImage');

  const { data: adminsData } = useGetAdmins({
    variables: {
      limit: 100,
      status: [StoreStatus.Active, StoreStatus.Pending, StoreStatus.Inactive],
    },
  });

  const { data: sportsData } = useGetSports();

  const [country, setCountry] =
    useState<GetAllCountriesV2_getAllCountriesV2 | null>(null);
  const [state, setState] =
    useState<GetCountryStates_getCountryStatesV2 | null>(null);
  const [citySearchKeyword, setCitySearchKeyword] = useState<string>('');

  const [selectedCityOption, setSelectedCityOption] =
    useState<AutoCompleteOption | null>(null);

  const { data: countriesList } = useGetCountriesV2();

  const [getStates, { data: statesList }] = useLazyQuery<
    GetCountryStates,
    GetCountryStatesVariables
  >(GET_COUNTRY_STATES_V2);

  const [getCities, { data: citiesList }] = useLazyQuery<
    GetStateCitiesV2,
    GetStateCitiesV2Variables
  >(GET_STATE_CITIES_V2);

  useEffect(() => {
    if (contentCreator?.storeDetails?.country && countriesList) {
      const country = findCountryByIso2(
        countriesList,
        contentCreator.storeDetails.country
      );

      setCountry(country);
    }
  }, [contentCreator, countriesList]);

  useEffect(() => {
    if (country) {
      getStates({
        variables: {
          input: { countryId: country?.id },
        },
      });

      const state =
        statesList?.getCountryStatesV2?.find(
          (item) => item.stateCode === contentCreator?.storeDetails?.state
        ) || null;

      setState(state);
      setSelectedCityOption(null);
    }
  }, [contentCreator, country, getStates, statesList]);

  useEffect(() => {
    if (state && contentCreator?.storeDetails?.city) {
      getCities({
        variables: {
          input: { stateId: state?.id, name: citySearchKeyword },
        },
      });

      const city =
        citiesList?.getStateCitiesV2?.entities?.find(
          (item) => item.name === contentCreator?.storeDetails?.city
        ) || null;

      if (city) {
        setSelectedCityOption({
          value: city?.id,
          label: city?.name,
          longitude: city?.longitude,
          latitude: city?.latitude,
        });
      }
    }
  }, [contentCreator, citiesList, getCities, state, citySearchKeyword]);

  useEffect(() => {
    if (contentCreator) {
      form.setFieldsValue({
        storeName: contentCreator.storeDetails?.storeName,
        sportType: contentCreator.sports.map((item) => item.id.toString()),
        country: contentCreator.storeDetails?.country,
        state: contentCreator.storeDetails?.state,
        city: contentCreator.storeDetails?.city,
        uploadAvatarImage: contentCreator.storeDetails?.avatarURL,
        uploadCoverImage: contentCreator.storeDetails?.coverURL,
        isOrphanPage: contentCreator.isOrphanPage,
        description: contentCreator.storeDetails?.description,
        hashtags: contentCreator.hashtags.map((item) => item.id),
        contactsFirstName: contentCreator.firstName,
        contactsLastName: contentCreator.lastName,
        contactsRole: contentCreator?.storeDetails?.gymRole,
        contactsEmail: contentCreator.email,
        contactsPhoneNumber: contentCreator.phoneNumber,
        redirectSlugs: contentCreator.redirectSlugs || [],
        facebookLink: contentCreator?.storeDetails?.facebookLink,
        instagramLink: contentCreator?.storeDetails?.instagramLink,
        tiktokLink: contentCreator?.storeDetails?.tiktokLink,
        youtubeLink: contentCreator?.storeDetails?.youtubeLink,
        twitterLink: contentCreator?.storeDetails?.twitterLink,
        website: contentCreator?.storeDetails?.website,
        adConnected: contentCreator?.storeManagementDetails?.adConnected,
        agencyName: contentCreator.storeManagementDetails?.agencyName,
        agencyWebsite: contentCreator.storeManagementDetails?.agencyWebsite,
        agentEmail: contentCreator.storeManagementDetails?.agentEmail,
        agentName: contentCreator.storeManagementDetails?.agentName,
        inbound: contentCreator?.storeManagementDetails?.inbound,
        initialAssessment:
          contentCreator?.storeManagementDetails?.initialAssessment,
        latestAssessment:
          contentCreator?.storeManagementDetails?.latestAssessment,
        managedById: contentCreator?.storeManagementDetails?.managedById,
        socialConnected:
          contentCreator?.storeManagementDetails?.socialConnected,
        facebookFollowing: contentCreator?.storeDetails?.facebookFollowing,
        gender: contentCreator?.storeDetails?.gender,
        instagramFollowing: contentCreator?.storeDetails?.instagramFollowing,
        tiktokFollowing: contentCreator?.storeDetails?.tiktokFollowing,
        twitterFollowing: contentCreator?.storeDetails?.twitterFollowing,
        youtubeFollowing: contentCreator?.storeDetails?.youtubeFollowing,
        areasOfInfluence: contentCreator.areasOfInfluence?.map(
          (item) => item.id
        ),
        psychosocialKeywords: contentCreator.psychosocialKeywords?.map(
          (item) => item.id
        ),
        designRequestsPerMonthLimit:
          contentCreator?.storeManagementDetails?.designRequestsPerMonthLimit,
        signing: contentCreator.storeManagementDetails?.signing || null,
        socialPost: contentCreator.storeManagementDetails?.socialPost || null,
        appearance: contentCreator.storeManagementDetails?.appearance || null,
        pressConferenceOrAmplifiedInterview:
          contentCreator.storeManagementDetails
            ?.pressConferenceOrAmplifiedInterview || null,
        watchParty: contentCreator.storeManagementDetails?.watchParty || null,
        affinities: contentCreator.storeManagementDetails?.affinities || null,
        currentSponsors:
          contentCreator.storeManagementDetails?.currentSponsors || null,
        conflictsOfInterestsOrNonInterests:
          contentCreator.storeManagementDetails
            ?.conflictsOfInterestsOrNonInterests || null,
      });
      setCitySearchKeyword(contentCreator?.storeDetails?.city || '');
    }
  }, [contentCreator, form]);

  const { data } = useQuery<GetHashtags, GetHashtagsVariables>(GET_HASHTAGS, {
    variables: {
      keyword: '',
    },
    fetchPolicy: 'cache-and-network',
  });

  const [editStoreAdmin, { loading: editLoading }] = useMutation<
    EditStoreAdmin,
    EditStoreAdminVariables
  >(EDIT_STORE_ADMIN);

  const { data: areaOfInfluenceData } = useQuery<
    GetAllAreasOfInfluence,
    GetAllAreasOfInfluenceVariables
  >(GET_ALL_AREAS_OF_INFLUENCE, {
    variables: {
      input: {
        keyword: '',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: psychosocialKeywordData } = useQuery<
    GetAllUserPsychosocialKeywords,
    GetAllUserPsychosocialKeywordsVariables
  >(GET_ALL_PSYCHOSOCIAL_KEYWORDS, {
    variables: {
      input: {
        keyword: '',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleFormSubmit = async (values: FormValues): Promise<void> => {
    const avatar =
      values.uploadAvatarImage?.length === 0
        ? null
        : values?.uploadAvatarImage?.[0]?.originFileObj;
    const cover =
      values.uploadCoverImage?.length === 0
        ? null
        : values?.uploadCoverImage?.[0]?.originFileObj;

    const storeEditInput: StoreEditAdminInput = {
      storeId: contentCreator?.id || '',
      userDetails: {
        firstName: values.contactsFirstName,
        lastName: values.contactsLastName,
        email: values.contactsEmail || '',
        phoneNumberE164: values.contactsPhoneNumber || '',
        sportIds: values?.sportType?.map(Number),
        hashtagIds: values.hashtags,
        isOrphanPage: values.isOrphanPage,
        redirects: values.redirectSlugs || [],
        areaOfInfluenceIds: values.areasOfInfluence || [],
        psychosocialKeywordsIds: values.psychosocialKeywords || [],
      },
      storeDetails: {
        ...(selectedCityOption
          ? {
              location: {
                longitude: selectedCityOption.longitude,
                latitude: selectedCityOption.latitude,
              },
            }
          : state
          ? {
              location: {
                longitude: state.longitude as number,
                latitude: state.latitude as number,
              },
            }
          : {}),
        socialMediaLink: values.socialMediaLink,
        storeName: values.storeName,
        facebookLink: isEmptyString(values.facebookLink),
        instagramLink: isEmptyString(values.instagramLink),
        tiktokLink: isEmptyString(values.tiktokLink),
        youtubeLink: isEmptyString(values.youtubeLink),
        twitterLink: isEmptyString(values.twitterLink),
        website: isEmptyString(values.website),
        ...(values.uploadAvatarImage === contentCreator?.storeDetails?.avatarURL
          ? {}
          : { avatar: avatar }),
        ...(values.uploadCoverImage === contentCreator?.storeDetails?.coverURL
          ? {}
          : { cover: cover }),
        description: values.description || '',
        country: country?.iso2 || null,
        state: state?.stateCode || null,
        city: selectedCityOption
          ? selectedCityOption?.label
          : values.city || null,
        gymRole: values.contactsRole || '',
      },
      storeManagementDetails: {
        adConnected: values.adConnected || false,
        agencyName: isEmptyString(values.agencyName),
        agencyWebsite: isEmptyString(values.agencyWebsite),
        agentEmail: isEmptyString(values.agentEmail),
        agentName: isEmptyString(values.agentName),
        gender: values.gender,
        inbound: isEmptyString(values.inbound),
        managedById: values.managedById || null,
        socialConnected: values.socialConnected,
        initialAssessment: isZero(
          Number(values.initialAssessment) <= 5 &&
            Number(values.initialAssessment) >= 0
            ? Number(values.initialAssessment)
            : 0
        ),
        latestAssessment: isZero(
          Number(values.latestAssessment) <= 5 &&
            Number(values.latestAssessment) >= 0
            ? Number(values.latestAssessment)
            : 0
        ),
        instagramFollowing: isZero(Number(values.instagramFollowing)),
        facebookFollowing: isZero(Number(values.facebookFollowing)),
        tiktokFollowing: isZero(Number(values.tiktokFollowing)),
        twitterFollowing: isZero(Number(values.twitterFollowing)),
        youtubeFollowing: isZero(Number(values.youtubeFollowing)),
        designRequestsPerMonthLimit:
          Number(values.designRequestsPerMonthLimit) || 1,
        signing: values.signing || null,
        socialPost: values.socialPost || null,
        appearance: values.appearance || null,
        pressConferenceOrAmplifiedInterview:
          values.pressConferenceOrAmplifiedInterview || null,
        watchParty: values.watchParty || null,
        affinities: values.affinities || null,
        currentSponsors: values.currentSponsors || null,
        conflictsOfInterestsOrNonInterests:
          values.conflictsOfInterestsOrNonInterests || null,
      },
    };

    try {
      const res = await editStoreAdmin({
        variables: {
          input: storeEditInput,
        },
      });

      const errors = res?.data?.editStoreAdmin?.errors;

      if (errors && errors.length > 0) {
        const errorMessages = errors.map((error) => error).join(', ');
        return errorNotification(`${errorMessages}`);
      }

      successNotification(`The description has been updated`);
    } catch (err) {
      if (
        (err as any).graphQLErrors[0]?.extensions.response?.message.some(
          (item: string | string[]) =>
            item.includes('must be a valid phone number')
        )
      ) {
        return errorNotification('Please enter a valid phone number');
      } else {
        errorNotification();
        console.log('update description error:', { ...(err as Error) });
      }
    }
  };

  const adminsOptions = adminsData?.admins?.entities.map(
    ({ id, firstName, lastName, status }) => {
      const isInactive = status === StoreStatus.Inactive;
      return (
        <Option key={id} value={id}>
          <div className={isInactive ? 'isDisabled' : ''}>
            {`${firstName} ${lastName} ${isInactive ? 'INACTIVE' : ''}`}
          </div>
        </Option>
      );
    }
  );

  const areasOfInfluenceOptions =
    areaOfInfluenceData?.getAllAreasOfInfluence?.entities.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));

  const psychosocialKeywordOptions =
    psychosocialKeywordData?.getAllUserPsychosocialKeywords?.entities.map(
      (item) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      )
    );

  const genderOptions = [
    { key: StoreGender.female, value: StoreGender.female },
    {
      key: StoreGender.male,
      value: StoreGender.male,
    },
    {
      key: StoreGender.other,
      value: StoreGender.other,
    },
    {
      key: StoreGender.preferNotToSay,
      value: StoreGender.preferNotToSay,
    },
  ].map(({ key, value }) => (
    <Option key={key} value={value}>
      {value}
    </Option>
  ));

  const hashtagsOptions = data?.getHashtags.map((item) => (
    <Option key={item.id} value={item.id}>
      {item.name}
    </Option>
  ));

  const redirectOptions = contentCreator?.redirectSlugs?.map((item) => (
    <Option key={item} value={item}>
      {item}
    </Option>
  ));

  const isContentCreatorInPendingStatus = !contentCreator?.activatedAt;
  const sports = sportsData?.sports || [];

  const handleChangeDescription = (value: string): void => {
    form.setFieldsValue({ description: value });
  };

  const normFile = (e: any) => {
    if (e && Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const setFields = () => {
    const fields = form.getFieldsValue();
    setFormFields({
      ...fields,
    });
  };

  const onCountryChange = (iso2: string) => {
    const country = countriesList?.getAllCountriesV2.find((item) => {
      return item.iso2 === iso2;
    });

    if (country) {
      getStates({
        variables: {
          input: { countryId: country?.id },
        },
      });

      setCountry(country);

      setState(null);
      setSelectedCityOption(null);
      setCitySearchKeyword('');
    }
  };

  const onStateChange = (stateCode: string) => {
    const state = statesList?.getCountryStatesV2.find((item) => {
      return item.stateCode === stateCode;
    });

    if (state) {
      setState(state);
      setSelectedCityOption(null);
      setCitySearchKeyword('');
    }
  };

  const onPhoneNumberChange = (value: string) =>
    form.setFieldsValue({ contactsPhoneNumber: `+${value}` });

  return (
    <div className="formWrapper">
      <Form
        onFinish={handleFormSubmit}
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 10 }}
        onValuesChange={setFields}
        scrollToFirstError
      >
        <Form.Item>
          <Title level={5}>General</Title>
        </Form.Item>
        <>
          <Form.Item name="storeName" label="Content Creator Name">
            <Input
              placeholder="Enter content creator name"
              disabled={isContentCreatorInPendingStatus}
            />
          </Form.Item>
          <Form.Item name="socialMediaLink" label="Social Media Link">
            <Input placeholder="Enter social media link" />
          </Form.Item>

          <Form.Item name="facebookLink" label="Facebook link">
            <Input placeholder="Enter facebook link" />
          </Form.Item>

          <Form.Item name="facebookFollowing" label="Facebook following">
            <Input type="number" placeholder="Facebook following" />
          </Form.Item>

          <Form.Item name="instagramLink" label="Instagram link">
            <Input placeholder="Enter instagram link" />
          </Form.Item>

          <Form.Item name="instagramFollowing" label="Instagram Following">
            <Input type="number" placeholder="Instagram Following" />
          </Form.Item>

          <Form.Item name="tiktokLink" label="Tiktok link">
            <Input placeholder="Enter tiktok link" />
          </Form.Item>

          <Form.Item name="tiktokFollowing" label="Tiktok Following">
            <Input type="number" placeholder="Tiktok Following" />
          </Form.Item>

          <Form.Item name="twitterLink" label="Twitter link">
            <Input placeholder="Enter Twitter link" />
          </Form.Item>

          <Form.Item name="twitterFollowing" label="Twitter Following">
            <Input type="number" placeholder="Twitter Following" />
          </Form.Item>

          <Form.Item name="youtubeLink" label="Youtube link">
            <Input placeholder="Enter Youtube link" />
          </Form.Item>

          <Form.Item name="youtubeFollowing" label="Youtube Following">
            <Input type="number" placeholder="Youtube Following" />
          </Form.Item>

          <Form.Item name="website" label="Website link">
            <Input placeholder="Enter website link" />
          </Form.Item>
          <Form.Item
            name="sportType"
            label="Type of sport"
            rules={[
              {
                required: true,
                message: 'Please, select sport',
              },
            ]}
          >
            <Select
              id="sportType"
              placeholder="Select sport type"
              mode="multiple"
              filterOption={(inputValue, option) =>
                option?.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            >
              {sports.map((item) => {
                return (
                  <Option value={`${item.id}`} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="uploadAvatarImage"
            label="Profile Image"
            validateStatus={
              pictureValidation ||
              form.getFieldsError(['uploadAvatarImage'])[0].errors?.length ||
              !uploadAvatarImage
                ? 'error'
                : ''
            }
            help={pictureValidation || null}
            rules={[
              {
                required: true,
                message: 'Please upload the picture',
              },
            ]}
          >
            <CroppedPictureInput
              setPictureValidation={setPictureValidation}
              defaultImage={contentCreator?.storeDetails?.avatarURL}
              requiredMessage="Please upload the picture"
              buttonTitle="Upload profile picture"
            />
          </Form.Item>

          <Form.Item
            name="uploadCoverImage"
            label="Background image"
            valuePropName="file"
            getValueFromEvent={normFile}
          >
            <UploadImageFile
              hideButton={
                form.getFieldsValue()?.uploadCoverImage?.length >= 1 ||
                form.getFieldInstance('uploadCoverImage')?.fileList?.length
              }
              buttonTitle="Upload image"
              imageAlt="Background image"
              defaultFileUrl={contentCreator?.storeDetails?.coverURL}
            />
          </Form.Item>
          <Form.Item
            name="isOrphanPage"
            label="Hide my profile"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <div className="richTextWrapper">
              <RichText
                onChange={handleChangeDescription}
                placeholder="Add description"
                initialValue={contentCreator?.storeDetails?.description || ''}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="designRequestsPerMonthLimit"
            label="Request Merch Design limit"
            tooltip="How many times per month content creator can request merch design, default value is 1"
          >
            <Input
              type="number"
              placeholder="Enter Request Merch Design limit per month"
              min={1}
              max={100}
            />
          </Form.Item>

          <Title level={5}>Store management</Title>

          <div className="liveDateWrapper">
            <Title level={5}>
              Live date:
              {contentCreator?.storeManagementDetails?.liveDate
                ? moment(
                    contentCreator?.storeManagementDetails?.liveDate
                  ).format(commonTimeFormat)
                : ' Date unavailable'}
            </Title>
          </div>

          <Form.Item name="managedById" label="Managed by:">
            <Select allowClear placeholder="Managed by">
              {adminsOptions}
            </Select>
          </Form.Item>

          <Form.Item
            name="adConnected"
            label="Ad connected"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            name="socialConnected"
            label="Social connected"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item name="agencyName" label="Agency Name">
            <Input placeholder="Enter agency name" />
          </Form.Item>

          <Form.Item name="agencyWebsite" label="Agency Website">
            <Input placeholder="Enter agency Website" />
          </Form.Item>

          <Form.Item name="agentEmail" label="Agent email">
            <Input placeholder="Enter agent email" />
          </Form.Item>

          <Form.Item name="agentName" label="Agent Name">
            <Input placeholder="Enter agent name" />
          </Form.Item>

          <Form.Item name="gender" label="Gender">
            <Select placeholder="Select gender">{genderOptions}</Select>
          </Form.Item>

          <Form.Item name="inbound" label="Inbound">
            <Input placeholder="Enter Inbound" />
          </Form.Item>

          <Form.Item name="initialAssessment" label="Initial Assessment">
            <InputNumber
              type="number"
              placeholder="Initial Assessment"
              min={0}
              max={5}
            />
          </Form.Item>

          <Form.Item name="latestAssessment" label="Latest Assessment">
            <InputNumber
              type="number"
              placeholder="Latest Assessment"
              min={0}
              max={5}
            />
          </Form.Item>

          <Form.Item name="hashtags" label="Hashtags">
            <Select
              placeholder="Select hashtags"
              mode="multiple"
              filterOption={(inputValue, option) =>
                option?.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            >
              {hashtagsOptions}
            </Select>
          </Form.Item>

          <Form.Item name="areasOfInfluence" label="Areas of interest">
            <Select
              placeholder="Select areas"
              mode="multiple"
              filterOption={(inputValue, option) =>
                option?.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            >
              {areasOfInfluenceOptions}
            </Select>
          </Form.Item>

          <Form.Item name="psychosocialKeywords" label="Internal tags">
            <Select
              placeholder="Select psychosocial keywords"
              mode="multiple"
              filterOption={(inputValue, option) =>
                option?.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            >
              {psychosocialKeywordOptions}
            </Select>
          </Form.Item>
        </>

        <Form.Item>
          <Title level={5} style={{ marginTop: '16px' }}>
            Contact person
          </Title>
        </Form.Item>
        <>
          <Form.Item
            name="contactsFirstName"
            label="Contact's first Name"
            rules={[
              {
                required: true,
                message: 'Please, input first name',
              },
            ]}
          >
            <Input placeholder="Enter first name" />
          </Form.Item>
          <Form.Item
            name="contactsLastName"
            label="Contact's last Name"
            rules={[
              {
                required: true,
                message: 'Please, input last name',
              },
            ]}
          >
            <Input placeholder="Enter last name" />
          </Form.Item>

          <Form.Item
            name="contactsRole"
            label="Contact's role"
            rules={[
              {
                required: true,
                message: 'Please, input role',
              },
            ]}
          >
            <Input placeholder="Enter role" />
          </Form.Item>
          <Form.Item
            name="contactsEmail"
            label="Contact's email"
            rules={[
              {
                required: true,
                message: 'Please, input email',
              },
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            name="contactsPhoneNumber"
            label="Contact's phone number"
            rules={[
              {
                required: true,
                message: 'Please, input phone number',
              },
              {
                message: 'Phone must be a valid phone number',
                validator: async (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }

                  if (isValidPhoneNumber(value)) {
                    return Promise.resolve();
                  }

                  return Promise.reject();
                },
              },
            ]}
          >
            <PhoneNumberInput
              name="contactsPhoneNumber"
              value={contentCreator?.phoneNumber || ' '}
              placeholder="Enter phone number"
              handlePhoneChange={onPhoneNumberChange}
            />
          </Form.Item>

          <Form.Item
            name="country"
            label="Country"
            rules={[
              {
                required: true,
                message: 'Please, select country',
              },
            ]}
          >
            <Select
              id="country"
              showSearch
              placeholder="Select a country"
              optionFilterProp="children"
              onChange={onCountryChange}
            >
              {countriesList?.getAllCountriesV2?.map(({ iso2, name }) => {
                return (
                  <Option key={iso2} value={iso2}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {country && (
            <Form.Item
              name="state"
              label="State/Province/Region"
              rules={[
                {
                  required: true,
                  message: 'Please, select country',
                },
              ]}
            >
              <Select
                id="state"
                showSearch
                placeholder="Select a state"
                optionFilterProp="children"
                onChange={onStateChange}
              >
                {statesList?.getCountryStatesV2?.map(({ name, stateCode }) => {
                  return (
                    <Option key={stateCode} value={stateCode}>
                      {name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          {citiesList?.getStateCitiesV2?.total !== 0 ? (
            <div className="city-wrapper">
              {state && (
                <SearchCitiesComponent
                  stateId={state.id}
                  selectedCityOption={selectedCityOption}
                  setSelectedCityOption={setSelectedCityOption}
                />
              )}
            </div>
          ) : (
            <Form.Item name="city" label="City">
              <Input placeholder="Enter City" />
            </Form.Item>
          )}

          <Form.Item name="redirectSlugs" label="List of redirect names">
            <Select mode="tags" placeholder="Enter redirect name">
              {redirectOptions}
            </Select>
          </Form.Item>

          <Title level={5}>Sponsor pricing</Title>

          <Form.Item
            name="signing"
            label="Signing (hourly rate or price per item and minimums)"
          >
            <Input placeholder="Signing (hourly rate or price per item and minimums)" />
          </Form.Item>

          <Form.Item name="appearance" label="Appearance (hourly rate)">
            <Input placeholder="Appearance (hourly rate)" />
          </Form.Item>

          <Form.Item name="socialPost" label="Social Post (price per post)">
            <Input placeholder="Social Post (price per post)" />
          </Form.Item>

          <Form.Item
            name="pressConferenceOrAmplifiedInterview"
            label="Press Conference / Amplified Interview"
          >
            <Input placeholder="Press Conference / Amplified Interview" />
          </Form.Item>

          <Form.Item name="watchParty" label="Watch Party">
            <Input placeholder="Watch Party" />
          </Form.Item>

          <Form.Item name="affinities" label="Affinities">
            <Input placeholder="Affinities" />
          </Form.Item>

          <Form.Item name="currentSponsors" label="Current Sponsors">
            <Input placeholder="Current Sponsors" />
          </Form.Item>

          <Form.Item
            name="conflictsOfInterestsOrNonInterests"
            label="Conflicts of interest / Non-interests"
          >
            <Input placeholder="Conflicts of interest / Non-interests" />
          </Form.Item>
        </>

        <Form.Item {...buttonItemLayout}>
          <Button type="primary" htmlType="submit" loading={editLoading}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditContentCreatorForm;
