import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Modal, Button, Tabs, Rate } from 'antd';
// Api
import { GET_VIDEO_EDIT_REQUESTS } from 'api/videoLab/queries';
// Types
import { SortedInfo, TableFilter } from 'ui/Table';
import {
  SortDirection,
  VideoEditRequestsOrderBy,
  VideoEditRequestStatus,
} from 'api/graphql-global-types';
import {
  GetVideoEditRequests,
  GetVideoEditRequests_getVideoEditRequests_entities,
  GetVideoEditRequests_getVideoEditRequests_entities_auditLogs,
  GetVideoEditRequestsVariables,
} from 'api/videoLab/types/GetVideoEditRequests';
// UI
import Table, { Column } from 'ui/Table';
// Components
import VideoEditingRequestsActions from '../components/VideoEditingRequestsActions/VideoEditingRequestsActions';
import { CreateRequestModal } from '../components/CreateRequestModal/CreateRequestModal';
import {
  assignedEditorColumn,
  dueDateColumn,
  requestDateColumn,
  requestTypeColumn,
  storeNameColumn,
} from 'pages/VideoEditingRequest/common/Columns';
import VideoEditRequestDetailsModal from '../components/VideoEditRequestDetailsModal/VideoEditRequestDetailsModal';
import AuditLogs from 'components/common/DesignRequestsTable/components/AuditLogs/AuditLogs';
// Styles
import styles from './VideoEditingRequestsTable.module.scss';

export type VideoEditRequestsExtended =
  GetVideoEditRequests_getVideoEditRequests_entities & {
    assignedEditorName: string;
  };

type ReturnType = {
  value: VideoEditRequestStatus;
  text: string;
};

const getStatues = (tabKey: string): ReturnType[] => {
  let statuses = [];

  switch (tabKey) {
    case 'isCompleted':
      statuses = [
        { value: VideoEditRequestStatus.Accepted, text: 'Accepted' },
        { value: VideoEditRequestStatus.AutoAccepted, text: 'Auto-Accepted' },
        { value: VideoEditRequestStatus.Archived, text: 'Archived' },
      ];
      break;
    case 'isInProgress':
      statuses = [
        { value: VideoEditRequestStatus.InProgress, text: 'In Progress' },
        {
          value: VideoEditRequestStatus.Archived,
          text: 'Archived',
        },
      ];
      break;
    case 'isOverdue':
      statuses = [
        { value: VideoEditRequestStatus.InProgress, text: 'In Progress' },
        { value: VideoEditRequestStatus.OnReview, text: 'On Review' },
        { value: VideoEditRequestStatus.Rejected, text: 'Rejected' },
        {
          value: VideoEditRequestStatus.Archived,
          text: 'Archived',
        },
      ];
      break;
    case 'isTodo':
      statuses = [
        { value: VideoEditRequestStatus.Todo, text: 'To Do' },
        {
          value: VideoEditRequestStatus.Archived,
          text: 'Archived',
        },
      ];
      break;
    default:
      statuses = [
        { value: VideoEditRequestStatus.Todo, text: 'To Do' },
        { value: VideoEditRequestStatus.Archived, text: 'Archived' },
        { value: VideoEditRequestStatus.InProgress, text: 'In Progress' },
        { value: VideoEditRequestStatus.OnReview, text: 'On Review' },
        { value: VideoEditRequestStatus.Accepted, text: 'Accepted' },
        { value: VideoEditRequestStatus.AutoAccepted, text: 'Auto-Accepted' },
        { value: VideoEditRequestStatus.Rejected, text: 'Rejected' },
      ];
  }
  return statuses;
};

export const refetchQueries = [
  {
    query: GET_VIDEO_EDIT_REQUESTS,
    variables: {
      input: {
        direction: SortDirection.DESC,
        orderBy: VideoEditRequestsOrderBy.createdAt,
        limit: 10,
        offset: 0,
      },
    },
  },
];

const { TabPane } = Tabs;

const VideoEditingRequestsTable = (): JSX.Element => {
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] =
    useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] =
    useState<GetVideoEditRequests_getVideoEditRequests_entities | null>(null);

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSortInfo] = useState<SortedInfo<VideoEditRequestsOrderBy>>({
    order: SortDirection.ASC,
    key: VideoEditRequestsOrderBy.createdAt,
  });
  const [searchFilters, setSearchFilters] = useState<TableFilter[]>([]);

  const [isCreateModalVisible, setIsCreateModalVisible] =
    useState<boolean>(false);

  const showEditModal = (
    record: GetVideoEditRequests_getVideoEditRequests_entities | null
  ) => {
    setSelectedRecord(record);
    setIsEditModalVisible(true);
  };

  const hideEditModal = () => {
    setIsEditModalVisible(false);
    setSelectedRecord(null);
  };

  const showDetailsModal = (
    record: GetVideoEditRequests_getVideoEditRequests_entities | null
  ) => {
    setSelectedRecord(record);
    setIsDetailsModalVisible(true);
  };

  const hideDetailsModal = () => {
    setIsDetailsModalVisible(false);
    setSelectedRecord(null);
  };

  const showCreateRequestModal = () => {
    setIsCreateModalVisible(true);
  };

  const hideCreateRequestModal = () => {
    setIsCreateModalVisible(false);
  };

  const [tabKey, setTabKey] = useState<string>('all');

  const handleTabChange = (activeKey: string) => {
    setTabKey(activeKey);
    setCurrentPage(1);
    setSearchFilters([]);
  };

  const getVideoRequestsInput = () => {
    let input: any = {
      direction: sort.order,
      orderBy: sort.key,
      limit: pageSize,
      offset: (currentPage > 1 ? currentPage - 1 : 0) * pageSize,
    };

    if (tabKey === 'isUnassigned') {
      input = {
        ...input,
        isUnassigned: true,
      };
    }

    if (tabKey === 'isOverdue') {
      input = {
        ...input,
        isOverdue: true,
      };
    }

    if (tabKey === 'isInProgress') {
      input = {
        ...input,
        status: [VideoEditRequestStatus.InProgress],
      };
    }

    if (tabKey === 'isCompleted') {
      input = {
        ...input,
        status: [VideoEditRequestStatus.Accepted],
      };
    }

    if (tabKey === 'isTodo') {
      input = {
        ...input,
        status: [VideoEditRequestStatus.Todo],
      };
    }

    searchFilters?.forEach(({ key, value }) => {
      input[key] = value;
    });

    const { status, ...rest } = input;

    return {
      input: {
        ...rest,
        ...(status && { videoEditRequestStatuses: status }),
      },
    };
  };

  const { data, loading } = useQuery<
    GetVideoEditRequests,
    GetVideoEditRequestsVariables
  >(GET_VIDEO_EDIT_REQUESTS, {
    variables: { ...getVideoRequestsInput() },
    fetchPolicy: 'network-only',
  });

  const extendedData: VideoEditRequestsExtended[] =
    data?.getVideoEditRequests?.entities.map(
      (request: GetVideoEditRequests_getVideoEditRequests_entities) => {
        return {
          ...request,
          assignedEditorName:
            request.assignedEditor?.firstName &&
            request.assignedEditor?.lastName
              ? `${request.assignedEditor?.firstName} ${request.assignedEditor?.lastName}`
              : '',
        };
      }
    ) || [];

  const counters = data?.getVideoEditRequests?.counters;

  const statusColumn = {
    title: 'Status',
    dataIndex: VideoEditRequestsOrderBy.status,
    key: VideoEditRequestsOrderBy.status,
    align: 'center' as const,
    filters: getStatues(tabKey),
    filterMultiple: true,
    withCheckboxFilters: true,
    width: 100,
  };

  const requestIdColumn = {
    title: 'Task ID',
    dataIndex: 'videoEditorId',
    key: 'videoEditorId',
    align: 'left' as const,
    fixed: 'left' as const,
    width: 120,
    render: function VideoEditorIdWrap(
      id: string,
      record: GetVideoEditRequests_getVideoEditRequests_entities
    ) {
      return (
        <span
          onKeyDown={() => showDetailsModal(record)}
          onClick={() => showDetailsModal(record)}
          className={styles.detailsModalLink}
          role="button"
          tabIndex={0}
        >
          {record.id}
        </span>
      );
    },
  };

  const auditLogsColumn = {
    title: 'Audit logs',
    dataIndex: 'auditLogs',
    align: 'center' as const,
    width: 140,
    render: function AuditLogsWrap(
      auditLogs: GetVideoEditRequests_getVideoEditRequests_entities_auditLogs[]
    ) {
      return <AuditLogs isVideoRequest data={auditLogs} />;
    },
  };

  const actionColumn = {
    title: 'Action',
    dataIndex: 'id',
    fixed: 'right' as const,
    align: 'center' as const,
    width: 90,
    render: function ActionsWrap(
      id: string,
      record: GetVideoEditRequests_getVideoEditRequests_entities
    ) {
      return (
        <VideoEditingRequestsActions
          request={record}
          store={record?.store}
          handleShowEditModal={() => showEditModal(record)}
        />
      );
    },
  };

  const assignedEditorRate = {
    title: 'Rate',
    key: 'rate',
    dataIndex: 'rate',
    align: 'center' as const,
    width: 200,
    render: function RateWrap(rate: number | null) {
      return <Rate value={rate || 0} disabled allowHalf count={5} />;
    },
  };

  const adminColumns: Column<
    VideoEditRequestsExtended,
    VideoEditRequestsOrderBy
  >[] = [
    requestIdColumn,
    storeNameColumn,
    requestDateColumn,
    dueDateColumn,
    requestTypeColumn,
    assignedEditorColumn,
    assignedEditorRate,
    statusColumn,
    auditLogsColumn,
    actionColumn,
  ];

  const renderTable = () => (
    <Table<VideoEditRequestsExtended, VideoEditRequestsOrderBy>
      columns={adminColumns}
      data={extendedData}
      scroll={{ x: 1280 }}
      loading={loading}
      total={data?.getVideoEditRequests?.total}
      defaultPageSize={pageSize}
      defaultCurrent={currentPage}
      setPageSize={setPageSize}
      setCurrentPage={setCurrentPage}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      sortInfo={sort}
      setSortInfo={setSortInfo}
    />
  );

  return (
    <>
      <Button
        type="primary"
        onClick={showCreateRequestModal}
        className={styles.addButton}
      >
        Add new video edit request
      </Button>
      <Tabs
        activeKey={tabKey}
        onChange={handleTabChange}
        className="countersCustomTabs"
      >
        <TabPane tab="All" key="all">
          {renderTable()}
        </TabPane>

        <TabPane
          tab={`Past Due Date ${counters?.pastDueDateCount || 0}`}
          key="isOverdue"
        >
          {renderTable()}
        </TabPane>
        <TabPane
          tab={`In progress ${counters?.inProgressCount || 0}`}
          key="isInProgress"
        >
          {renderTable()}
        </TabPane>

        <TabPane
          tab={`Completed ${counters?.completedCount || 0}`}
          key="isCompleted"
        >
          {renderTable()}
        </TabPane>

        <TabPane
          tab={`Unassigned ${counters?.unassignedCount || 0}`}
          key="isTodo"
        >
          {renderTable()}
        </TabPane>
      </Tabs>

      <Modal
        title="Create video edit request"
        visible={!!isCreateModalVisible}
        destroyOnClose
        footer={null}
        onCancel={hideCreateRequestModal}
        width={800}
      >
        <CreateRequestModal onClose={hideCreateRequestModal} />
      </Modal>

      <Modal
        title="Video editing request details"
        visible={!!selectedRecord && isDetailsModalVisible}
        destroyOnClose
        width={800}
        onCancel={hideDetailsModal}
        footer={null}
      >
        <VideoEditRequestDetailsModal
          videoEditRequest={selectedRecord}
          hideDetailsModal={hideDetailsModal}
          isViewOnly
        />
      </Modal>

      <Modal
        title="Video editing request details"
        visible={!!selectedRecord && isEditModalVisible}
        destroyOnClose
        width={800}
        onCancel={hideEditModal}
        footer={null}
      >
        <VideoEditRequestDetailsModal
          videoEditRequest={selectedRecord}
          hideDetailsModal={hideEditModal}
        />
      </Modal>
    </>
  );
};

export default VideoEditingRequestsTable;
