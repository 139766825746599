import { CreateCampaignExampleFiles_createCampaignExampleFiles } from 'api/campaignV2/types/CreateCampaignExampleFiles';
import {
  CampaignMediaType,
  SearchCampaignStoresOrderBy,
  SortDirection,
  StoreAthleticLevel,
  StoreGender,
  UserRole,
} from 'api/graphql-global-types';

export const GENDER_OPTIONS = [
  { value: StoreGender.male, label: 'Male' },
  { value: StoreGender.female, label: 'Female' },
  { value: StoreGender.other, label: 'Other' },
  { value: StoreGender.preferNotToSay, label: 'Prefer not to say' },
  { value: '', label: 'All' },
];

export const PRODUCT_TYPES = [
  {
    value: 'Non-Battery Product',
    label: 'Non-Battery Product',
  },
  {
    value: 'Battery Product',
    label: 'Battery Product',
  },
  {
    value: 'Non-Alcoholic Liquid',
    label: 'Non-Alcoholic Liquid',
  },
  {
    value: 'Oversized/weight Product',
    label: 'Oversized/weight Product',
  },
  {
    value: 'Food',
    label: 'Food',
  },
  {
    value: 'Plants',
    label: 'Plants',
  },
  {
    value: 'CBD/Cannabis',
    label: 'CBD/Cannabis',
  },
  {
    value: 'Alcohol',
    label: 'Alcohol',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const MEDIA_TYPES = [
  { value: CampaignMediaType.IMAGE, label: 'Image' },
  { value: CampaignMediaType.VIDEO, label: 'Video' },
];

export type ValueLabelOptionFollowers = {
  value: number;
  label: string;
};

export const FOLLOWING_OPTIONS_MIN: ValueLabelOptionFollowers[] = [
  { value: 1_000, label: '1k+' },
  { value: 5_000, label: '5k+' },
  { value: 10_000, label: '10K+' },
  { value: 25_000, label: '25k+' },
  { value: 50_000, label: '50k+' },
  { value: 100_000, label: '100k+' },
  { value: 500_000, label: '500K+' },
  { value: 1_000_000, label: '1M+' },
];

export const FOLLOWING_OPTIONS_MAX: ValueLabelOptionFollowers[] = [
  { value: 1_000, label: '<1k' },
  { value: 5_000, label: '<5k' },
  { value: 10_000, label: '<10K' },
  { value: 25_000, label: '<25k' },
  { value: 50_000, label: '<50k' },
  { value: 100_000, label: '<100k' },
  { value: 500_000, label: '<500K' },
  { value: 1_000_000, label: '<1M' },
];

export type ValueLabelOption = {
  value: string;
  label: string;
};

export const TALENT_TYPE: ValueLabelOption[] = [
  { value: UserRole.Athlete, label: 'Athlete' },
  { value: UserRole.ContentCreator, label: 'Creator' },
];

export type AthleteLevelOption = {
  value: StoreAthleticLevel;
  label: string;
};

export const ATHLETIC_LEVEL: AthleteLevelOption[] = [
  {
    value: StoreAthleticLevel.Amateur,
    label: 'Amateur',
  },
  {
    value: StoreAthleticLevel.CollegeUniversity,
    label: 'College University',
  },
  {
    value: StoreAthleticLevel.OlympicNational,
    label: 'Olympic National',
  },
  {
    value: StoreAthleticLevel.Other,
    label: 'Other',
  },
  {
    value: StoreAthleticLevel.Professional,
    label: 'Professional',
  },
  {
    value: StoreAthleticLevel.RetiredProfessional,
    label: 'Retired Professional',
  },
];

export type TalentTypeOption = {
  value: UserRole;
  label: string;
};

type ImageToUpload = {
  fields: string;
  url: string;
  key: string;
  type: string;
  file: File | string;
};

export const getImagesToUpload = (
  preSignedUrls: CreateCampaignExampleFiles_createCampaignExampleFiles[],
  images: FileWithPreview[]
): ImageToUpload[] => {
  const res: ImageToUpload[] = [];

  images.forEach((file, index) => {
    const preSignedUrl = preSignedUrls?.[index];

    res.push({
      fields: preSignedUrl?.fields || '',
      url: preSignedUrl?.url || '',
      key: preSignedUrl?.key,
      type: file?.type || '',
      file: file || '',
    });
  });

  return res;
};

export const uploadImages = async (
  preSignedUrls: CreateCampaignExampleFiles_createCampaignExampleFiles[],
  images: FileWithPreview[]
): Promise<string[]> => {
  const imagesToUpload = getImagesToUpload(preSignedUrls, images);

  for (const image of imagesToUpload) {
    const { fields, url, key, file } = image;
    const formData = new FormData();

    Object.entries(JSON.parse(fields)).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    formData.append('key', key);
    formData.append('file', file);

    await fetch(url, {
      method: 'POST',
      body: formData,
    });
  }

  return imagesToUpload.map(({ key }) => key);
};

export const isUsOrCa = (country: string): boolean => {
  return (
    country === 'United States' ||
    country === 'Canada' ||
    country === 'US' ||
    country === 'CA'
  );
};

export const formatAudienceSize = (
  audienceSize: string,
  digits: number
): string => {
  const number = Number(audienceSize);

  if (Number.isNaN(number)) {
    return '';
  }

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'Qa' },
    { value: 1e18, symbol: 'Qi' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return number >= item.value;
    });
  return item
    ? (number / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
};

export type SortingOptionsType = {
  value: SearchCampaignStoresOrderBy;
  label: string;
  direction: SortDirection;
};

export const SORTING_OPTIONS: SortingOptionsType[] = [
  {
    value: SearchCampaignStoresOrderBy.fullName,
    label: 'Name (A-Z)',
    direction: SortDirection.ASC,
  },
  {
    value: SearchCampaignStoresOrderBy.fullName,
    label: 'Name (Z-A)',
    direction: SortDirection.DESC,
  },
  {
    value: SearchCampaignStoresOrderBy.totalFollowing,
    label: 'Followers (High to Low)',
    direction: SortDirection.DESC,
  },
  {
    value: SearchCampaignStoresOrderBy.totalFollowing,
    label: 'Followers (Low to High)',
    direction: SortDirection.ASC,
  },
];
