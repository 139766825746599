// Types
import { OrganizationType, StoreCareerStatus } from 'api/graphql-global-types';
import { GetCountryList_getCountryList } from 'api/users/types/GetCountryList';
import { UserRole } from 'api/graphql-global-types';
import {
  GetAllCountriesV2,
  GetAllCountriesV2_getAllCountriesV2,
} from 'api/locations/types/GetAllCountriesV2';

// for case when user removes text from input and submits
export const isEmptyString = (value: string | null): string | null => {
  return value === '' ? null : value;
};

// for case when user removes text from input and submits
export const isZero = (value: number | null): number | null => {
  return value === 0 ? null : value;
};
export const findStateByName = (
  stateName: string,
  data: GetCountryList_getCountryList[]
): GetCountryList_getCountryList | null | undefined => {
  return data.reduce<GetCountryList_getCountryList | null | undefined>(
    (foundState, country) =>
      foundState ||
      (country?.states?.find((state) => state.name === stateName) as
        | GetCountryList_getCountryList
        | undefined),
    null
  );
};
export const findStateByCode = (
  stateCode: string,
  data: GetCountryList_getCountryList[]
): GetCountryList_getCountryList | null | undefined => {
  return data.reduce<GetCountryList_getCountryList | null | undefined>(
    (foundState, country) =>
      foundState ||
      (country?.states?.find((state) => state.code === stateCode) as
        | GetCountryList_getCountryList
        | undefined),
    null
  );
};

export type OrgTypeOption = {
  value: string;
  label: string;
};

export const ORGANIZATION_TYPE_OPTIONS: OrgTypeOption[] = [
  {
    value: OrganizationType.Gym,
    label: 'Gym',
  },
  {
    value: OrganizationType.EventOrganizer,
    label: 'Event/Organizer',
  },
  {
    value: OrganizationType.AssociationLeagueTeam,
    label: 'Association/League/Team',
  },
  {
    value: OrganizationType.Other,
    label: 'Other',
  },
];

export const STORE_ROLES = [
  UserRole.Athlete,
  UserRole.ContentCreator,
  UserRole.Organization,
];

export const CAREER_STATUS_OPTIONS = [
  {
    value: StoreCareerStatus.Active,
    label: 'Active',
  },
  {
    value: StoreCareerStatus.Retired,
    label: 'Retired',
  },
];

export const findCountryByIso2 = (
  countries: GetAllCountriesV2,
  country: string
): GetAllCountriesV2_getAllCountriesV2 | null => {
  return (
    countries?.getAllCountriesV2?.find((item) => item.iso2 === country) || null
  );
};
