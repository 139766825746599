import { gql } from '@apollo/client';

export const GET_STORE_INTERVIEWERS = gql`
  query GetStoreInterviewers($input: StoreInterviewerFilterInput!) {
    getStoreInterviewers(input: $input) {
      entities {
        id
        createdAt
        email
        firstName
        lastName
        status
        scheduledInterviewCnt
        interviewerDetails {
          id
          isIncludedInAutoAssignment
          sports {
            id
            name
          }
        }
      }
      limit
      offset
      total
    }
  }
`;

export const GET_STORE_INTERVIEWERS_MINIMAL = gql`
  query GetStoreInterviewersMinimal($input: StoreInterviewerFilterInput!) {
    getStoreInterviewers(input: $input) {
      entities {
        id
        firstName
        lastName
        storeDetails {
          storeName
        }
      }
      limit
      offset
      total
    }
  }
`;
