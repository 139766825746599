import {
  useQuery,
  QueryResult,
  QueryOptions,
  WatchQueryFetchPolicy,
} from '@apollo/client';
// Api
import { GET_ADMINS } from 'api/admin/queries';
// Types
import { AdminFilterInput } from 'api/graphql-global-types';
import { GetAdmins, GetAdminsVariables } from 'api/admin/types/GetAdmins';

type BasicQueryOptions = Omit<
  QueryOptions,
  'query' | 'variables' | 'fetchPolicy'
> & {
  fetchPolicy?: WatchQueryFetchPolicy;
};

type UseGetAdminsOptions = {
  options?: BasicQueryOptions;
  variables?: AdminFilterInput;
};

export const useGetAdmins = (
  options?: UseGetAdminsOptions
): QueryResult<GetAdmins, GetAdminsVariables> => {
  const admins = useQuery<GetAdmins, GetAdminsVariables>(GET_ADMINS, {
    variables: {
      input: {
        ...options?.variables,
      },
    },
    ...options?.options,
  });

  return admins;
};
